/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../api/types.generated";
export type ReturnHackerHardwareRequestMutationVariables = Types.Exact<{
  id: Types.Scalars["Float"];
}>;

export type ReturnHackerHardwareRequestMutation = {
  returnHackerHardwareRequest: Pick<Types.HardwareRequest, "id" | "status">;
};

export const ReturnHackerHardwareRequestDocument = gql`
  mutation ReturnHackerHardwareRequest($id: Float!) {
    returnHackerHardwareRequest(id: $id) {
      id
      status
    }
  }
`;

/**
 * __useReturnHackerHardwareRequestMutation__
 *
 * To run a mutation, you first call `useReturnHackerHardwareRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReturnHackerHardwareRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [returnHackerHardwareRequestMutation, { data, loading, error }] = useReturnHackerHardwareRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReturnHackerHardwareRequestMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ReturnHackerHardwareRequestMutation,
    ReturnHackerHardwareRequestMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ReturnHackerHardwareRequestMutation,
    ReturnHackerHardwareRequestMutationVariables
  >(ReturnHackerHardwareRequestDocument, baseOptions);
}
export type ReturnHackerHardwareRequestMutationHookResult = ReturnType<
  typeof useReturnHackerHardwareRequestMutation
>;
export type ReturnHackerHardwareRequestMutationResult =
  ApolloReactCommon.MutationResult<ReturnHackerHardwareRequestMutation>;
export type ReturnHackerHardwareRequestMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    ReturnHackerHardwareRequestMutation,
    ReturnHackerHardwareRequestMutationVariables
  >;
