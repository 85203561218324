/* eslint-disable @typescript-eslint/camelcase */
import { Text, Button, Label, Card } from "north.js";
import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import "twin.macro";

import { MENTORSHIP_PROFILES_SLUG } from "src/shared/constants/event";
import { useGetMentorshipIndividualLazyQuery } from "src/shared/contexts/MentorContext/graphql/getMentorIndividual.generated";
import { MentorshipRequestStage } from "src/shared/contexts/MentorshipRequestContext/constants";
import { MentorshipRequest } from "src/shared/contexts/MentorshipRequestContext/types";
import { formatTime } from "src/shared/utils/formatTime";
import { getFieldAnswer } from "src/shared/utils/hackerapi";

import MentorCardContent from "./MentorCardContent";
import { RequestHostModal } from "./RequestHostModal";
import { RequestModal } from "./RequestModal";

export interface RequestCardProps {
  request: MentorshipRequest;
  updateRequest: (data: MentorshipRequest) => Promise<unknown>;
  onResolve: (request: MentorshipRequest) => void;
  onReopen: (request: MentorshipRequest) => void;
}

// This component UI is largely based on the card in claimed-requests for mentors
export const RequestCard: React.FC<RequestCardProps> = ({
  request,
  updateRequest,
  onResolve,
  onReopen,
}) => {
  const [isRequestModalOpen, setIsRequestModalOpen] = useState(false);
  const [isRequestHostModalOpen, setIsRequestHostModalOpen] = useState(false);
  const [getMentorshipIndividualQuery, { data: mentorData }] =
    useGetMentorshipIndividualLazyQuery();

  useEffect(() => {
    if (request?.mentor_id) {
      console.debug({ id: request?.mentor_id });

      getMentorshipIndividualQuery({
        variables: {
          slug: MENTORSHIP_PROFILES_SLUG,
          userId: request.mentor_id.toString(),
        },
      });
    }
  }, [request, getMentorshipIndividualQuery]);
  const mentorName = useMemo(() => {
    return mentorData?.claims[0]?.name;
  }, [mentorData]);

  if (request?.mentor_id) {
    console.debug({ mentorName });
  }

  // Repeat code from MentorContext
  const mentorProfile = useMemo(() => {
    const individualClaim = mentorData?.claims[0];
    const individualFields = individualClaim?.fields;
    return individualClaim && individualFields
      ? {
          user_id: request.mentor_id as number,
          institution: getFieldAnswer(
            individualFields,
            "institution"
          ) as string,
          pronouns: getFieldAnswer(individualFields, "pronouns") as string[],
          other_pronouns: getFieldAnswer(
            individualFields,
            "other_pronouns"
          ) as string,
          mentorship_topics: getFieldAnswer(
            individualFields,
            "mentorship_topics"
          ) as string[],
        }
      : null;
  }, [mentorData, request.mentor_id]);

  const submit = (
    title: string,
    description: string,
    category: string[],
    priority: number
  ) => {
    const updated = { ...request, title, description, category, priority };
    updateRequest(updated);
    setIsRequestModalOpen(false);
  };

  const submitHost = (
    title: string,
    description: string,
    category: string[],
    priority: number
  ) => {
    const updated = { ...request, title, description, category, priority };
    updateRequest(updated);
    setIsRequestHostModalOpen(false);
  };

  const isUnclaimed = request.stage_slug === MentorshipRequestStage.OPEN;
  const isClaimed = request.stage_slug === MentorshipRequestStage.CLAIMED;
  const isResolved = request.stage_slug === MentorshipRequestStage.RESOLVED;
  const isHackathonHostRequest =
    request?.category?.includes("Hackathon Host") ?? false;

  return (
    <>
      <RequestModal
        isOpen={isRequestModalOpen}
        onCancel={() => setIsRequestModalOpen(false)}
        onSubmit={submit}
        requestToEdit={request}
        newRequest={false}
      />
      <RequestHostModal
        isOpen={isRequestHostModalOpen}
        onCancel={() => setIsRequestHostModalOpen(false)}
        onSubmit={submitHost}
        requestToEdit={request}
        newRequest={false}
      />
      <Card>
        <div tw="flex justify-between align-items[center]">
          <BadgeDiv>
            {request.stage_slug === MentorshipRequestStage.OPEN ? (
              <Label size="sm" color="warning-light">
                <Text type="body2">Unclaimed</Text>
              </Label>
            ) : request.stage_slug === MentorshipRequestStage.CLAIMED ? (
              <Label size="sm" color="primary-light">
                <Text type="body2">Claimed</Text>
              </Label>
            ) : request.stage_slug === MentorshipRequestStage.RESOLVED ? (
              <Label size="sm" color="success-light">
                <Text type="body2">Resolved</Text>
              </Label>
            ) : null}
            {isHackathonHostRequest && (
              <Label size="sm" color="primary-light">
                <Text type="body2">Hackathon Host</Text>
              </Label>
            )}
          </BadgeDiv>
          <SmallText>
            Submitted {formatTime(request.created_at, true)}
          </SmallText>
        </div>
        <div tw="h-8" />
        <Text type="button" tw="text-text-body">
          {request.title}
        </Text>
        <div tw="h-8" />
        <Text type="body1" tw="text-text-body">
          {request.description}
        </Text>
        {(request?.category || []).length > 0 && (
          <>
            <div tw="h-20" />
            <div tw="flex">
              <SmallText category>Category</SmallText>
              <div tw="w-16" />
              <div tw="flex align-items[center] flex-wrap gap-8">
                {(
                  request?.category?.filter(
                    (category) => category !== "Hackathon Host"
                  ) || []
                ).map((category) => (
                  <>
                    <Label size="sm" color="secondary-light" key={category}>
                      {category}
                    </Label>
                  </>
                ))}
              </div>
            </div>

            <div tw="h-8" />
          </>
        )}

        {!isUnclaimed && mentorProfile && mentorName && (
          <MentorCardContent profile={mentorProfile} name={mentorName} />
        )}
        <div tw="h-20" />
        <div tw="flex justify-end">
          {isUnclaimed ? (
            <>
              <Button
                color="secondary"
                onClick={() =>
                  isHackathonHostRequest
                    ? setIsRequestHostModalOpen(true)
                    : setIsRequestModalOpen(true)
                }
              >
                Edit
              </Button>
              <div tw="w-16" />
              <Button color="primary" onClick={() => onResolve(request)}>
                Mark as Resolved
              </Button>
            </>
          ) : isClaimed ? (
            <>
              {/* <Button
                color="secondary"
                onClick={() => alert("view slack profile")} // TODO: Implement
              >
                View slack profile
              </Button> */}
              <div tw="w-16" />
              <Button color="primary" onClick={() => onResolve(request)}>
                Mark as Resolved
              </Button>
            </>
          ) : isResolved ? (
            <Button color="primary" onClick={() => onReopen(request)}>
              Re-open
            </Button>
          ) : (
            <div></div>
          )}
        </div>
      </Card>
    </>
  );
};

const SmallText = styled.p<{ category?: boolean }>`
  font-size: 12px;
  color: ${({ theme }) => theme.globalConstants.color.textTertiary};
  font-weight: 500;
  line-height: 1.35;
  ${({ category }) => category && `margin-top: 4px;`}
`;

const BadgeDiv = styled.div`
  display: flex;
  gap: 12px;
`;
