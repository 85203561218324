import { Spacer, Text } from "@hackthenorth/north";
import moment from "moment-timezone";
import { Button, Select } from "north.js";
import React, { useCallback, useState } from "react";
import { Prompt } from "react-router-dom";

import { successToast } from "src/shared/components";
import { CURRENT_EVENT_SLUG } from "src/shared/constants/event";

import BusCard from "./busCard";
import { BUS_DROPOFF_DATE, BUS_LOCATIONS, BUS_PICKUP_DATE } from "./constants";
import { useFindBusesByLocationQuery } from "./graphql/findBusesByLocation.generated";
import { useFindMyBusesByEventQuery } from "./graphql/findMyBusesByEvent.generated";
import { useRegisterSelfToBusMutation } from "./graphql/registerSelfToBus.generated";
import { useUnregisterSelfFromBusMutation } from "./graphql/unregisterSelfToBus.generated";

const UNIVERSITY_OF_WATERLOO = "University of Waterloo";
const LAZARDIS_HALL = "Lazardis Hall";

interface BusComponentProps {
  isBlocking: boolean;
  setIsBlocking: React.Dispatch<React.SetStateAction<boolean>>;
}

export const BusComponent: React.FC<BusComponentProps> = ({
  isBlocking,
  setIsBlocking,
}) => {
  const [pickupLocation, setPickupLocation] = useState<string>("");
  const [tempPickupLocation, setTempPickupLocation] = useState<string>("");

  const [dropoffLocation, setDropoffLocation] = useState<string>("");
  const [tempDropoffLocation, setTempDropoffLocation] = useState<string>("");

  const [selectedDropoff, setSelectedDropoff] = useState({
    currentBusID: -1,
    newBusStopID: -1,
  });

  const [selectedPickup, setSelectedPickup] = useState({
    currentBusID: -1,
    newBusStopID: -1,
  });

  const [changingBus, setChangingBus] = useState({
    pickup: false,
    dropoff: false,
  });

  const { data: myBuses } = useFindMyBusesByEventQuery({
    variables: {
      eventSlug: CURRENT_EVENT_SLUG,
    },
    onCompleted: () => {
      let isMounted = true;

      const pickupBus = myBuses?.findMyBusesByEvent.find((bus) =>
        bus.stops?.find((stop) => {
          return (
            moment(stop.departure_time)
              .tz("America/Toronto")
              .format("MMMM D, YYYY") === BUS_PICKUP_DATE
          );
        })
      );

      const dropoffBus = myBuses?.findMyBusesByEvent.find((bus) =>
        bus.stops?.find((stop) => {
          return (
            moment(stop.departure_time)
              .tz("America/Toronto")
              .format("MMMM D, YYYY") === BUS_DROPOFF_DATE
          );
        })
      );

      if (isMounted) {
        if (pickupBus && pickupBus.stops) {
          setSelectedPickup({
            ...selectedPickup,
            currentBusID: pickupBus.id,
            newBusStopID: pickupBus.stops?.[0].id,
          });
          setPickupLocation(pickupBus.stops[0].name);
        }

        if (dropoffBus && dropoffBus.stops) {
          setSelectedDropoff({
            ...selectedDropoff,
            currentBusID: dropoffBus.id,
            newBusStopID: dropoffBus.stops?.[0].id,
          });
          setDropoffLocation(dropoffBus.stops[0].name);
        }
      }

      return () => {
        isMounted = false;
      };
    },
  });

  const { data: departureBuses, refetch: refetchDepartureBuses } =
    useFindBusesByLocationQuery({
      variables: {
        location: pickupLocation,
      },
      skip: !pickupLocation,
    });

  const validDepartureBuses = departureBuses?.findBusesByLocation
    .map((bus) => {
      const departureStop = bus.stops?.find(
        (stop) => stop.name === pickupLocation
      );
      const arrivalStop = bus.stops?.[bus.stops.length - 1];

      return !departureStop ||
        !arrivalStop ||
        (arrivalStop.name !== UNIVERSITY_OF_WATERLOO &&
          arrivalStop.name !== LAZARDIS_HALL)
        ? null
        : { ...bus, departureStop, arrivalStop };
    })
    .filter((bus): bus is NonNullable<typeof bus> => bus !== null)
    .sort((a, b) =>
      a.departureStop.departure_time.localeCompare(
        b.departureStop.departure_time
      )
    );

  const { data: arrivalBuses, refetch: refetchArrivalBuses } =
    useFindBusesByLocationQuery({
      variables: {
        location: dropoffLocation,
      },
      skip: !dropoffLocation,
    });

  const validArrivalBuses = arrivalBuses?.findBusesByLocation
    .map((bus) => {
      const departureStop = bus.stops?.[0];
      const arrivalStop = bus.stops?.find(
        (stop) => stop.name === dropoffLocation
      );

      return !departureStop ||
        !arrivalStop ||
        (departureStop.name !== UNIVERSITY_OF_WATERLOO &&
          departureStop.name !== LAZARDIS_HALL)
        ? null
        : { ...bus, departureStop, arrivalStop };
    })
    .filter((bus): bus is NonNullable<typeof bus> => bus !== null)
    .sort((a, b) =>
      a.departureStop.departure_time.localeCompare(
        b.departureStop.departure_time
      )
    );

  const [registerSelfToBus] = useRegisterSelfToBusMutation();

  const [unregisterSelfToBus] = useUnregisterSelfFromBusMutation();

  const registerToPickupBus = useCallback(async () => {
    setChangingBus({ ...changingBus, pickup: false });
    if (selectedPickup.currentBusID !== -1) {
      await unregisterSelfToBus({
        variables: {
          busId: selectedPickup.currentBusID,
        },
      });
      setSelectedPickup({
        ...selectedPickup,
        currentBusID: -1,
      });
    }

    if (selectedPickup.newBusStopID !== -1) {
      const result = await registerSelfToBus({
        variables: {
          busStopId: selectedPickup.newBusStopID,
        },
      });

      const newBusID = result.data?.registerSelfToBus?.bus_id;

      await refetchDepartureBuses();
      if (typeof newBusID === "number") {
        setSelectedPickup({
          ...selectedPickup,
          currentBusID: newBusID,
        });
      }
      successToast("Successfully registered to pickup bus!");
      setChangingBus({ ...changingBus, pickup: false });
      return;
    }
    await refetchDepartureBuses();
    successToast("Successfully unregistered from pickup bus!");
    setChangingBus({ ...changingBus, pickup: false });
  }, [
    changingBus,
    registerSelfToBus,
    selectedPickup,
    unregisterSelfToBus,
    refetchDepartureBuses,
  ]);

  const registerToDropoffBus = useCallback(async () => {
    if (selectedDropoff.currentBusID !== -1) {
      await unregisterSelfToBus({
        variables: {
          busId: selectedDropoff.currentBusID,
        },
      });
      setSelectedDropoff({
        ...selectedDropoff,
        currentBusID: -1,
      });
    }

    if (selectedDropoff.newBusStopID !== -1) {
      const result = await registerSelfToBus({
        variables: {
          busStopId: selectedDropoff.newBusStopID,
        },
      });

      const newBusID = result.data?.registerSelfToBus?.bus_id;

      await refetchArrivalBuses();
      if (typeof newBusID === "number") {
        setSelectedDropoff({
          ...selectedDropoff,
          currentBusID: newBusID,
        });
      }
      successToast("Successfully registered to dropoff bus!");
      setChangingBus({ ...changingBus, dropoff: false });
      return;
    }

    await refetchArrivalBuses();
    successToast("Successfully unregistered from dropoff bus!");
    setChangingBus({ ...changingBus, dropoff: false });
  }, [
    changingBus,
    registerSelfToBus,
    selectedDropoff,
    unregisterSelfToBus,
    refetchArrivalBuses,
  ]);

  return (
    <>
      <Prompt
        when={isBlocking}
        message="You have unsaved changes, are you sure you want to move away from this page?"
      />
      <Text mods="h3 bold">Reserve your bus</Text>
      <Spacer height={24} />
      <Text mods="bold textBody">Pick-up Location (Friday, September 13)</Text>
      <Spacer height={12} />
      <Select
        fullWidth
        size="lg"
        value={pickupLocation}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          if (e.target.value === "") {
            setSelectedPickup({ ...selectedPickup, newBusStopID: -1 });
          }
          setPickupLocation(e.target.value);
        }}
        disabled={!changingBus.pickup}
      >
        {BUS_LOCATIONS.map((route, i) => {
          return (
            <Select.Option key={i} value={route}>
              {route}
            </Select.Option>
          );
        })}
        <Select.Option value={""}>I don&apos;t need a bus</Select.Option>
      </Select>
      <Spacer height={24} />
      {pickupLocation !== "" && (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "16px",
            width: "95%",
            marginBottom: "24px",
          }}
        >
          {validDepartureBuses?.map((bus, i) => (
            <BusCard
              key={i}
              availableSeats={bus.seats_available}
              departure={bus.departureStop.departure_time}
              arrival={bus.arrivalStop.departure_time}
              selected={
                selectedPickup.newBusStopID === -1
                  ? bus.departureStop.id === selectedPickup.currentBusID
                  : bus.departureStop.id === selectedPickup.newBusStopID
              }
              disabled={bus.seats_available === 0 || !changingBus.pickup}
              onClick={() => {
                setSelectedPickup({
                  ...selectedPickup,
                  newBusStopID: bus.departureStop.id,
                });
                setIsBlocking(true);
              }}
            />
          ))}
        </div>
      )}
      <div style={{ display: "flex", gap: "5px" }}>
        <Button
          color={changingBus.pickup ? "primary" : "secondary"}
          size="lg"
          onClick={() => {
            if (changingBus.pickup) {
              registerToPickupBus();
            } else {
              setChangingBus({ ...changingBus, pickup: true });
              setTempPickupLocation(pickupLocation);
            }
          }}
          disabled={
            changingBus.pickup &&
            selectedPickup.newBusStopID === selectedPickup.currentBusID
          }
        >
          {changingBus.pickup ? "Confirm" : "Edit bus route"}
        </Button>
        {changingBus.pickup && (
          <Button
            color="secondary"
            size="lg"
            onClick={() => {
              setChangingBus({ ...changingBus, pickup: false });
              setSelectedPickup({
                currentBusID: selectedPickup.currentBusID,
                newBusStopID: selectedPickup.currentBusID,
              });
              setPickupLocation(tempPickupLocation);
            }}
          >
            Cancel
          </Button>
        )}
      </div>
      <Spacer height={36} />
      <Text mods="bold textBody">Drop-off Location (Sunday, September 15)</Text>
      <Spacer height={12} />
      <Select
        fullWidth
        size="lg"
        value={dropoffLocation}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          if (e.target.value === "") {
            setSelectedDropoff({ ...selectedDropoff, newBusStopID: -1 });
          }
          setDropoffLocation(e.target.value);
        }}
        disabled={!changingBus.dropoff}
      >
        {BUS_LOCATIONS.map((route, i) => (
          <Select.Option key={i} value={route}>
            {route}
          </Select.Option>
        ))}
        <Select.Option value={""}>I don&apos;t need a bus</Select.Option>
      </Select>
      <Spacer height={24} />
      {dropoffLocation !== "" && (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "16px",
            width: "95%",
            marginBottom: "24px",
          }}
        >
          {validArrivalBuses?.map((bus, i) => (
            <BusCard
              key={i}
              availableSeats={bus.seats_available}
              departure={bus.departureStop.departure_time}
              arrival={bus.arrivalStop.departure_time}
              selected={
                selectedDropoff.newBusStopID === -1
                  ? bus.arrivalStop.id === selectedDropoff.currentBusID
                  : bus.arrivalStop.id === selectedDropoff.newBusStopID
              }
              disabled={bus.seats_available === 0 || !changingBus.dropoff}
              onClick={() => {
                setSelectedDropoff({
                  ...selectedDropoff,
                  newBusStopID: bus.arrivalStop.id,
                });
                setIsBlocking(true);
              }}
            />
          ))}
        </div>
      )}
      <div style={{ display: "flex", gap: "5px" }}>
        <Button
          color={changingBus.dropoff ? "primary" : "secondary"}
          size="lg"
          onClick={() => {
            if (changingBus.dropoff) {
              registerToDropoffBus();
            } else {
              setChangingBus({ ...changingBus, dropoff: true });
              setTempDropoffLocation(dropoffLocation);
            }
          }}
          disabled={
            changingBus.dropoff &&
            selectedDropoff.newBusStopID === selectedDropoff.currentBusID
          }
        >
          {changingBus.dropoff ? "Confirm" : "Edit bus route"}
        </Button>
        {changingBus.dropoff && (
          <Button
            color="secondary"
            size="lg"
            onClick={() => {
              setChangingBus({ ...changingBus, dropoff: false });
              setSelectedDropoff({
                currentBusID: selectedDropoff.currentBusID,
                newBusStopID: selectedDropoff.currentBusID,
              });
              setDropoffLocation(tempDropoffLocation);
            }}
          >
            Cancel
          </Button>
        )}
      </div>
    </>
  );
};
