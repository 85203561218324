import { Text, Spacer } from "@hackthenorth/north";
import { Link } from "north.js";
import React from "react";
import styled from "styled-components";

import { PageWrapper } from "src/shared/components";
import { Field } from "src/shared/contexts/HackerContext/types";
import { useHackerState } from "src/shared/contexts/HackerContext/useHackerState";
import { useDeviceSize } from "src/shared/hooks";

import { BusForm } from "./busForm";
import { FlightForm } from "./flightForm";

const TravelForm = () => {
  const ISFLIGHTCLOSED = true;
  const ISBUSCLOSED = true;

  const ACCEPTED_FIELDS = [Field.TRAVEL_OPTION];

  const { responsesState } = useHackerState(ACCEPTED_FIELDS, {});
  const isFlight = responsesState[Field.TRAVEL_OPTION] === "flight";
  const isTabletOrSmaller = useDeviceSize("tablet");

  const StyledPageWrapper = styled(PageWrapper)<{ isTabletOrSmaller: boolean }>`
    width: ${isTabletOrSmaller ? `100%` : `70%`};
    padding-left: ${({ isTabletOrSmaller }) =>
      isTabletOrSmaller ? "24px" : "60px"};
  `;

  const HeaderText = styled.h1`
    font-size: 28px;
    font-weight: 900;
    font-family: ${({ theme }) => theme.globalConstants.fontFamily.heading};
    line-height: 1.5;
    color: ${({ theme }) => theme.globalConstants.color.brandSecondary};
    margin-bottom: 8px;
    margin-top: 0;
  `;
  const Title = () => <HeaderText>Travel details</HeaderText>;

  const travelEmail = (
    <Link href="mailto:travel@hackthenorth.com">
      <span style={{ fontWeight: "bold" }}>travel@hackthenorth.com</span>
    </Link>
  );

  const Description = () =>
    isFlight ? (
      <Text>
        Please select a bus route that is most convenient for you to get from
        the airport to the event. If you don&apos;t need a bus, you may select{" "}
        <b>“I don&apos;t need a bus”</b>. If you have any questions or concerns
        please contact {travelEmail}.
      </Text>
    ) : (
      <Text>
        Please select the bus route that is most convenient for you. You will
        receive a confirmation email once you&apos;ve confirmed the route. If
        you have any questions or concerns regarding buses, please contact{" "}
        {travelEmail}.
      </Text>
    );

  const Form = () =>
    isFlight ? (
      <FlightForm isFlightClosed={ISFLIGHTCLOSED} />
    ) : (
      <BusForm isBusClosed={ISBUSCLOSED} />
    );

  return (
    <StyledPageWrapper isTabletOrSmaller={isTabletOrSmaller}>
      {!ISFLIGHTCLOSED && <Title />}
      <Spacer height={16} />
      {!ISFLIGHTCLOSED && <Description />}
      <Spacer height={42} />
      <Form />
    </StyledPageWrapper>
  );
};

export default TravelForm;
