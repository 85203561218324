import {
  CalendarIcon,
  CheckCircleIcon,
  ClipboardDocumentCheckIcon,
  ClipboardDocumentListIcon,
  HomeIcon,
  InboxArrowDownIcon,
  KeyIcon,
  MagnifyingGlassIcon,
  ShareIcon,
  SparklesIcon,
  UserCircleIcon,
  UserGroupIcon,
  TicketIcon,
  InboxIcon,
  ClockIcon,
  UsersIcon,
  StarIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import {
  CalendarIcon as CalendarIconSolid,
  CheckCircleIcon as CheckCircleIconSolid,
  ClipboardDocumentCheckIcon as ClipboardDocumentCheckIconSolid,
  ClipboardDocumentListIcon as ClipboardDocumentListIconSolid,
  HomeIcon as HomeIconSolid,
  InboxArrowDownIcon as InboxArrowDownIconSolid,
  KeyIcon as KeyIconSolid,
  ShareIcon as ShareIconSolid,
  SparklesIcon as SparklesIconSolid,
  UserCircleIcon as UserCircleIconSolid,
  UserGroupIcon as UserGroupIconSolid,
  TicketIcon as TicketIconSolid,
  InboxIcon as InboxIconSolid,
  ClockIcon as ClockIconSolid,
  UsersIcon as UsersIconSolid,
  StarIcon as StarIconSolid,
  QuestionMarkCircleIcon as QuestionMarkSolid,
} from "@heroicons/react/24/solid";
import Cookies from "js-cookie";
import { CSSProperties } from "styled-components";

import { Route } from "src/shared/constants/route";
import { Permission } from "src/shared/contexts/PermissionsContext/types";

export type TSidebarSection = {
  title?: string;
  content: TSidebarItem[];
};

export type TSidebarItem = {
  name: string;
  href: string;
  onClick?: () => void;
  icon: React.FC<{ style?: CSSProperties }>;
  activeIcon: React.FC<{ style?: CSSProperties }>;
  permissions?: Permission[];
  antiPermissions?: Permission[];
};

export const GENERAL_SECTION: TSidebarSection = {
  content: [
    {
      name: "Home",
      href: Route.HOME,
      icon: HomeIcon,
      activeIcon: HomeIconSolid,
      antiPermissions: [
        Permission.SPONSOR,
        Permission.VOLUNTEER,
        Permission.WORKSHOP_LEAD,
      ],
    },
    {
      name: "Schedule",
      href: Route.SCHEDULE,
      icon: CalendarIcon,
      activeIcon: CalendarIconSolid,
      permissions: [Permission.VIEW_FULL_SCHEDULE],
    },
    {
      name: "Ticket",
      href: Route.TICKET,
      icon: TicketIcon,
      activeIcon: TicketIconSolid,
      permissions: [
        Permission.SPONSOR,
        Permission.MENTOR,
        Permission.VOLUNTEER,
        Permission.HACKER_EVENT_ACCESS,
        Permission.ORGANIZER,
      ],
    },
    {
      name: "Mentorship",
      href: Route.HACKER_MENTORSHIP,
      icon: UserGroupIcon,
      activeIcon: UserGroupIconSolid,
      permissions: [Permission.HACKER_EVENT_ACCESS],
    },
    {
      name: "Charts",
      href: Route.SIGNAGE_HUNT,
      icon: MagnifyingGlassIcon,
      activeIcon: MagnifyingGlassIcon,
      permissions: [Permission.HACKER_EVENT_ACCESS],
    },
    {
      name: "Networking",
      href: Route.NETWORKING,
      icon: ShareIcon,
      activeIcon: ShareIconSolid,
      permissions: [
        Permission.HACKER_EVENT_ACCESS,
        Permission.MENTOR,
        Permission.WORKSHOP_LEAD,
        Permission.VOLUNTEER,
      ],
    },
  ],
};

export const MENTOR_SECTIONS: TSidebarSection = {
  title: "Mentorship",
  content: [
    {
      name: "Open Requests",
      href: Route.MENTOR_REQUESTS,
      icon: ClipboardDocumentListIcon,
      activeIcon: ClipboardDocumentListIconSolid,
      permissions: [Permission.MENTOR],
    },
    {
      name: "Claimed Requests",
      href: Route.MENTOR_CLAIMED_REQUESTS,
      icon: ClipboardDocumentCheckIcon,
      activeIcon: ClipboardDocumentCheckIconSolid,
      permissions: [Permission.MENTOR],
    },
    {
      name: "My profile",
      href: Route.MENTOR_PROFILE,
      icon: UserCircleIcon,
      activeIcon: UserCircleIconSolid,
      permissions: [Permission.MENTOR],
    },
  ],
};

export const VOLUNTEER_SECTIONS: TSidebarSection = {
  title: "Volunteer",
  content: [
    {
      name: "Registration",
      href: Route.VOLUNTEER_REGISTRATION,
      icon: InboxArrowDownIcon,
      activeIcon: InboxArrowDownIconSolid,
      permissions: [Permission.VOLUNTEER],
    },
    {
      name: "Event Redemption",
      href: Route.VOLUNTEER_EVENT_REDEMPTION,
      icon: CheckCircleIcon,
      activeIcon: CheckCircleIconSolid,
      permissions: [Permission.VOLUNTEER],
    },
  ],
};

export const HARDWARE_SECTIONS: TSidebarSection = {
  title: "Hardware",
  content: [
    {
      name: "Home",
      href: Route.HARDWARE_HOME,
      icon: HomeIcon,
      activeIcon: HomeIconSolid,
      permissions: [Permission.HACKER_EVENT_ACCESS],
    },
    {
      name: "Inventory",
      href: Route.HARDWARE_INVENTORY,
      icon: InboxIcon,
      activeIcon: InboxIconSolid,
      permissions: [Permission.HACKER_EVENT_ACCESS],
    },
    {
      name: "Request History",
      href: Route.HARDWARE_REQUEST_HISTORY,
      icon: ClockIcon,
      activeIcon: ClockIconSolid,
      permissions: [Permission.HACKER_EVENT_ACCESS],
    },
    {
      name: "Users",
      href: Route.HARDWARE_USERS,
      icon: UsersIcon,
      activeIcon: UsersIconSolid,
      permissions: [Permission.ORGANIZER],
    },
    {
      name: "Lottery",
      href: Route.HARDWARE_LOTTERY,
      icon: StarIcon,
      activeIcon: StarIconSolid,
      permissions: [Permission.ORGANIZER],
    },
    {
      name: "FAQ",
      href: Route.HARDWARE_FAQ,
      icon: QuestionMarkCircleIcon,
      activeIcon: QuestionMarkSolid,
      permissions: [Permission.HACKER_EVENT_ACCESS],
    },
  ],
};

export const SPONSOR_SECTIONS: TSidebarSection = {
  title: "Sponsorship",
  content: [
    {
      name: "Perks",
      href: Route.SPONSOR_PERKS,
      icon: SparklesIcon,
      activeIcon: SparklesIconSolid,
      permissions: [Permission.SPONSOR],
    },
    {
      name: "Recruitment",
      href: Route.SPONSOR_RECRUITMENT,
      icon: UserGroupIcon,
      activeIcon: UserGroupIconSolid,
      permissions: [Permission.SPONSOR_RECRUITMENT_ACCESS],
    },
  ],
};

export const INTERNAL_SECTIONS: TSidebarSection = {
  title: "Internal",
  content: [
    {
      name: "Impersonate",
      href: "#",
      onClick: () => {
        const token = window.prompt("JWT hackerapi access token");

        // The domain is:
        // local: `localhost`
        // staging: `.release-staging.my.hackthenorth.com`
        // deploy preview: `.deploy-preview-${number}--my-hackthenorth-com.netlify.app`
        // prod: `.hackthenorth.com`
        const domain =
          window.location.hostname === "my.hackthenorth.com"
            ? ".hackthenorth.com"
            : `.${window.location.hostname}`;

        if (token) {
          Cookies.set("accessJwt", token, {
            expires: Date.now() + 345600000,
            domain,
          });
        }
      },
      icon: KeyIcon,
      activeIcon: KeyIconSolid,
      permissions: [Permission.ORGANIZER],
    },
  ],
};

// const STAGING_SECTIONS = IS_PRODUCTION ? { content: [] } : HARDWARE_SECTIONS;

export const SECTIONS: TSidebarSection[] = [
  GENERAL_SECTION,
  MENTOR_SECTIONS,
  VOLUNTEER_SECTIONS,
  SPONSOR_SECTIONS,
  INTERNAL_SECTIONS,
  HARDWARE_SECTIONS,
];
