import { Label, Text } from "north.js";
import React from "react";
import "twin.macro";

import { Divider } from "src/shared/components";
import * as Mentor from "src/shared/contexts/MentorContext/types";

export interface MentorCardContentProps {
  profile: Mentor.Profile;
  name: string;
}

const MentorCardContent: React.FC<MentorCardContentProps> = ({
  profile,
  name,
}) => {
  let pronounString = profile?.pronouns?.join(", ") ?? "";
  if (profile?.other_pronouns) {
    pronounString = pronounString.concat(", ", profile.other_pronouns);
  }
  return (
    <>
      <div tw="h-16" />
      <Divider />
      <div tw="h-16" />

      <Text type="caption" tw="font-medium">
        Claimed by
      </Text>
      <div tw="h-16" />
      <div tw="flex justify-between">
        <Text type="button" tw="text-text-body">
          {name}
        </Text>
        <Text type="caption" tw="text-text-body">
          {pronounString}
        </Text>
      </div>
      <div tw="h-12" />
      <Text type="body1" tw="text-text-body font-medium ">
        {profile?.institution}
      </Text>
      {(profile?.mentorship_topics ?? []).length > 0 && (
        <>
          <div tw="h-16" />
          <Text type="caption" tw="font-medium text-text-secondary">
            Areas of expertise
          </Text>
          <div tw="h-8" />
          <div tw="flex align-items[center] flex-wrap gap-8">
            {(profile?.mentorship_topics || []).map((topic) => (
              <>
                <Label
                  size="sm"
                  color="secondary-light"
                  tw="font-medium"
                  key={topic}
                >
                  {topic}
                </Label>
              </>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default MentorCardContent;
