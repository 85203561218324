import { Text } from "@hackthenorth/north";
import { QRCodeSVG } from "qrcode.react";
import React from "react";
import "twin.macro";
import styled from "styled-components";

import { InfoTag } from "src/shared/components";
import { useDeviceSize } from "src/shared/hooks";
import { rsvpCardImg, LogoGradient } from "src/static/img";

interface RSVPCardProps {
  name: string | undefined;
  email: string | undefined;
  role: string | undefined;
  shirtType: string | undefined;
  shirtSize: string | undefined;
}

const RSVPCard: React.FC<RSVPCardProps> = ({
  name,
  email,
  role,
  shirtType,
  shirtSize,
}) => {
  const isMobileOrSmaller = useDeviceSize("mobile");
  const isLargeMobileOrSmaller = useDeviceSize("largeMobile");

  return (
    <>
      <img src={rsvpCardImg} alt={"Hacker gears background for RSVP card"} />
      <CardWrapper>
        <CardHeaderWrapper>
          <LogoImage src={LogoGradient} alt="Hack the North logo" />
          <Text mods="bold white">Hack the North 2024</Text>
        </CardHeaderWrapper>
        <CardTextWrapper>
          <CardNameWrapper>
            <Text mods="h2 heading white">{name}</Text>
            <Text
              style={{ marginTop: "-10px", color: "rgba(255, 255, 255, 0.60)" }}
            >
              {email}
            </Text>
          </CardNameWrapper>
          <TagWrapper>
            <RoleBadge>{role}</RoleBadge>
            {shirtType && (
              <ShirtBadge>
                {shirtType} {shirtSize}
              </ShirtBadge>
            )}
          </TagWrapper>
        </CardTextWrapper>
        <QRCodeSVG
          value={email !== undefined ? email : "no email available"}
          size={isMobileOrSmaller ? 80 : isLargeMobileOrSmaller ? 90 : 134}
          bgColor={"#ffffff"}
          fgColor={"#1B3146"}
          level={"L"}
          includeMargin={false}
        />
      </CardWrapper>
    </>
  );
};

const CardWrapper = styled.div`
  width: inherit;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 40px;
  margin-left: 40px;

  ${({ theme }) => theme.mediaQueries.medium`
    margin-top: 32px;
    margin-left: 32px;
  `}
  ${({ theme }) => theme.mediaQueries.largeMobile`
    margin-top: 30px;
    margin-left: 30px;
  `}
`;

const CardHeaderWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const TagWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-left: -5px;
  flex-wrap: wrap;
`;

const LogoImage = styled.img`
  width: 25px;
  height: auto;
`;

const CardNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const CardTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 250px;
  ${({ theme }) => theme.mediaQueries.tablet`
    max-width: 220px;
  `}
`;

const RoleBadge = styled(InfoTag)`
  color: ${({ theme }) => theme.globalConstants.color.white} !important;
  background-color: ${({ theme }) =>
    theme.globalConstants.color.backgroundPrimary};
`;

const ShirtBadge = styled(InfoTag)`
  color: ${({ theme }) => theme.globalConstants.color.white} !important;
  background-color: ${({ theme }) =>
    theme.globalConstants.color.backgroundSuccess};
`;

export default RSVPCard;
