import { Text, DropdownMenu, Button } from "north.js";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useQueryParam } from "use-query-params";

import { HardwareSkuType } from "src/api/types.generated";
import { Divider, Header, PageWrapper } from "src/shared/components";
import { HardwareItemCard, Search } from "src/shared/components/Hardware";
import { ChevronDownIcon } from "src/static/icons";
import globalConstants from "src/theme/globalConstants";

import { useGetRemainingQuantityQuery } from "../../graphql/getRemainingQuantity.generated";
import { THardwareSku } from "../../shared/hardwareModels";
import Loader from "../../shared/Loader";
import { useGetHardwareInfo } from "../../shared/useGetHardwareInfo";

import InfoModal from "./infoModal";
import RequestModal from "./requestModal";

const HackerInventory: React.FC = () => {
  const [query, setQuery] = useQueryParam<string>("search");
  const [filterParam] = useQueryParam<string>("filterParam");
  const [selectedCategory, setSelectedCategory] = useState<number>(
    filterParam ? parseInt(filterParam) : -1
  );
  const [open, setOpen] = useState(false);
  const [hardwareInfoModalOpen, setHardwareInfoModalOpen] = useState(false);
  const [requestHardwareItem, setRequestHardwareItem] =
    useState<THardwareSku | null>(null);
  const [remainingQuantityMap, setRemainingQuantityMap] = useState<{
    [key: string]: number;
  }>({});
  const {
    hardwareCategories,
    hardwareSkus,
    hardwareCategoriesDataLoading,
    hardwareSkusDataLoading,
  } = useGetHardwareInfo();

  const closeModal = () => setOpen(false);

  const onSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.currentTarget.value === "") {
        setQuery("");
      } else {
        setQuery(e.currentTarget.value);
      }
    },
    [setQuery]
  );

  const { data: remainingQuantityData } = useGetRemainingQuantityQuery();
  const remainingQuantity = remainingQuantityData?.hardwareItems;

  useEffect(() => {
    if (remainingQuantity) {
      const quantityMap: { [key: string]: number } = {};
      remainingQuantity.forEach((item) => {
        if (item.sku_code) {
          quantityMap[item.sku_code] = (quantityMap[item.sku_code] || 0) + 1;
        }
      });
      setRemainingQuantityMap(quantityMap);
    }
  }, [remainingQuantity]);

  const filteredHardwareItems = useMemo(() => {
    let filtered = hardwareSkus;

    if (selectedCategory !== -1) {
      filtered = filtered.filter(
        (item) => item.category_id === selectedCategory
      );
    }

    if (query && query.trim() !== "") {
      const lowercaseQuery = query.toLowerCase();
      filtered = filtered.filter(
        (item) =>
          item.name.toLowerCase().includes(lowercaseQuery) ||
          item.short_description.toLowerCase().includes(lowercaseQuery) ||
          item.long_description.toLowerCase().includes(lowercaseQuery)
      );
    }

    return filtered;
  }, [hardwareSkus, selectedCategory, query]);

  if (hardwareCategoriesDataLoading || hardwareSkusDataLoading) {
    return <Loader />;
  }

  return (
    <>
      <Header
        title="Hardware"
        subtitle="Browse and request a variety of hardware to bring your project to life!"
      />
      <PageWrapper>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            marginBottom: "48px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <Text type="h2">Inventory</Text>
            <Text
              type="body1"
              style={{ color: globalConstants?.color?.textSecondary as string }}
            >
              View inventory and request items.
            </Text>
          </div>
          <FlexRow>
            <SearchWrapper>
              <Search
                query={query || ""}
                onSearchChange={onSearchChange}
                totalSearchResults={filteredHardwareItems.length}
                placeholder="Search all items"
              />
            </SearchWrapper>
            <DropdownMenu
              items={[
                [
                  {
                    content: <Text type="body1">All</Text>,
                    action: () => setSelectedCategory(-1),
                    align: "left" as const, // need const for typecheck
                  },
                ],
                hardwareCategories?.map((category) => ({
                  content: <Text type="body1">{category.name}</Text>,
                  action: () => setSelectedCategory(category.id),
                  align: "left",
                })) || [],
              ]}
              style={{ justifyContent: "start" }}
            >
              <Button size="lg">
                {hardwareCategories.find(
                  (category) => category.id === selectedCategory
                )?.name || "All"}
                <ChevronDownIcon />
              </Button>
            </DropdownMenu>
          </FlexRow>
        </div>
        {requestHardwareItem && (
          <>
            <RequestModal
              open={open}
              closeModal={closeModal}
              hardwareItem={requestHardwareItem}
            />
            <InfoModal
              categoryName={
                hardwareCategories.find(
                  (category) => category.id === requestHardwareItem.category_id
                )?.name || ""
              }
              isOpen={hardwareInfoModalOpen}
              onRequest={() => {
                setOpen(true);
              }}
              onClose={() => setHardwareInfoModalOpen(false)}
              hardwareItem={requestHardwareItem}
            />
          </>
        )}
        <ItemCardsWrapper>
          {filteredHardwareItems?.map((item, index) => (
            <>
              {index !== 0 && <Divider style={{ margin: "24px 0" }} />}
              <HardwareItemCard
                key={item.code}
                skuCode={item.code}
                image={item.image}
                label={
                  item.type === HardwareSkuType.Lottery
                    ? [{ text: "Lottery Item", color: "primary-light" }]
                    : item.type === HardwareSkuType.Free
                    ? [
                        {
                          text: "Free item, no request needed",
                          color: "success-light",
                        },
                      ]
                    : []
                }
                onClick={() => {
                  setRequestHardwareItem(item);
                  setHardwareInfoModalOpen(true);
                }}
                title={item.name}
                description={item.short_description}
                buttons={
                  item.type === HardwareSkuType.Free
                    ? []
                    : [
                        {
                          text: "Request",
                          onClick: () => {
                            setRequestHardwareItem(item);
                            setOpen(true);
                          },
                          color: "primary",
                        },
                      ]
                }
                showRemainingQuantity={item.type !== HardwareSkuType.Free}
                remainingQuantity={remainingQuantityMap[item.code] || 0}
              />
            </>
          ))}
        </ItemCardsWrapper>
      </PageWrapper>
    </>
  );
};

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ItemCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: hidden;
`;

const SearchWrapper = styled.div`
  width: 100%;
`;

export default HackerInventory;
