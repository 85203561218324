import { useGetAllHardwareCategoriesQuery } from "../graphql/getAllHardwareCategories.generated";
import { useGetAllHardwareSkusQuery } from "../graphql/getAllHardwareSkus.generated";

import { THardwareCategory, THardwareSku } from "./hardwareModels";

export const useGetHardwareInfo = () => {
  const {
    data: hardwareCategoriesData,
    loading: hardwareCategoriesDataLoading,
    error: hardwareCategoriesDataError,
  } = useGetAllHardwareCategoriesQuery();

  const {
    data: hardwareSkusData,
    loading: hardwareSkusDataLoading,
    error: hardwareSkusDataError,
  } = useGetAllHardwareSkusQuery();

  const hardwareCategories: THardwareCategory[] =
    hardwareCategoriesData?.getHardwareCategories || [];

  const hardwareSkus: THardwareSku[] = hardwareSkusData?.getHardwareSkus || [];

  return {
    hardwareCategories,
    hardwareSkus,
    hardwareCategoriesDataLoading,
    hardwareSkusDataLoading,
    hardwareCategoriesDataError,
    hardwareSkusDataError,
  };
};
