/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../api/types.generated";
export type GetHackerHardwareRequestsQueryVariables = Types.Exact<{
  where: Types.HardwareRequestWhereInput;
}>;

export type GetHackerHardwareRequestsQuery = {
  myHardwareRequests: Array<
    Pick<
      Types.HardwareRequest,
      | "id"
      | "status"
      | "notes"
      | "quantity"
      | "user_id"
      | "event_id"
      | "sku_code"
      | "created_at"
      | "updated_at"
    > & { user: Pick<Types.User, "name" | "email"> }
  >;
};

export const GetHackerHardwareRequestsDocument = gql`
  query GetHackerHardwareRequests($where: HardwareRequestWhereInput!) {
    myHardwareRequests(where: $where) {
      id
      status
      notes
      quantity
      user_id
      event_id
      sku_code
      created_at
      updated_at
      user {
        name
        email
      }
    }
  }
`;

/**
 * __useGetHackerHardwareRequestsQuery__
 *
 * To run a query within a React component, call `useGetHackerHardwareRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHackerHardwareRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHackerHardwareRequestsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetHackerHardwareRequestsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetHackerHardwareRequestsQuery,
    GetHackerHardwareRequestsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetHackerHardwareRequestsQuery,
    GetHackerHardwareRequestsQueryVariables
  >(GetHackerHardwareRequestsDocument, baseOptions);
}
export function useGetHackerHardwareRequestsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetHackerHardwareRequestsQuery,
    GetHackerHardwareRequestsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetHackerHardwareRequestsQuery,
    GetHackerHardwareRequestsQueryVariables
  >(GetHackerHardwareRequestsDocument, baseOptions);
}
export type GetHackerHardwareRequestsQueryHookResult = ReturnType<
  typeof useGetHackerHardwareRequestsQuery
>;
export type GetHackerHardwareRequestsLazyQueryHookResult = ReturnType<
  typeof useGetHackerHardwareRequestsLazyQuery
>;
export type GetHackerHardwareRequestsQueryResult =
  ApolloReactCommon.QueryResult<
    GetHackerHardwareRequestsQuery,
    GetHackerHardwareRequestsQueryVariables
  >;
