/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../api/types.generated";
export type GetAllHardwareSkusQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetAllHardwareSkusQuery = {
  getHardwareSkus: Array<
    Pick<
      Types.HardwareSku,
      | "code"
      | "name"
      | "short_description"
      | "long_description"
      | "image"
      | "location"
      | "category_id"
      | "type"
    >
  >;
};

export const GetAllHardwareSkusDocument = gql`
  query GetAllHardwareSkus {
    getHardwareSkus(where: {}) {
      code
      name
      short_description
      long_description
      image
      location
      category_id
      type
    }
  }
`;

/**
 * __useGetAllHardwareSkusQuery__
 *
 * To run a query within a React component, call `useGetAllHardwareSkusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllHardwareSkusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllHardwareSkusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllHardwareSkusQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetAllHardwareSkusQuery,
    GetAllHardwareSkusQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetAllHardwareSkusQuery,
    GetAllHardwareSkusQueryVariables
  >(GetAllHardwareSkusDocument, baseOptions);
}
export function useGetAllHardwareSkusLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAllHardwareSkusQuery,
    GetAllHardwareSkusQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetAllHardwareSkusQuery,
    GetAllHardwareSkusQueryVariables
  >(GetAllHardwareSkusDocument, baseOptions);
}
export type GetAllHardwareSkusQueryHookResult = ReturnType<
  typeof useGetAllHardwareSkusQuery
>;
export type GetAllHardwareSkusLazyQueryHookResult = ReturnType<
  typeof useGetAllHardwareSkusLazyQuery
>;
export type GetAllHardwareSkusQueryResult = ApolloReactCommon.QueryResult<
  GetAllHardwareSkusQuery,
  GetAllHardwareSkusQueryVariables
>;
