import React from "react";
import "twin.macro";

import { Permission, usePermissionsContext } from "src/shared/contexts";
import NotFound from "src/views/404";

import HackerInventory from "./hacker";
import OrganizerInventory from "./organizer";

const Inventory: React.FC = () => {
  const { hasPermissions } = usePermissionsContext();

  if (hasPermissions([Permission.ORGANIZER])) {
    return <OrganizerInventory />;
  }

  if (hasPermissions([Permission.HACKER_EVENT_ACCESS])) {
    return <HackerInventory />;
  }

  return <NotFound />;
};

export default Inventory;
