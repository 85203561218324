import { Text, TextInput } from "north.js";
import React from "react";
import { styled } from "twin.macro";

import { MagnifyingGlassIcon } from "src/static/icons";
import globalConstants from "src/theme/globalConstants";

type SearchProps = {
  query: string;
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  totalSearchResults?: number;
  hideResults?: boolean;
  placeholder?: string;
};

const Search: React.FC<SearchProps> = ({
  query,
  onSearchChange,
  totalSearchResults,
  hideResults,
  placeholder,
}) => {
  return (
    <div>
      <SearchBar
        placeholder={placeholder || ""}
        value={query}
        onChange={onSearchChange}
        size="md"
        leadingIcon={<MagnifyingGlassIcon width="16px" height="16px" />}
      />
      {!hideResults && query && (
        <Text
          type="body1"
          style={{
            color: globalConstants?.color?.textSecondary as string,
            display: "block",
            marginTop: "15px",
          }}
        >
          {totalSearchResults} search results for &quot;{query}&quot;
        </Text>
      )}
    </div>
  );
};

const SearchBar = styled(TextInput)`
  max-width: 700px !important;
  width: 80%;
`;

export default Search;
