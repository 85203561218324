import { GraphQLError } from "graphql";
import React, { useEffect, useMemo, useState } from "react";

import { HardwareRequestStatus, HardwareSku } from "src/api/types.generated";
import { errorToast } from "src/shared/components";
import HardwareRequestsHistoryList from "src/shared/components/Hardware/RequestsList";
import { processRequests } from "src/shared/utils/hardware/processRequests";
import globalConstants from "src/theme/globalConstants";

import { useApproveHackerHardwareRequestMutation } from "../../graphql/approveHackerHardwareRequest.generated";
import { useGetAllHardwareRequestsQuery } from "../../graphql/getAllHardwareRequests.generated";
import { useGetRemainingQuantityQuery } from "../../graphql/getRemainingQuantity.generated";
import { useReturnHackerHardwareRequestMutation } from "../../graphql/returnHackerHardwareRequest.generated";
import { useUpdateHardwareRequestStatusMutation } from "../../graphql/updateHardwareRequestStatus.generated";

import HardwareDetailsModal from "./detailsModal";

type TOrganizerRequestHistoryProps = {
  items: Pick<
    HardwareSku,
    | "code"
    | "image"
    | "type"
    | "name"
    | "location"
    | "short_description"
    | "long_description"
    | "category_id"
  >[];
  status: HardwareRequestStatus | undefined;
  hideLottery: boolean;
};

const OrganizerRequestHistory = ({
  items,
  status,
  hideLottery,
}: TOrganizerRequestHistoryProps) => {
  const { data: hardwareRequestsData, refetch: refetchHardwareRequests } =
    useGetAllHardwareRequestsQuery({
      variables: {
        where: status ? { status: { equals: status } } : {},
      },
    });

  const [remainingQuantityMap, setRemainingQuantityMap] = useState<{
    [key: string]: number;
  }>({});

  const { data: remainingQuantityData } = useGetRemainingQuantityQuery();
  const remainingQuantity = remainingQuantityData?.hardwareItems;

  useEffect(() => {
    if (remainingQuantity) {
      const quantityMap: { [key: string]: number } = {};
      remainingQuantity.forEach((item) => {
        if (item.sku_code) {
          quantityMap[item.sku_code] = (quantityMap[item.sku_code] || 0) + 1;
        }
      });
      setRemainingQuantityMap(quantityMap);
    }
  }, [remainingQuantity]);

  const [editRequestHardwareItem, setEditRequestHardwareItem] = useState<{
    requestId: number;
    skuCode: string;
  } | null>(null);
  const closeModal = () => setEditRequestHardwareItem(null);

  const [approveHackerHardwareRequest] =
    useApproveHackerHardwareRequestMutation();

  const [updateHardwareRequestStatus] =
    useUpdateHardwareRequestStatusMutation();

  const [returnHackerHardwareRequest] =
    useReturnHackerHardwareRequestMutation();

  const hardwareRequests = useMemo(() => {
    return processRequests({
      remainingQuantityMap,
      data: hardwareRequestsData?.hardwareRequests,
      items,
      userType: "organizer",
      getButtons: (status) => {
        if (status === HardwareRequestStatus.Pending) {
          return [
            {
              text: "Approve",
              textColor: globalConstants?.color?.textSuccess as string,
              onClick: async (id, requestId) => {
                if (requestId) {
                  try {
                    await approveHackerHardwareRequest({
                      variables: {
                        id: requestId,
                      },
                    });
                    await refetchHardwareRequests();
                  } catch (e) {
                    errorToast((e as GraphQLError)?.message);
                  }
                }
              },
              color: "secondary",
            },
            {
              text: "Deny",
              onClick: async (id, requestId) => {
                if (requestId) {
                  try {
                    await updateHardwareRequestStatus({
                      variables: {
                        id: requestId,
                        status: HardwareRequestStatus.Denied,
                      },
                    });
                    await refetchHardwareRequests();
                  } catch (e) {
                    errorToast((e as GraphQLError)?.message);
                  }
                }
              },
              color: "secondary-danger",
            },
          ];
        }
        if (status === HardwareRequestStatus.Approved) {
          return [
            {
              text: "Mark as Ready",
              onClick: async (id, requestId) => {
                if (requestId) {
                  try {
                    await updateHardwareRequestStatus({
                      variables: {
                        id: requestId,
                        status: HardwareRequestStatus.Ready,
                      },
                    });
                    await refetchHardwareRequests();
                  } catch (e) {
                    errorToast((e as GraphQLError)?.message);
                  }
                }
              },
              color: "secondary",
            },
            {
              text: "Revoke Approval",
              onClick: async (id, requestId) => {
                if (requestId) {
                  try {
                    await updateHardwareRequestStatus({
                      variables: {
                        id: requestId,
                        status: HardwareRequestStatus.Pending,
                      },
                    });
                    await refetchHardwareRequests();
                  } catch (e) {
                    errorToast((e as GraphQLError)?.message);
                  }
                }
              },
              color: "secondary-danger",
            },
          ];
        }
        if (status === HardwareRequestStatus.Ready) {
          return [
            {
              text: "Mark as Fulfilled",
              onClick: async (id, requestId) => {
                if (requestId) {
                  try {
                    await updateHardwareRequestStatus({
                      variables: {
                        id: requestId,
                        status: HardwareRequestStatus.Fulfilled,
                      },
                    });
                    await refetchHardwareRequests();
                  } catch (e) {
                    errorToast((e as GraphQLError)?.message);
                  }
                }
              },
              color: "secondary",
            },
            {
              text: "Revoke Approval",
              onClick: async (id, requestId) => {
                if (requestId) {
                  try {
                    await updateHardwareRequestStatus({
                      variables: {
                        id: requestId,
                        status: HardwareRequestStatus.Pending,
                      },
                    });
                    await refetchHardwareRequests();
                  } catch (e) {
                    errorToast((e as GraphQLError)?.message);
                  }
                }
              },
              color: "secondary-danger",
            },
          ];
        }
        if (status === HardwareRequestStatus.Fulfilled) {
          return [
            {
              text: "Mark as Returned",
              onClick: async (id, requestId) => {
                if (requestId) {
                  try {
                    await returnHackerHardwareRequest({
                      variables: {
                        id: requestId,
                      },
                    });
                    await refetchHardwareRequests();
                  } catch (e) {
                    errorToast((e as GraphQLError)?.message);
                  }
                }
              },
              color: "secondary",
            },
          ];
        }
        return [];
      },
      onClick: (requestId, skuCode) => {
        setEditRequestHardwareItem({ requestId, skuCode });
      },
      hideLottery,
    });
  }, [
    hardwareRequestsData,
    items,
    approveHackerHardwareRequest,
    refetchHardwareRequests,
    updateHardwareRequestStatus,
    returnHackerHardwareRequest,
    remainingQuantityMap,
    hideLottery,
  ]);

  return (
    <>
      {editRequestHardwareItem !== null && (
        <HardwareDetailsModal
          open={editRequestHardwareItem !== null}
          closeModal={closeModal}
          requestId={editRequestHardwareItem.requestId}
          skuCode={editRequestHardwareItem.skuCode}
          itemDetails={{
            name:
              items.find(
                (item) => item.code === editRequestHardwareItem.skuCode
              )?.name ?? "Unknown Item",
            location:
              items.find(
                (item) => item.code === editRequestHardwareItem.skuCode
              )?.location ?? "Unknown Location",
          }}
        />
      )}
      <HardwareRequestsHistoryList
        hardwareRequests={hardwareRequests.filter((request) =>
          status ? request.status === status : true
        )}
        userType="organizer"
      />
    </>
  );
};

export default OrganizerRequestHistory;
