import { Spacer } from "@hackthenorth/north";
import React from "react";
import styled from "styled-components";

import { Header } from "src/shared/components";
import Schedule from "src/views/hacker/home/schedule/Schedule";

import "twin.macro";
import "styled-components/macro";

const ScheduleView: React.FC = () => {
  return (
    <div tw="flex flex-col h-full w-full">
      <Header title="Schedule" subtitle="View the schedule for the weekend!" />
      <HomePageWrapper>
        <Container>
          <Spacer height={40} />
          <Schedule />
        </Container>
      </HomePageWrapper>
    </div>
  );
};

const HomePageWrapper = styled.div`
  height: 100%;
  max-width: 100%;
  overflow: hidden;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export default ScheduleView;
