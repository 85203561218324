/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../api/types.generated";
export type ApproveHackerHardwareRequestMutationVariables = Types.Exact<{
  id: Types.Scalars["Float"];
}>;

export type ApproveHackerHardwareRequestMutation = {
  approveHackerHardwareRequest: Pick<Types.HardwareRequest, "id" | "status">;
};

export const ApproveHackerHardwareRequestDocument = gql`
  mutation ApproveHackerHardwareRequest($id: Float!) {
    approveHackerHardwareRequest(id: $id) {
      id
      status
    }
  }
`;

/**
 * __useApproveHackerHardwareRequestMutation__
 *
 * To run a mutation, you first call `useApproveHackerHardwareRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveHackerHardwareRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveHackerHardwareRequestMutation, { data, loading, error }] = useApproveHackerHardwareRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApproveHackerHardwareRequestMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ApproveHackerHardwareRequestMutation,
    ApproveHackerHardwareRequestMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ApproveHackerHardwareRequestMutation,
    ApproveHackerHardwareRequestMutationVariables
  >(ApproveHackerHardwareRequestDocument, baseOptions);
}
export type ApproveHackerHardwareRequestMutationHookResult = ReturnType<
  typeof useApproveHackerHardwareRequestMutation
>;
export type ApproveHackerHardwareRequestMutationResult =
  ApolloReactCommon.MutationResult<ApproveHackerHardwareRequestMutation>;
export type ApproveHackerHardwareRequestMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    ApproveHackerHardwareRequestMutation,
    ApproveHackerHardwareRequestMutationVariables
  >;
