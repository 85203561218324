import { Link } from "north.js";
import React from "react";

type THardwareFaq = {
  question: string;
  answer: React.ReactNode;
};

export const HARDWARE_FAQ: THardwareFaq[] = [
  {
    question: "Can I sign out hardware without requesting it first?",
    answer:
      'All items listed as "check-out" and "lottery" must be requested through the hardware tool before being picked up. Items listed as "free" don’t need to be requested, you can grab these directly at the Shopify Hardware Hub.',
  },
  {
    question: "When do I need to request hardware?",
    answer:
      "You can request and pick up hardware at any time during the event. Please note that once hacking starts, most items are likely to be signed out already.",
  },
  {
    question: "When do I need to return my hardware?",
    answer:
      "Hardware must be returned by 2:00 PM on Sunday at the Shopify Hardware Hub. If you are a Hack the North finalist, you can return your hardware after the closing ceremonies.",
  },
  {
    question: "How long do I have to pick up my hardware?",
    answer:
      "Hardware must be picked up within 3 hours of it being ready for pickup. For our high demand lottery items, your items must be picked up within an hour.",
  },
  {
    question: "Can I have someone else sign out hardware for me?",
    answer:
      "No, you need to pick up your hardware items yourself. Hackers are required to leave their ID with us until their hardware items have been returned.",
  },
  {
    question: "What if I can’t leave my ID with you?",
    answer:
      "Please talk to us at the Shopify Hardware Hub. We’ll work with you to see if we can accommodate your case.",
  },
  {
    question: "When are you running the lottery for lottery items?",
    answer:
      "The lottery will run at 1:00 AM on Saturday. A Slack message will be posted in the #hardware channel when hardware lottery selection has been completed.",
  },
  {
    question: "What if the only items I want are listed as free items?",
    answer:
      "Free items are available to just “grab & go” at the Shopify Hardware Hub. You don’t need to sign these out on the hardware tool.",
  },
  {
    question: "How long will it take you to approve my hardware?",
    answer: (
      <>
        Starting on Friday, hardware approvals should only take up to an hour at
        most. Closer to when hacking starts, orders should be approved within a
        few minutes. Please email{" "}
        <Link
          href="mailto:hardware@hackthenorth.com"
          target="_blank"
          rel="noopener"
        >
          hardware@hackthenorth.com
        </Link>{" "}
        with any concerns on approval.
      </>
    ),
  },
  {
    question: "What do I do if my hardware gets canceled or denied?",
    answer:
      "The most common reasons for canceled or denied orders are a lack of supply on our end, or an order with an unreasonable amount of requests. Please see us at the Shopify Hardware Hub for more information.",
  },
  {
    question: "What if I accidentally damage my items before returning them?",
    answer:
      "Don’t worry, you will still get your ID back. If the hardware you receive is defective or you think you have damaged it, please visit the hardware checkout desk. Replacements may be available, but please always inform us if your hardware is broken so that we don’t give it out to someone else — you won’t be required to pay for it unless it was clearly damaged from misuse.",
  },
  {
    question:
      "What if I damage an item by accident and want to borrow a new one?",
    answer:
      "Please return your item to the hardware checkout at the Shopify Hardware Hub. We will try to replace your item if we have extras in stock.",
  },
  {
    question: "Are there 3D Printers and Laser Cutters available?",
    answer: (
      <>
        Yes! This year, we have <b>24/7 access to the 3D printing lab</b>, which
        is equipped with over 10 Prusa printers and all the filament you can
        dream of! Additionally, the laser cutting machine in the lab will be
        available to hackers on Saturday afternoon. The 3D printing lab is
        located in room 2002 on Floor 2 of E5. 3D printers will be available on
        a first-come, first-served basis throughout the weekend, while laser
        cutting will be accessible only on Saturday afternoon — timings will be
        announced on Slack. Visit the lab with your design ready to go in an STL
        file format and our volunteers can show you what to do next! Complete
        beginner to 3D printing and don’t know where to start? Visit us anyways
        and we can help you get started.
      </>
    ),
  },
];
