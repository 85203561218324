export type FAQSection = {
  label: string;
  sectionId: string;
  shareId: string;
};

export const FAQ_SHARE_ID = "b9a305a9-0cdc-4a09-9272-547bf40191c7";

const GENERAL_SHARE_ID = "b045836b-aac4-41ae-90ae-7b75a0564b26";
const SWAG_SHARE_ID = "a4501685-10a8-4c03-bfb8-4ed1f08c014b";
const ACTIVITIES_SHARE_ID = "60c02d26-423a-40e8-ab12-f3fbb8d20cc7";
const MENTORSHIP_SHARE_ID = "ce2ba29f-2a19-4b63-979a-b771d1b930c4";
const JUDGING_SHARE_ID = "0d3e4889-c4e1-454d-96a9-c20addc17360";

export const FAQ_SECTIONS: FAQSection[] = [
  {
    label: "General",
    sectionId: "general",
    shareId: GENERAL_SHARE_ID,
  },
  {
    label: "Swag",
    sectionId: "swag",
    shareId: SWAG_SHARE_ID,
  },
  {
    label: "Activities",
    sectionId: "activities",
    shareId: ACTIVITIES_SHARE_ID,
  },
  {
    label: "Mentorship",
    sectionId: "mentorship",
    shareId: MENTORSHIP_SHARE_ID,
  },
  {
    label: "Judging",
    sectionId: "judging",
    shareId: JUDGING_SHARE_ID,
  },
];
