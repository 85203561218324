import { Spacer } from "@hackthenorth/north";
import { Link } from "north.js";
import React from "react";
import styled from "styled-components";

import { PageWrapper } from "src/shared/components";
import { useUserContext } from "src/shared/contexts";
import { Field } from "src/shared/contexts/HackerContext/types";
import { useHackerState } from "src/shared/contexts/HackerContext/useHackerState";
import { useDeviceSize } from "src/shared/hooks";
import AppleWallet from "src/static/img/applewallet.svg";
import GoogleWallet from "src/static/img/googlewallet.svg";

import { TopBar } from "../hacker/rsvp/home/topBar";

import TicketContainer from "./components/ticketContainer";
import { WalletButton } from "./components/ticketModal";
import { capitalizeFirstLetter } from "./util";

const NewPageWrapper = styled(PageWrapper)`
  max-width: 100%;
`;

const shirtSizes = {
  XS: "Extra Small",
  S: "Small",
  M: "Medium",
  L: "Large",
  XL: "Extra Large",
  "2XL": "2 Extra Large",
  None: "N/A",
};

const ACCEPTED_FIELDS = [
  Field.SHORT_SCHOOL,
  Field.RSVP_DEADLINE,
  Field.TRAVEL_OPTION,
  Field.REIMBURSEMENT_RECIPIENT_NAME,
  Field.TRAVEL_RECEIPT,
  Field.TRAVEL_PHONE_NUMBER,
  Field.SHIRT_SIZE,
  Field.SHIRT_TYPE,
];

const TicketView: React.FC = () => {
  const { responsesState } = useHackerState(ACCEPTED_FIELDS, {});
  const { apple_wallet_url, google_wallet_url } = useUserContext();

  const userShirtSize: string = responsesState[Field.SHIRT_SIZE] || "None";
  const isTabletOrSmaller = useDeviceSize("medium");

  return (
    <NewPageWrapper>
      <OuterContainer>
        <TopBar />
        <HomePageWrapper>
          <CenterFlex isTabletOrSmaller={isTabletOrSmaller}>
            <TextHolder isTabletOrSmaller={isTabletOrSmaller}>
              <Heading1Text>My ticket</Heading1Text>
              <SubtitleText>
                This is your ticket for the event and will be needed to register
                (a.k.a. check in) at Hack the North 2024. Please press &quot;
                View my ticket&quot;, then use the buttons below to add this
                ticket to your mobile wallet or take a screenshot of the ticket
                page. Registration begins at 5:30 PM local time at the RBC Oasis
                Tent in the parking lot of the University of Waterloo&apos;s E7
                building.
                <br />
                <br />
                If you have any questions, please contact{" "}
                <Link href="mailto:hello@hackthenorth.com">
                  hello@hackthenorth.com
                </Link>
                !
              </SubtitleText>
            </TextHolder>
            <ImgWrapper>
              <TicketContainer
                shirtType={capitalizeFirstLetter(
                  responsesState[Field.SHIRT_TYPE]
                )}
                shirtSize={shirtSizes[userShirtSize]}
              />
              <WalletContainer>
                <WalletButton href={apple_wallet_url} icon={AppleWallet} />
                <Spacer width="16px" />
                <WalletButton href={google_wallet_url} icon={GoogleWallet} />
              </WalletContainer>
            </ImgWrapper>
          </CenterFlex>
        </HomePageWrapper>
      </OuterContainer>
    </NewPageWrapper>
  );
};

export default TicketView;

const OuterContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 80px;
`;

const HomePageWrapper = styled.div`
  height: 100%;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  flex-direction: column;
  gap: 10px;

  ${({ theme }) => theme.mediaQueries.tablet`
    width: 100%;
  `}
`;

const WalletContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const CenterFlex = styled.div<{ isTabletOrSmaller: boolean }>`
  display: flex;
  justify-content: center;
  gap: 22px;
  width: 100%;
  flex-direction: ${({ isTabletOrSmaller }) =>
    isTabletOrSmaller ? "column-reverse" : "row"};
  align-items: ${({ isTabletOrSmaller }) =>
    isTabletOrSmaller ? "center" : "flex-start"};
`;

const TextHolder = styled.div<{ isTabletOrSmaller: boolean }>`
  width: ${({ isTabletOrSmaller }) => (isTabletOrSmaller ? "100%" : "70%")};
  padding: 0px
    ${({ isTabletOrSmaller }) => (isTabletOrSmaller ? "0px" : "63px")} 0px 0px;
`;

const Heading1Text = styled.h1`
  margin-top: 0;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.globalConstants.color.textBody};
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.heading};
  font-weight: 900;
  font-size: 28px;

  ${({ theme }) => theme.mediaQueries.largeMobile`
    font-size: 24px;
  `}
`;

const SubtitleText = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.globalConstants.color.textSecondary};
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5rem;
`;
