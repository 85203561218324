import { Button, Text } from "north.js";
import React from "react";
import styled from "styled-components";

import Modal from "src/shared/components/Modal";
import globalConstants from "src/theme/globalConstants";

import { THardwareSku } from "../../shared/hardwareModels";

interface InfoModalProps {
  isOpen: boolean;
  onClose: () => void;
  onRequest: () => void;
  hardwareItem: THardwareSku;
  categoryName: string;
}

const InfoModal: React.FC<InfoModalProps> = ({
  isOpen,
  onClose,
  onRequest,
  hardwareItem,
  categoryName,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={hardwareItem.name}
      width={window.innerWidth <= 766 ? "100%" : "766px"}
    >
      <ContentWrapper>
        <FlexRow>
          <Image src={hardwareItem.image} />
          <FlexCol>
            <LargeBodyBold>Description</LargeBodyBold>
            <StyledBodyBold>{hardwareItem.long_description}</StyledBodyBold>
          </FlexCol>
        </FlexRow>
        <InfoRow>
          {/* <InfoCol>
            <LargeBodyBold>Keywords</LargeBodyBold>
            <StyledBodyBold>{hardwareItem.name}</StyledBodyBold>
          </InfoCol> */}
          <InfoCol>
            <LargeBodyBold>Item name</LargeBodyBold>
            <StyledBodyBold>{hardwareItem.name}</StyledBodyBold>
          </InfoCol>
          <InfoCol>
            <LargeBodyBold>Category</LargeBodyBold>
            <StyledBodyBold>{categoryName}</StyledBodyBold>
          </InfoCol>
          {/* <InfoCol>
            <LargeBodyBold>Type</LargeBodyBold>
            <StyledBodyBold>{hardwareItem.type}</StyledBodyBold>
          </InfoCol> */}
        </InfoRow>
        {hardwareItem.type !== "FREE" && (
          <StyledButton
            onClick={() => {
              onClose();
              onRequest();
            }}
            size="md"
            color="primary"
          >
            Request
          </StyledButton>
        )}
      </ContentWrapper>
    </Modal>
  );
};

const StyledButton = styled(Button)`
  align-self: flex-end;
`;

const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 80px;
`;

const StyledBodyBold = styled(Text)`
  color: ${globalConstants?.color?.brandSecondary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 28.8px */
  word-wrap: break-word;
`;

const LargeBodyBold = styled(Text)`
  color: ${globalConstants?.color?.brandSecondary};

  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 32px */
`;

const InfoCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 12px 0px 24px 0px;
  max-height: 80vh;
  overflow-y: auto;
`;

const Image = styled.img`
  width: 124px;
  height: 124px;
  border-radius: 8px;
  background: linear-gradient(
      0deg,
      rgba(156, 163, 175, 0.2) 0%,
      rgba(156, 163, 175, 0.2) 100%
    ),
    url(<path-to-image>) lightgray 50% / cover no-repeat;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
`;

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export default InfoModal;
