/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../api/types.generated";
export type GetUsersByNameQueryVariables = Types.Exact<{
  name: Types.Scalars["String"];
}>;

export type GetUsersByNameQuery = {
  users: Array<
    Pick<
      Types.User,
      "id" | "name" | "email" | "role" | "created_at" | "updated_at"
    >
  >;
};

export const GetUsersByNameDocument = gql`
  query GetUsersByName($name: String!) {
    users(where: { name: { contains: $name, mode: insensitive } }, take: 100) {
      id
      name
      email
      role
      created_at
      updated_at
    }
  }
`;

/**
 * __useGetUsersByNameQuery__
 *
 * To run a query within a React component, call `useGetUsersByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetUsersByNameQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetUsersByNameQuery,
    GetUsersByNameQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetUsersByNameQuery,
    GetUsersByNameQueryVariables
  >(GetUsersByNameDocument, baseOptions);
}
export function useGetUsersByNameLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetUsersByNameQuery,
    GetUsersByNameQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetUsersByNameQuery,
    GetUsersByNameQueryVariables
  >(GetUsersByNameDocument, baseOptions);
}
export type GetUsersByNameQueryHookResult = ReturnType<
  typeof useGetUsersByNameQuery
>;
export type GetUsersByNameLazyQueryHookResult = ReturnType<
  typeof useGetUsersByNameLazyQuery
>;
export type GetUsersByNameQueryResult = ApolloReactCommon.QueryResult<
  GetUsersByNameQuery,
  GetUsersByNameQueryVariables
>;
