/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../api/types.generated";
export type UpdateHardwareRequestByOrganizerMutationVariables = Types.Exact<{
  id: Types.Scalars["Float"];
  qty: Types.Scalars["Float"];
}>;

export type UpdateHardwareRequestByOrganizerMutation = {
  updateHardwareRequestByOrganizer: Pick<
    Types.HardwareRequest,
    "id" | "quantity"
  >;
};

export const UpdateHardwareRequestByOrganizerDocument = gql`
  mutation UpdateHardwareRequestByOrganizer($id: Float!, $qty: Float!) {
    updateHardwareRequestByOrganizer(id: $id, quantity: $qty) {
      id
      quantity
    }
  }
`;

/**
 * __useUpdateHardwareRequestByOrganizerMutation__
 *
 * To run a mutation, you first call `useUpdateHardwareRequestByOrganizerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHardwareRequestByOrganizerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHardwareRequestByOrganizerMutation, { data, loading, error }] = useUpdateHardwareRequestByOrganizerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      qty: // value for 'qty'
 *   },
 * });
 */
export function useUpdateHardwareRequestByOrganizerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateHardwareRequestByOrganizerMutation,
    UpdateHardwareRequestByOrganizerMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateHardwareRequestByOrganizerMutation,
    UpdateHardwareRequestByOrganizerMutationVariables
  >(UpdateHardwareRequestByOrganizerDocument, baseOptions);
}
export type UpdateHardwareRequestByOrganizerMutationHookResult = ReturnType<
  typeof useUpdateHardwareRequestByOrganizerMutation
>;
export type UpdateHardwareRequestByOrganizerMutationResult =
  ApolloReactCommon.MutationResult<UpdateHardwareRequestByOrganizerMutation>;
export type UpdateHardwareRequestByOrganizerMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateHardwareRequestByOrganizerMutation,
    UpdateHardwareRequestByOrganizerMutationVariables
  >;
