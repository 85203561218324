import { Text } from "north.js";
import React, { useEffect, useMemo, useState } from "react";

import { Divider, Header, PageWrapper } from "src/shared/components";
import { HardwareItemCard } from "src/shared/components/Hardware";
import globalConstants from "src/theme/globalConstants";

import { useGetRequestsQuery } from "../graphql/getAllRequests.generated";
import { useGetRemainingQuantityQuery } from "../graphql/getRemainingQuantity.generated";
import Loader from "../shared/Loader";
import { useGetHardwareInfo } from "../shared/useGetHardwareInfo";

import LotteryModal from "./modal";

const HackerInventory: React.FC = () => {
  const [lotteryModalItem, setLotteryModalItem] = useState<string | null>(null);

  const {
    hardwareCategories,
    hardwareSkus,
    hardwareCategoriesDataLoading,
    hardwareSkusDataLoading,
  } = useGetHardwareInfo();

  const filteredHardwareItems = useMemo(() => {
    let filtered = hardwareSkus;

    const lotteryCategoryId = hardwareCategories.find(
      (category) => category.name === "Lottery"
    )?.id;

    filtered = filtered.filter(
      (item) => item.category_id === lotteryCategoryId
    );

    return filtered;
  }, [hardwareSkus, hardwareCategories]);
  const [requestFrequency, setRequestFrequency] = useState<{
    [key: string]: number;
  }>({});

  const { data: requestsData } = useGetRequestsQuery();
  const requests = requestsData?.hardwareRequests;

  useEffect(() => {
    if (requests) {
      const frequency: { [key: string]: number } = {};
      requests.forEach((request) => {
        if (request.sku_code) {
          frequency[request.sku_code] = (frequency[request.sku_code] || 0) + 1;
        }
      });
      setRequestFrequency(frequency);
    }
  }, [requests]);

  const getItemBySkuCode = (skuCode: string) => {
    const item = filteredHardwareItems.find((item) => item.code === skuCode);
    return item;
  };

  const [remainingQuantityMap, setRemainingQuantityMap] = useState<{
    [key: string]: number;
  }>({});

  const { data: remainingQuantityData } = useGetRemainingQuantityQuery();
  const remainingQuantity = remainingQuantityData?.hardwareItems;

  useEffect(() => {
    if (remainingQuantity) {
      const quantityMap: { [key: string]: number } = {};
      remainingQuantity.forEach((item) => {
        if (item.sku_code) {
          quantityMap[item.sku_code] = (quantityMap[item.sku_code] || 0) + 1;
        }
      });
      setRemainingQuantityMap(quantityMap);
    }
  }, [remainingQuantity]);

  if (hardwareCategoriesDataLoading || hardwareSkusDataLoading) {
    return <Loader />;
  }

  return (
    <>
      {lotteryModalItem && (
        <LotteryModal
          skuCode={lotteryModalItem}
          open={!!lotteryModalItem}
          closeModal={() => setLotteryModalItem(null)}
          getItemBySkuCode={getItemBySkuCode}
        />
      )}
      <Header
        title="Hardware"
        subtitle="Browse and request a variety of hardware to bring your project to life!"
      />
      <PageWrapper>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            marginBottom: "48px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <Text type="h2">Lottery</Text>
            <Text
              type="body1"
              style={{ color: globalConstants?.color?.textSecondary as string }}
            >
              View hacker requests and approve them through random draws.
            </Text>
          </div>
        </div>
        {filteredHardwareItems?.map((item, index) => (
          <>
            {index !== 0 && <Divider style={{ margin: "24px 0" }} />}
            <HardwareItemCard
              key={item.code}
              skuCode={item.code}
              image={item.image}
              showRequestCount
              showRemainingQuantity
              requestedQuantity={requestFrequency[item.code] || 0}
              remainingQuantity={remainingQuantityMap[item.code] || 0}
              onClick={() => setLotteryModalItem(item.code)}
              label={
                item.type === "LOTTERY"
                  ? [{ text: "Lottery Item", color: "primary-light" }]
                  : []
              }
              title={item.name}
              description={item.short_description}
            />
          </>
        ))}
      </PageWrapper>
    </>
  );
};

export default HackerInventory;
