import { Modal, Spinner } from "@hackthenorth/north";
import React from "react";
import styled from "styled-components";

const Loader: React.FC = () => {
  return (
    <Modal isOpen>
      <FlexContainer>
        <Spinner />
      </FlexContainer>
    </Modal>
  );
};

const FlexContainer = styled.div`
  justify-content: center;
  align-items: center;
`;

export default Loader;
