import { GraphQLError } from "graphql";
import {
  Button,
  ButtonGroup,
  Label,
  Table,
  Text,
  TextArea,
  TextInput,
} from "north.js";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { HardwareRequestHistoryEntry, User } from "src/api/types.generated";
import { errorToast } from "src/shared/components";
import Modal from "src/shared/components/Modal";
import { mapStatusToLabelColor } from "src/shared/utils/hardware/mapStatusToLabelColor";
import { ChevronLeftIcon, ChevronRightIcon } from "src/static/icons";
import { deviceBreakpoints } from "src/theme/deviceBreakpoints";
import globalConstants from "src/theme/globalConstants";

import { useGetHardwareItemsQuery } from "../../graphql/getHardwareItems.generated";
import { useGetHardwareRequestQuery } from "../../graphql/getHardwareRequest.generated";
import { useUpdateHackerHardwareRequestByHackerMutation } from "../../graphql/updateHackerHardwareRequest.generated";
import { useUpdateHardwareRequestByOrganizerMutation } from "../../graphql/updateHardwareRequestByOrganizer.generated";

interface HardwareDetailsModalProps {
  open: boolean;
  closeModal: () => void;
  requestId: number;
  skuCode: string;
  itemDetails: {
    name: string;
    location: string;
  };
}

const HardwareDetailsModal: React.FC<HardwareDetailsModalProps> = ({
  open,
  closeModal,
  requestId,
  skuCode,
  itemDetails,
}) => {
  const [page, setPage] = useState(0);
  const itemsPerPage = 5;

  const handleClose = () => {
    closeModal();
  };

  const {
    data: hardwareRequestData,
    loading: hardwareRequestLoading,
    refetch: refetchHardwareRequest,
  } = useGetHardwareRequestQuery({
    variables: {
      id: requestId,
    },
  });

  const { data: hardwareItemsData, loading: hardwareItemsLoading } =
    useGetHardwareItemsQuery({
      variables: {
        where: {
          sku_code: {
            equals: skuCode,
          },
        },
      },
    });

  const [updateHackerHardwareRequestByHacker, { loading: isSavingNotes }] =
    useUpdateHackerHardwareRequestByHackerMutation();

  const [updateHackerHardwareRequestByOrganizer, { loading: isSavingQty }] =
    useUpdateHardwareRequestByOrganizerMutation();

  const hardwareRequest = hardwareRequestData?.hardwareRequest;
  const hardwareItems = hardwareItemsData?.hardwareItems;

  const [quantityInput, setQuantityInput] = useState(0);
  const [updateRequestNotes, setUpdateRequestNotes] = useState("");

  useEffect(() => {
    if (hardwareRequest) {
      setUpdateRequestNotes(hardwareRequest.notes);
      setQuantityInput(hardwareRequest.quantity);
    }
  }, [hardwareRequest]);

  const handleSaveNotes = () => {
    updateHackerHardwareRequestByHacker({
      variables: {
        id: requestId,
        notes: updateRequestNotes,
      },
    }).catch((e) => {
      errorToast((e as GraphQLError)?.message);
    });

    refetchHardwareRequest();
  };

  const handleSaveQty = () => {
    updateHackerHardwareRequestByOrganizer({
      variables: {
        id: requestId,
        qty: quantityInput,
      },
    }).catch((e) => {
      errorToast((e as GraphQLError)?.message);
    });
  };

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value === "" ? 0 : parseInt(e.target.value);
    setQuantityInput(isNaN(value) ? 0 : value);
  };

  if (!hardwareRequest || !hardwareItems) {
    return null;
  }

  const NUM_PAGES = Math.ceil(hardwareItems.length / itemsPerPage);

  return (
    <Modal
      onClose={handleClose}
      isOpen={open}
      width={window.innerWidth <= 766 ? "100%" : "766px"}
      title={
        hardwareItemsLoading || hardwareRequestLoading
          ? "Loading Request..."
          : itemDetails.name
      }
    >
      <Flex
        column
        gap={0}
        style={{
          marginTop: 16,
          maxHeight: "80vh",
          overflowY: "auto",
        }}
      >
        <RequestInfoWrapper>
          <EmailWrapper>
            <SectionHeading type="body1">Email</SectionHeading>
            <Text type="body1">{hardwareRequest.user.email}</Text>
          </EmailWrapper>
          <Flex column gap={0}>
            <SectionHeading type="body1">Date/Time Requested</SectionHeading>
            <Text type="body1">
              {new Date(hardwareRequest.created_at).toLocaleString()}
            </Text>
          </Flex>
          <Flex column gap={0}>
            <SectionHeading type="body1">Location</SectionHeading>
            <Text type="body1">{itemDetails.location}</Text>
          </Flex>
        </RequestInfoWrapper>

        <Flex column gap={16} style={{ marginBottom: 24, width: "100%" }}>
          <SectionHeading type="body1">Units</SectionHeading>
          <TableWrapper>
            <Table
              displayStyle={"plain"}
              columns={[
                {
                  id: "number",
                  name: "Number",
                },
                {
                  id: "notes",
                  name: "Notes",
                },
              ]}
              data={hardwareItems.map((item) => ({
                number: item.id,
                notes: item.notes,
              }))}
              page={page}
              itemsPerPage={itemsPerPage}
              showFooter
            />
          </TableWrapper>

          <PaginationWrapper>
            <ButtonGroup>
              <Button
                size="sm"
                disabled={page === 0}
                onClick={() => setPage(Math.max(0, page - 1))}
              >
                <ChevronLeftIcon className="w-4 h-4" />
              </Button>
              {Array.from({ length: NUM_PAGES }, (_, i) => (
                <Button
                  size="sm"
                  color={page === i ? "primary" : "secondary"}
                  onClick={() => setPage(i)}
                >
                  {i + 1}
                </Button>
              ))}
              <Button
                size="sm"
                disabled={page === NUM_PAGES - 1}
                onClick={() => setPage(Math.min(NUM_PAGES, page + 1))}
              >
                <ChevronRightIcon className="w-4 h-4" />
              </Button>
            </ButtonGroup>
          </PaginationWrapper>
        </Flex>

        <Flex column gap={16} style={{ marginBottom: 40, width: "100%" }}>
          <Flex gap={8}>
            <SectionHeading type="body1">Notes</SectionHeading>
          </Flex>
          <TextArea
            value={updateRequestNotes}
            onChange={(e) => setUpdateRequestNotes(e.target.value)}
            fullWidth
            placeholder="Add notes to the request here"
          />
          <Button
            onClick={handleSaveNotes}
            disabled={isSavingNotes}
            color="primary"
          >
            {isSavingNotes ? "Saving..." : "Save"}
          </Button>
        </Flex>

        <Flex
          gap={16}
          style={{
            marginBottom: 40,
            width: "100%",
          }}
        >
          <SectionHeading type="body1">Quantity</SectionHeading>
          <TextInput
            type="number"
            min={0}
            value={quantityInput}
            onChange={handleQuantityChange}
            size="sm"
          />
          <Button
            onClick={handleSaveQty}
            disabled={isSavingQty}
            color="primary"
          >
            {isSavingQty ? "Saving..." : "Save"}
          </Button>
        </Flex>

        <HistoryEntryUpdates
          historyEntries={hardwareRequest.hardware_request_history_entry}
        />
      </Flex>
    </Modal>
  );
};

const HistoryEntryUpdates: React.FC<{
  historyEntries: (Pick<
    HardwareRequestHistoryEntry,
    "status" | "time" | "notes" | "id"
  > & {
    modifying_user: Pick<User, "name">;
  })[];
}> = ({ historyEntries }) => {
  // Sort history entries by time in descending order
  const entries = [...historyEntries].sort((a, b) =>
    b.time.localeCompare(a.time)
  );

  return (
    <Flex column gap={16}>
      <SectionHeading type="body1">History</SectionHeading>
      <Flex column gap={16} style={{ flexDirection: "column-reverse" }}>
        {entries.map((entry, index) => {
          if (!entry.status) return null;

          const previousEntry = index > 0 ? entries[index - 1] : null;
          const statusChanged =
            !previousEntry || entry.status !== previousEntry.status;

          return (
            <Flex key={entry.id} column gap={2}>
              <Flex gap={8}>
                <Text
                  type="body1"
                  style={{ color: globalConstants?.color?.textDark as string }}
                >
                  <span style={{ fontWeight: 700 }}>
                    {entry.modifying_user.name}
                  </span>{" "}
                  changed {statusChanged ? "status" : "notes"} to{" "}
                  {!statusChanged ? (
                    entry.notes ? (
                      <span style={{ fontWeight: 700 }}>{entry.notes}</span>
                    ) : (
                      "empty"
                    )
                  ) : null}
                </Text>
                {statusChanged && (
                  <Label size="sm" color={mapStatusToLabelColor(entry.status)}>
                    {entry.status.charAt(0).toUpperCase() +
                      entry.status.slice(1).toLowerCase()}
                  </Label>
                )}
                <HistoryEntryTimeDesktop
                  type="body1"
                  style={{
                    color: globalConstants?.color?.textSecondary as string,
                  }}
                >
                  {" ∙ "}
                  {new Date(entry.time).toLocaleString()}
                </HistoryEntryTimeDesktop>
              </Flex>
              <HistoryEntryTimeMobile
                type="body1"
                style={{
                  color: globalConstants?.color?.textSecondary as string,
                }}
              >
                {new Date(entry.time).toLocaleString()}
              </HistoryEntryTimeMobile>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
};

const SectionHeading = styled(Text)`
  font-size: 20px !important;
  font-weight: 700;
  color: ${({ theme }) => theme.globalConstants.color.textDark};
`;

const Flex = styled.div<{ column?: boolean; gap: number; mediaQuery?: number }>`
  display: flex;
  align-items: ${({ column }) => (column ? "flex-start" : "center")};
  flex-direction: ${({ column }) => (column ? "column" : "row")};
  gap: ${({ gap }) => gap}px;
  max-width: 100%;

  @media (max-width: ${({ mediaQuery }) => mediaQuery}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const TableWrapper = styled.div`
  width: 100% !important;
`;

const RequestInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 64px;
  margin-bottom: 40px;

  @media (max-width: ${deviceBreakpoints.tablet}px) {
    flex-direction: column;
    gap: 16px;
  }
`;

const EmailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 50%;

  span {
    word-wrap: break-word;
  }

  @media (max-width: ${deviceBreakpoints.tablet}px) {
    max-width: 100%;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: end;
`;

const HistoryEntryTimeDesktop = styled(Text)`
  display: none;

  @media (min-width: ${deviceBreakpoints.tablet}px) {
    display: block;
  }
`;

const HistoryEntryTimeMobile = styled(Text)`
  display: block;

  @media (min-width: ${deviceBreakpoints.tablet}px) {
    display: none;
  }
`;

export default HardwareDetailsModal;
