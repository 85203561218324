import { Flex, UnstyledButton, Spinner } from "@hackthenorth/north";
import { Alert, Button, Link, Modal, TextArea } from "north.js";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { Icon } from "src/shared/components";
import { CheckCircleInvertedGreen, InfoIcon } from "src/static/icons";

import { AnsweredBadge, NotAnsweredBadge } from "../Badges";
import { useAnswerSignageQuestionMutation } from "../graphql/answerSignageQuestion.generated";
import { useGetSignageQuestionByIdQuery } from "../graphql/getSignageQuestionById.generated";

type TQuestion = {
  id: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const Question: React.FC<TQuestion> = ({ id, isOpen, setIsOpen }) => {
  const [response, setResponse] = useState("");
  const [submitted, setSubmitted] = useState(false); // a NEW submission, not previous response
  const [isEditing, setIsEditing] = useState(true);

  const {
    data: questionInfo,
    refetch,
    loading,
  } = useGetSignageQuestionByIdQuery({
    variables: {
      question_id: parseInt(id),
    },
    onCompleted: (data) => {
      if (
        data.getSignageQuestionById.signage_answers &&
        data.getSignageQuestionById.signage_answers.length !== 0
      ) {
        setResponse(data.getSignageQuestionById.signage_answers[0].value);
        setIsEditing(false);
      } else {
        setResponse("");
        setIsEditing(true);
      }
      setSubmitted(false);
    },
  });

  useEffect(() => {
    refetch(); // Refetch when id changes
  }, [id, refetch]);

  const [answerSignageQuestion] = useAnswerSignageQuestionMutation();

  const answerQuestion = useCallback(async () => {
    await answerSignageQuestion({
      variables: {
        question_id: parseInt(id),
        answer: response,
      },
    });
    setSubmitted(true);
    setIsEditing(false);
  }, [answerSignageQuestion, id, response]);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <QuestionModal open={isOpen} onClose={() => setIsOpen(false)}>
      {loading ? (
        <LoadingContainer>
          <Spinner />
        </LoadingContainer>
      ) : (
        <SectionContainer>
          <Flex
            row
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <BadgeContainer>
              {questionInfo?.getSignageQuestionById.signage_answers?.length ===
                0 && !submitted ? (
                <NotAnsweredBadge />
              ) : (
                <AnsweredBadge />
              )}
            </BadgeContainer>
            <UnstyledButton onClick={handleClose}>
              <Icon name="x" width="12px" height="12px" strokeWidth="1.2px" />
            </UnstyledButton>
          </Flex>

          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <Heading>{questionInfo?.getSignageQuestionById.title}</Heading>

            {(() => {
              switch (questionInfo?.getSignageQuestionById.type) {
                case "free-form":
                  return <CharacterLimit>(150 character limit)</CharacterLimit>;
                case "one-word":
                  return <CharacterLimit>(15 character limit)</CharacterLimit>;
                default:
                  return null;
              }
            })()}
          </div>

          {!isEditing ? (
            <p style={{ fontSize: "16px", fontWeight: 500, color: "#1F2937" }}>
              {response}
            </p>
          ) : (
            (() => {
              switch (questionInfo?.getSignageQuestionById.type) {
                case "free-form":
                  return (
                    <TextArea
                      autoResize
                      fullWidth
                      maxChars={150}
                      placeholder="Enter your response"
                      value={response}
                      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                        setResponse(e.target.value);
                      }}
                      style={{ height: "17vh", fontSize: "16px" }}
                    />
                  );

                case "number":
                  return (
                    <input
                      type="number"
                      value={response}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setResponse(e.target.value);
                      }}
                      onBlur={() => {
                        if (Number(response) < 0) {
                          setResponse("0");
                        }
                      }}
                      style={{ fontSize: "16px" }}
                    />
                  );

                case "one-word":
                  return (
                    <input
                      type="text"
                      maxLength={15}
                      value={response}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const value = e.target.value;
                        // Regular expression to allow only one word without spaces
                        if (/^\S*$/.test(value)) {
                          setResponse(value);
                        }
                      }}
                      style={{ fontSize: "16px" }}
                    />
                  );

                default:
                  return null; // Or some fallback UI
              }
            })()
          )}
          {submitted ? (
            // NEW submission
            <Alert
              color="success"
              icon={<CheckCircleInvertedGreen />}
              style={{ fontWeight: 500 }}
            >
              Your response has been shared on{" "}
              <a
                href="https://charts.hackthenorth.com/"
                style={{
                  fontWeight: 700,
                  textDecoration: "underline",
                }}
              >
                charts.hackthenorth.com
              </a>
            </Alert>
          ) : questionInfo?.getSignageQuestionById.signage_answers &&
            questionInfo?.getSignageQuestionById.signage_answers.length ===
              0 ? (
            // no answer
            <Alert
              color="primary"
              icon={<InfoIcon />}
              style={{ fontWeight: 500 }}
            >
              Your response will be displayed on{" "}
              <Link href="https://charts.hackthenorth.com/">
                <span style={{ fontWeight: 700 }}>charts.hackthenorth.com</span>
              </Link>{" "}
              once submitted
            </Alert>
          ) : (
            // OLD submission
            !isEditing && (
              <Alert
                color="primary"
                icon={<InfoIcon />}
                style={{ fontWeight: 500 }}
              >
                Your response is displayed on{" "}
                <a
                  href="https://charts.hackthenorth.com/"
                  style={{ fontWeight: 700, textDecoration: "underline" }}
                >
                  charts.hackthenorth.com
                </a>
              </Alert>
            )
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              width: "100%",
            }}
          >
            {submitted && (
              <p style={{ color: "#6B7280", padding: "10px 20px" }}>
                Submitted!
              </p>
            )}
            {!isEditing ? (
              <Button
                color="secondary"
                size="md"
                onClick={() => setIsEditing(true)}
              >
                <span style={{ fontWeight: 500 }}>Edit</span>
              </Button>
            ) : (
              <Button
                color="primary"
                size="md"
                disabled={response.length === 0 || response.length > 150}
                onClick={() => answerQuestion()}
              >
                <span style={{ fontWeight: 500 }}>Submit</span>
              </Button>
            )}
          </div>
        </SectionContainer>
      )}
    </QuestionModal>
  );
};

const QuestionModal = styled(Modal)`
  width: 100%;
  min-width: 300px;
  max-width: 600px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  @media (max-width: ${({ theme }) => theme.deviceBreakpoints?.largeMobile}px) {
    max-width: none;
    max-height: none;
    width: 100vw !important;
    height: 100vh !important;
  }
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const BadgeContainer = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
`;

const Heading = styled.h3`
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.heading};
  font-size: 20px;
  font-weight: 900;
`;

const CharacterLimit = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.globalConstants.color.textSecondary};
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; /* Adjust depending on your modal size */
  font-size: 18px;
  font-weight: 500;
`;

export default Question;
