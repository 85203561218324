/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../api/types.generated";
export type UpdateHackerHardwareRequestByHackerMutationVariables = Types.Exact<{
  id: Types.Scalars["Float"];
  status?: Types.Maybe<Types.Scalars["String"]>;
  notes?: Types.Maybe<Types.Scalars["String"]>;
}>;

export type UpdateHackerHardwareRequestByHackerMutation = {
  updateHardwareRequestByHacker: Pick<
    Types.HardwareRequest,
    "id" | "status" | "notes"
  >;
};

export const UpdateHackerHardwareRequestByHackerDocument = gql`
  mutation UpdateHackerHardwareRequestByHacker(
    $id: Float!
    $status: String
    $notes: String
  ) {
    updateHardwareRequestByHacker(id: $id, status: $status, notes: $notes) {
      id
      status
      notes
    }
  }
`;

/**
 * __useUpdateHackerHardwareRequestByHackerMutation__
 *
 * To run a mutation, you first call `useUpdateHackerHardwareRequestByHackerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHackerHardwareRequestByHackerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHackerHardwareRequestByHackerMutation, { data, loading, error }] = useUpdateHackerHardwareRequestByHackerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useUpdateHackerHardwareRequestByHackerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateHackerHardwareRequestByHackerMutation,
    UpdateHackerHardwareRequestByHackerMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateHackerHardwareRequestByHackerMutation,
    UpdateHackerHardwareRequestByHackerMutationVariables
  >(UpdateHackerHardwareRequestByHackerDocument, baseOptions);
}
export type UpdateHackerHardwareRequestByHackerMutationHookResult = ReturnType<
  typeof useUpdateHackerHardwareRequestByHackerMutation
>;
export type UpdateHackerHardwareRequestByHackerMutationResult =
  ApolloReactCommon.MutationResult<UpdateHackerHardwareRequestByHackerMutation>;
export type UpdateHackerHardwareRequestByHackerMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateHackerHardwareRequestByHackerMutation,
    UpdateHackerHardwareRequestByHackerMutationVariables
  >;
