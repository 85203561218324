import { ApolloQueryResult } from "@apollo/client";
import { GraphQLError } from "graphql";
import { Button, ButtonGroup, Label, Table, Text } from "north.js";
import React, { useState } from "react";
import styled from "styled-components";

import { HardwareRequestStatus } from "src/api/types.generated";
import { errorToast } from "src/shared/components";
import Modal from "src/shared/components/Modal";
import { mapStatusToLabelColor } from "src/shared/utils/hardware/mapStatusToLabelColor";
import { ChevronLeftIcon, ChevronRightIcon } from "src/static/icons";
import { deviceBreakpoints } from "src/theme/deviceBreakpoints";
import globalConstants from "src/theme/globalConstants";

import { useApproveHackerHardwareRequestMutation } from "../graphql/approveHackerHardwareRequest.generated";
import {
  GetAllHardwareRequestsQuery,
  useGetAllHardwareRequestsQuery,
} from "../graphql/getAllHardwareRequests.generated";
import { useGetAvailableHardwareItemsQuery } from "../graphql/getAvailableHardwareItems.generated";
import { useGetHardwareItemsQuery } from "../graphql/getHardwareItems.generated";
import { useGetHardwareRequestCountBySkuQuery } from "../graphql/getHardwareRequestBySku.generated";
import { THardwareSku } from "../shared/hardwareModels";

interface LotteryModalProps {
  open: boolean;
  closeModal: () => void;
  skuCode: string;
  getItemBySkuCode: (skuCode: string) => THardwareSku | undefined;
}

const LotteryModal: React.FC<LotteryModalProps> = ({
  open,
  closeModal,
  skuCode,
  getItemBySkuCode,
}) => {
  const [page, setPage] = useState(0);
  const itemsPerPage = 5;
  const itemData = getItemBySkuCode(skuCode);

  const [selectedRequest, setSelectedRequest] = useState<number | null>(null);

  const handleClose = () => {
    closeModal();
  };

  const { data: hardwareItemsData, loading: hardwareItemsLoading } =
    useGetHardwareItemsQuery({
      variables: {
        where: {
          sku_code: {
            equals: skuCode,
          },
        },
      },
    });

  const {
    data: hardwareRequestsData,
    loading: hardwareRequestsLoading,
    refetch: refetchHardwareRequests,
  } = useGetAllHardwareRequestsQuery({
    variables: {
      where: {
        sku_code: {
          equals: skuCode,
        },
      },
    },
  });

  // Number of requests
  const { data: requestCountData } = useGetHardwareRequestCountBySkuQuery({
    variables: { skuCode: skuCode },
  });
  const requestCount = requestCountData?.hardwareRequests.length;

  // Number of available items
  const { data: availableItemsData } = useGetAvailableHardwareItemsQuery({
    variables: { skuCode: skuCode },
  });
  const existingQuantity = availableItemsData?.hardwareItems.length;

  const hardwareItems = hardwareItemsData?.hardwareItems;
  const hardwareRequests = hardwareRequestsData?.hardwareRequests;

  if (!hardwareItems || !hardwareRequests) {
    return null;
  }

  const NUM_PAGES = Math.ceil(hardwareRequests.length / itemsPerPage);

  const pendingItems = hardwareRequests.filter(
    (req) => req.status === HardwareRequestStatus.Pending
  );

  const randomDraw = () => {
    const randomIndex = Math.floor(Math.random() * pendingItems.length);
    setSelectedRequest(randomIndex);
  };

  return (
    <Modal
      onClose={handleClose}
      isOpen={open}
      width={window.innerWidth <= 766 ? "100%" : "766px"}
      title={
        hardwareItemsLoading || hardwareRequestsLoading
          ? "Loading Request..."
          : itemData?.name
      }
      style={{ maxHeight: "90vh" }}
      scroll
    >
      <Column style={{ marginTop: 16 }}>
        <ItemInfoWrapper>
          <ImageWrapper>
            <img src={itemData?.image} alt={itemData?.name} />
          </ImageWrapper>
          <Column style={{ gap: 16 }}>
            <Column style={{ gap: 8 }}>
              <LargeBodyBold>Description</LargeBodyBold>
              <Text
                type="body1"
                style={{
                  color: globalConstants?.color?.textSecondary as string,
                }}
              >
                {itemData?.short_description}
              </Text>
            </Column>
            <Row style={{ gap: 8 }}>
              <Label color="secondary">
                {requestCount} request{requestCount === 1 ? "" : "s"}
              </Label>
              <Label color="secondary-light">
                {existingQuantity} available
              </Label>
            </Row>
          </Column>
        </ItemInfoWrapper>

        {hardwareRequests.length > 0 ? (
          <>
            <Column style={{ gap: 16, marginBottom: 40 }}>
              <Row style={{ alignItems: "center", gap: 16 }}>
                <SectionHeading type="body1">Random Draw</SectionHeading>
                <Button
                  onClick={randomDraw}
                  disabled={pendingItems.length === 0}
                  color="primary"
                >
                  Select A Request
                </Button>
              </Row>
              {selectedRequest !== null && pendingItems[selectedRequest] ? (
                <Table
                  displayStyle={"plain"}
                  columns={[
                    {
                      id: "name",
                      name: "Name",
                    },
                    {
                      id: "quantity",
                      name: "Quantity",
                    },
                    {
                      id: "time",
                      name: "Request Time",
                    },
                    {
                      id: "status",
                      name: "Status",
                    },
                  ]}
                  data={[
                    {
                      name: pendingItems[selectedRequest].user?.name,
                      quantity: pendingItems[selectedRequest].quantity,
                      time: new Date(
                        pendingItems[selectedRequest].created_at
                      ).toLocaleString(),
                      status: (
                        <TableAction
                          status={pendingItems[selectedRequest].status}
                          requestId={pendingItems[selectedRequest].id}
                          refetch={refetchHardwareRequests}
                        />
                      ),
                    },
                  ]}
                />
              ) : (
                <Text
                  type="body1"
                  style={{
                    color: globalConstants?.color?.textSecondary as string,
                  }}
                >
                  No request selected.
                </Text>
              )}
            </Column>
            <Column style={{ gap: 16 }}>
              <SectionHeading type="body1">Requests</SectionHeading>
              <Table
                displayStyle={"plain"}
                columns={[
                  {
                    id: "name",
                    name: "Name",
                  },
                  {
                    id: "quantity",
                    name: "Quantity",
                  },
                  {
                    id: "time",
                    name: "Request Time",
                  },
                  {
                    id: "status",
                    name: "Status",
                  },
                ]}
                data={hardwareRequests.map((req) => ({
                  name: req.user?.name,
                  quantity: req.quantity,
                  time: new Date(req.created_at).toLocaleString(),
                  status: (
                    <TableAction
                      status={req.status}
                      requestId={req.id}
                      refetch={refetchHardwareRequests}
                    />
                  ),
                }))}
                page={page}
                itemsPerPage={itemsPerPage}
                showFooter
              />
              <PaginationWrapper>
                <ButtonGroup>
                  <Button
                    size="sm"
                    disabled={page === 0}
                    onClick={() => setPage(Math.max(0, page - 1))}
                  >
                    <ChevronLeftIcon className="w-4 h-4" />
                  </Button>
                  {Array.from({ length: NUM_PAGES }, (_, i) => (
                    <Button
                      size="sm"
                      color={page === i ? "primary" : "secondary"}
                      onClick={() => setPage(i)}
                    >
                      {i + 1}
                    </Button>
                  ))}
                  <Button
                    size="sm"
                    disabled={page === NUM_PAGES - 1}
                    onClick={() => setPage(Math.min(NUM_PAGES, page + 1))}
                  >
                    <ChevronRightIcon className="w-4 h-4" />
                  </Button>
                </ButtonGroup>
              </PaginationWrapper>
            </Column>
          </>
        ) : (
          <Column style={{ gap: 16 }}>
            <LargeBodyBold>Requests</LargeBodyBold>
            <Text
              type="body1"
              style={{
                color: globalConstants?.color?.textSecondary as string,
              }}
            >
              No requests yet.
            </Text>
          </Column>
        )}
      </Column>
    </Modal>
  );
};

const TableAction: React.FC<{
  status: HardwareRequestStatus;
  requestId: number;
  refetch: () => Promise<ApolloQueryResult<GetAllHardwareRequestsQuery>>;
}> = ({ status, requestId, refetch }) => {
  const [approveHackerHardwareRequest] =
    useApproveHackerHardwareRequestMutation();
  const [isLoading, setIsLoading] = useState(false);

  if (status === HardwareRequestStatus.Pending) {
    return (
      <Button
        onClick={async () => {
          try {
            setIsLoading(true);
            await approveHackerHardwareRequest({
              variables: {
                id: requestId,
              },
            });
            await refetch();
          } catch (e) {
            errorToast((e as GraphQLError)?.message);
          } finally {
            setIsLoading(false);
          }
        }}
        disabled={isLoading}
        color="secondary"
      >
        Approve
      </Button>
    );
  }

  return (
    <Label size="sm" color={mapStatusToLabelColor(status)}>
      {status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()}
    </Label>
  );
};

const SectionHeading = styled(Text)`
  font-size: 20px !important;
  font-weight: 700;
  color: ${({ theme }) => theme.globalConstants.color.textDark};
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  max-width: 100%;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItemInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 64px;
  margin-bottom: 40px;

  @media (max-width: ${deviceBreakpoints.tablet}px) {
    gap: 32px;
  }

  @media (max-width: ${deviceBreakpoints.largeMobile}px) {
    gap: 16px;
  }
`;

const ImageWrapper = styled.div`
  width: 124px;
  height: 124px;
  border-radius: 8px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.globalConstants.color.backgroundGray};
  user-select: none;

  @media (max-width: ${deviceBreakpoints.tablet}px) {
    width: 96px;
    height: 96px;
  }

  @media (max-width: ${deviceBreakpoints.largeMobile}px) {
    width: 80px;
    height: 80px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: end;
`;

const LargeBodyBold = styled(Text)`
  color: ${({ theme }) => theme.globalConstants.color.brandSecondary};

  font-size: 20px !important;
  font-style: normal;
  font-weight: 700;
`;

export default LotteryModal;
