import { Button, Label, Text } from "north.js";
import React from "react";
import { styled } from "twin.macro";

import { HardwareRequestStatus } from "src/api/types.generated";
import { deviceBreakpoints } from "src/theme/deviceBreakpoints";
import globalConstants from "src/theme/globalConstants";

export type THardwareItemCardLabel = {
  text: string;
  color:
    | "primary"
    | "primary-light"
    | "secondary"
    | "secondary-light"
    | "success"
    | "success-light"
    | "danger"
    | "danger-light"
    | "warning"
    | "warning-light";
};

export type THardwareItemCardButton = {
  text: string;
  textColor?: string;
  onClick: (skuCode: string, requestId?: number) => void;
  color:
    | "primary"
    | "secondary"
    | "secondary-danger"
    | "success"
    | "warning"
    | "danger"
    | "tertiary"
    | "tertiary-danger";
};

export type THardwareItemCardProps = {
  skuCode: string;
  requestId?: number;
  image: string;
  status?: HardwareRequestStatus;
  label?: THardwareItemCardLabel[];
  title: string;
  description?: string;
  submitted_by?: string;
  quantity?: number;
  timestamp?: string;
  buttons?: THardwareItemCardButton[];
  onClick?: (skuCode: string) => void;
  showRequestCount?: boolean;
  showRemainingQuantity?: boolean;
  remainingQuantity?: number;
  requestedQuantity?: number;
};

const statusToLabel: Record<
  HardwareRequestStatus,
  THardwareItemCardLabel | undefined
> = {
  [HardwareRequestStatus.Approved]: {
    text: "Approved",
    color: "success-light",
  },
  [HardwareRequestStatus.Pending]: {
    text: "Pending",
    color: "warning-light",
  },
  [HardwareRequestStatus.Ready]: {
    text: "Ready for Pick-up",
    color: "success-light",
  },
  [HardwareRequestStatus.Denied]: {
    text: "Denied",
    color: "danger-light",
  },
  [HardwareRequestStatus.Fulfilled]: undefined,
  [HardwareRequestStatus.Returned]: undefined,
  [HardwareRequestStatus.Cancelled]: {
    text: "Cancelled",
    color: "danger-light",
  },
};

const HardwareItemCard: React.FC<THardwareItemCardProps> = ({
  skuCode,
  requestId,
  image,
  status,
  label,
  title,
  description,
  submitted_by,
  quantity,
  timestamp,
  buttons,
  onClick,
  showRequestCount,
  showRemainingQuantity,
  remainingQuantity = 0,
  requestedQuantity = 0,
}) => {
  return (
    <Wrapper>
      <ImageWrapper>
        <img src={image} alt={title} />
      </ImageWrapper>
      <Flex column gap={16}>
        <Flex column gap={8}>
          {(label || status) && (
            <Flex gap={4}>
              {showRequestCount && (
                <Label size="sm" color="secondary">
                  {requestedQuantity} request
                  {requestedQuantity === 1 ? "" : "s"}
                </Label>
              )}
              {status && statusToLabel[status] && (
                <Label
                  size="sm"
                  color={statusToLabel[status]?.color ?? undefined}
                >
                  {statusToLabel[status]?.text ?? status}
                </Label>
              )}
              {label &&
                label.map((label, index) => (
                  <Label size="sm" color={label.color} key={index}>
                    {label.text}
                  </Label>
                ))}
            </Flex>
          )}

          <Flex gap={8} mediaQuery={deviceBreakpoints.largeMobile}>
            {onClick ? (
              <TitleButton onClick={() => onClick(skuCode)}>
                {title}
              </TitleButton>
            ) : (
              <Title>{title}</Title>
            )}
            {showRemainingQuantity !== false && (
              <Text
                type="body2"
                style={{
                  color:
                    remainingQuantity && remainingQuantity <= 5
                      ? (globalConstants?.color?.textDanger as string)
                      : (globalConstants?.color?.textSecondary as string),
                }}
              >
                {remainingQuantity === 1
                  ? `${remainingQuantity} unit remaining`
                  : `${remainingQuantity} units remaining`}
              </Text>
            )}
          </Flex>
          {description && (
            <Text
              type="body1"
              style={{ color: globalConstants?.color?.textSecondary as string }}
            >
              {description}
            </Text>
          )}
          {quantity && (
            <Flex gap={4}>
              <Text
                type="body1"
                style={{
                  color: globalConstants?.color?.textSecondary as string,
                }}
              >
                Quantity:
              </Text>
              <Label size="sm" color="secondary-light">
                {quantity}
              </Label>
            </Flex>
          )}
          {submitted_by && (
            <Flex gap={4} mediaQuery={deviceBreakpoints.tablet}>
              <Text
                type="body1"
                style={{
                  color: globalConstants?.color?.textSecondary as string,
                }}
              >
                Submitted by:
              </Text>
              <Label size="sm" color="secondary-light">
                {submitted_by}
              </Label>
            </Flex>
          )}
          {timestamp && status === HardwareRequestStatus.Pending && (
            <Flex gap={4} mediaQuery={deviceBreakpoints.largeMobile}>
              <Text
                type="body1"
                style={{
                  color: globalConstants?.color?.textSecondary as string,
                }}
              >
                Date/Time:
              </Text>
              <Label size="sm" color="secondary-light">
                {new Date(timestamp).toLocaleString()}
              </Label>
            </Flex>
          )}
        </Flex>

        {buttons && (
          <ButtonWrapper>
            {buttons.map((button, index) => (
              <Button
                key={index}
                color={button.color}
                onClick={() => button.onClick(skuCode, requestId)}
                size="lg"
                style={{
                  color: button.textColor ?? undefined,
                }}
              >
                {button.text}
              </Button>
            ))}
          </ButtonWrapper>
        )}
      </Flex>
    </Wrapper>
  );
};

const Flex = styled.div<{ column?: boolean; gap: number; mediaQuery?: number }>`
  display: flex;
  align-items: ${({ column }) => (column ? "flex-start" : "center")};
  flex-direction: ${({ column }) => (column ? "column" : "row")};
  gap: ${({ gap }) => gap}px;

  @media (max-width: ${({ mediaQuery }) => mediaQuery}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Wrapper = styled.div`
  display: flex;
  position: relative;
  border-radius: 16px;
  gap: 24px;
  background: ${({ theme }) => theme.globalConstants.color.white};
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
  border: 1px solid ${({ theme }) => theme.globalConstants.color.textLight};
  color: ${({ theme }) => theme.globalConstants.color.brandPrimary};
`;

const ImageWrapper = styled.div`
  width: 124px;
  height: 124px;
  border-radius: 8px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.globalConstants.color.backgroundGray};
  user-select: none;

  @media (max-width: ${deviceBreakpoints.tablet}px) {
    width: 96px;
    height: 96px;
  }

  @media (max-width: ${deviceBreakpoints.largeMobile}px) {
    width: 80px;
    height: 80px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.globalConstants.color.textBody};
`;

const TitleButton = styled.button`
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.globalConstants.color.textBody};
  cursor: pointer;
  text-align: left;
  &:hover {
    text-decoration: underline;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: ${deviceBreakpoints.largeMobile}px) {
    flex-direction: column;
    gap: 8px;
  }
`;

export default HardwareItemCard;
