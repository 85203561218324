/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../api/types.generated";
export type GetAllHardwareCategoriesQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetAllHardwareCategoriesQuery = {
  getHardwareCategories: Array<
    Pick<
      Types.HardwareCategory,
      "id" | "name" | "short_description" | "long_description" | "image"
    >
  >;
};

export const GetAllHardwareCategoriesDocument = gql`
  query GetAllHardwareCategories {
    getHardwareCategories {
      id
      name
      short_description
      long_description
      image
    }
  }
`;

/**
 * __useGetAllHardwareCategoriesQuery__
 *
 * To run a query within a React component, call `useGetAllHardwareCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllHardwareCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllHardwareCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllHardwareCategoriesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetAllHardwareCategoriesQuery,
    GetAllHardwareCategoriesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetAllHardwareCategoriesQuery,
    GetAllHardwareCategoriesQueryVariables
  >(GetAllHardwareCategoriesDocument, baseOptions);
}
export function useGetAllHardwareCategoriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAllHardwareCategoriesQuery,
    GetAllHardwareCategoriesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetAllHardwareCategoriesQuery,
    GetAllHardwareCategoriesQueryVariables
  >(GetAllHardwareCategoriesDocument, baseOptions);
}
export type GetAllHardwareCategoriesQueryHookResult = ReturnType<
  typeof useGetAllHardwareCategoriesQuery
>;
export type GetAllHardwareCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetAllHardwareCategoriesLazyQuery
>;
export type GetAllHardwareCategoriesQueryResult = ApolloReactCommon.QueryResult<
  GetAllHardwareCategoriesQuery,
  GetAllHardwareCategoriesQueryVariables
>;
