import {
  HardwareRequest,
  HardwareRequestStatus,
  HardwareSku,
  HardwareSkuType,
  User,
} from "src/api/types.generated";

import {
  THardwareItemCardButton,
  THardwareItemCardProps,
} from "../../components/Hardware/ItemCard";

export const processRequests = ({
  remainingQuantityMap,
  data,
  items,
  userType,
  getButtons,
  onClick,
  hideLottery,
}: {
  remainingQuantityMap: { [key: string]: number };
  data:
    | (Pick<
        HardwareRequest,
        | "created_at"
        | "updated_at"
        | "status"
        | "quantity"
        | "id"
        | "sku_code"
        | "notes"
        | "user_id"
        | "event_id"
      > & {
        user: Pick<User, "name" | "email">;
      })[]
    | undefined;
  items: Pick<
    HardwareSku,
    | "code"
    | "image"
    | "type"
    | "name"
    | "location"
    | "short_description"
    | "long_description"
    | "category_id"
  >[];
  userType: "organizer" | "hacker";
  // getButtons is here since we need to pass different onClick functions retrieved from generated graphql hooks
  getButtons: (
    status: HardwareRequestStatus
  ) => THardwareItemCardButton[] | undefined;
  // item name onclick
  onClick?: (requestId: number, skuCode: string) => void;
  hideLottery?: boolean;
}) => {
  if (!data) return [];

  // Initialize an object with all HardwareRequestStatus as keys and empty arrays as values
  const requestGroups: Record<HardwareRequestStatus, THardwareItemCardProps[]> =
    Object.values(HardwareRequestStatus).reduce((acc, status) => {
      acc[status] = [];
      return acc;
    }, {} as Record<HardwareRequestStatus, THardwareItemCardProps[]>);

  // Populate the requestGroups object with the appropriate cards
  data.forEach((request) => {
    const item = items.find((item) => item.code === request.sku_code);
    if (!item) return;

    const isLottery = item.type === HardwareSkuType.Lottery;

    if (hideLottery && isLottery) return;

    // Data for item cards
    const cardProps: THardwareItemCardProps = {
      skuCode: item?.code ?? "",
      requestId: request.id,
      image: item?.image ?? "",
      status: request.status,
      label: isLottery
        ? [
            {
              text: "Lottery Item",
              color: "primary-light",
            },
          ]
        : undefined,
      title: item?.name ?? "",
      description: item?.short_description ?? "",
      quantity: request.quantity,
      submitted_by: userType === "organizer" ? request.user.email : undefined,
      timestamp: userType === "organizer" ? request.created_at : undefined,
      buttons: getButtons(request.status),
      onClick: onClick ? () => onClick(request.id, item.code) : undefined,
      remainingQuantity: remainingQuantityMap[item.code],
    };
    requestGroups[request.status].push(cardProps);
  });

  return (
    Object.entries(requestGroups)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([_, items]) => items.length > 0)
      .map(([status, items]) => ({
        status: status as HardwareRequestStatus,
        items,
      }))
  );
};
