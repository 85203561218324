import { GraphQLError } from "graphql";
import React, { useEffect, useMemo, useState } from "react";

import { HardwareRequestStatus, HardwareSku } from "src/api/types.generated";
import { errorToast } from "src/shared/components";
import HardwareRequestsHistoryList from "src/shared/components/Hardware/RequestsList";
import { processRequests } from "src/shared/utils/hardware/processRequests";

import { useGetHackerHardwareRequestsQuery } from "../graphql/getHackerHardwareRequests.generated";
import { useGetRemainingQuantityQuery } from "../graphql/getRemainingQuantity.generated";
import { useUpdateHackerHardwareRequestByHackerMutation } from "../graphql/updateHackerHardwareRequest.generated";

type THackerRequestHistoryProps = {
  items: Pick<
    HardwareSku,
    | "code"
    | "image"
    | "type"
    | "name"
    | "location"
    | "short_description"
    | "long_description"
    | "category_id"
  >[];
  status: HardwareRequestStatus | undefined;
};

const HackerRequestHistory = ({
  items,
  status,
}: THackerRequestHistoryProps) => {
  const { data: hackerHardwareRequestsData } =
    useGetHackerHardwareRequestsQuery({
      variables: {
        where: status
          ? {
              status: { equals: status },
            }
          : {},
      },
    });

  const [updateHackerHardwareRequestByHacker] =
    useUpdateHackerHardwareRequestByHackerMutation();

  const [remainingQuantityMap, setRemainingQuantityMap] = useState<{
    [key: string]: number;
  }>({});

  const { data: remainingQuantityData } = useGetRemainingQuantityQuery();
  const remainingQuantity = remainingQuantityData?.hardwareItems;

  useEffect(() => {
    if (remainingQuantity) {
      const quantityMap: { [key: string]: number } = {};
      remainingQuantity.forEach((item) => {
        if (item.sku_code) {
          quantityMap[item.sku_code] = (quantityMap[item.sku_code] || 0) + 1;
        }
      });
      setRemainingQuantityMap(quantityMap);
    }
  }, [remainingQuantity]);

  const hardwareRequests = useMemo(() => {
    return processRequests({
      remainingQuantityMap,
      data: hackerHardwareRequestsData?.myHardwareRequests,
      items,
      userType: "hacker",
      getButtons: (status) => {
        if (status === HardwareRequestStatus.Pending) {
          return [
            {
              text: "Cancel Request",
              onClick: async (id, requestId) => {
                if (requestId) {
                  try {
                    await updateHackerHardwareRequestByHacker({
                      variables: {
                        id: requestId,
                        status: HardwareRequestStatus.Cancelled,
                      },
                    });
                  } catch (e) {
                    errorToast((e as GraphQLError)?.message);
                  }
                }
              },
              color: "secondary-danger",
            },
          ];
        }
        return undefined;
      },
    });
  }, [
    hackerHardwareRequestsData,
    items,
    remainingQuantityMap,
    updateHackerHardwareRequestByHacker,
  ]);

  return (
    <HardwareRequestsHistoryList
      hardwareRequests={hardwareRequests}
      userType="hacker"
    />
  );
};

export default HackerRequestHistory;
