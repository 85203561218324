import { DropdownMenu, Button, Text } from "north.js";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useQueryParam } from "use-query-params";

import { HardwareSkuType } from "src/api/types.generated";
import { Divider, Header, PageWrapper } from "src/shared/components";
import { HardwareItemCard, Search } from "src/shared/components/Hardware";
import { ChevronDownIcon } from "src/static/icons";
import { deviceBreakpoints } from "src/theme/deviceBreakpoints";
import globalConstants from "src/theme/globalConstants";

import { useGetRequestsQuery } from "../../graphql/getAllRequests.generated";
import { useGetRemainingQuantityQuery } from "../../graphql/getRemainingQuantity.generated";
import { THardwareSku } from "../../shared/hardwareModels";
import Loader from "../../shared/Loader";
import { useGetHardwareInfo } from "../../shared/useGetHardwareInfo";

import InfoModal from "./infoModal";
import UploadSpreadsheet from "./uploadSpreadsheet";

const OrganizerInventory: React.FC = () => {
  const [query, setQuery] = useQueryParam<string>("search");
  const [filterParam] = useQueryParam<string>("filterParam");
  const [selectedCategory, setSelectedCategory] = useState<number>(
    filterParam ? parseInt(filterParam) : -1
  );
  const [hardwareInfoModalOpen, setHardwareInfoModalOpen] = useState(false);
  const [requestHardwareItem, setRequestHardwareItem] =
    useState<THardwareSku | null>(null);
  const [tab, setTab] = useState<"inventory" | "uploadSpreadsheet">(
    "inventory"
  );
  const [remainingQuantityMap, setRemainingQuantityMap] = useState<{
    [key: string]: number;
  }>({});
  const [requestFrequency, setRequestFrequency] = useState<{
    [key: string]: number;
  }>({});

  const { data: requestsData } = useGetRequestsQuery();
  const requests = requestsData?.hardwareRequests;

  useEffect(() => {
    if (requests) {
      const frequency: { [key: string]: number } = {};
      requests.forEach((request) => {
        if (request.sku_code) {
          frequency[request.sku_code] = (frequency[request.sku_code] || 0) + 1;
        }
      });
      setRequestFrequency(frequency);
    }
  }, [requests]);

  const { data: remainingQuantityData } = useGetRemainingQuantityQuery();
  const remainingQuantity = remainingQuantityData?.hardwareItems;

  useEffect(() => {
    if (remainingQuantity) {
      const quantityMap: { [key: string]: number } = {};
      remainingQuantity.forEach((item) => {
        if (item.sku_code) {
          quantityMap[item.sku_code] = (quantityMap[item.sku_code] || 0) + 1;
        }
      });
      setRemainingQuantityMap(quantityMap);
    }
  }, [remainingQuantity]);

  const onSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.currentTarget.value === "") {
        setQuery("");
      } else {
        setQuery(e.currentTarget.value);
      }
    },
    [setQuery]
  );

  const {
    hardwareCategories,
    hardwareSkus,
    hardwareCategoriesDataLoading,
    hardwareSkusDataLoading,
  } = useGetHardwareInfo();

  const filteredHardwareItems = useMemo(() => {
    let filtered = hardwareSkus;
    if (selectedCategory !== -1) {
      filtered = filtered.filter(
        (item) => item.category_id === selectedCategory
      );
    }

    if (query && query.trim() !== "") {
      const lowercaseQuery = query.toLowerCase();
      filtered = filtered.filter(
        (item) =>
          item.name.toLowerCase().includes(lowercaseQuery) ||
          item.short_description.toLowerCase().includes(lowercaseQuery) ||
          item.long_description.toLowerCase().includes(lowercaseQuery)
      );
    }

    return filtered;
  }, [hardwareSkus, selectedCategory, query]);

  if (hardwareCategoriesDataLoading || hardwareSkusDataLoading) {
    return <Loader />;
  }

  if (tab === "uploadSpreadsheet") {
    return <UploadSpreadsheet tab={tab} setTab={setTab} />;
  }

  return (
    <>
      <Header
        title="Hardware"
        subtitle="Browse and request a variety of hardware to bring your project to life!"
      />
      <PageWrapper>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            marginBottom: "48px",
          }}
        >
          <HeaderWrapper>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
              }}
            >
              <Text type="h2">Inventory Management</Text>
              <Text
                type="body1"
                style={{
                  color: globalConstants?.color?.textSecondary as string,
                }}
              >
                Add or delete items by uploading a spreadsheet.
              </Text>
            </div>
            <Button color="primary" onClick={() => setTab("uploadSpreadsheet")}>
              Upload Spreadsheet
            </Button>
          </HeaderWrapper>
          <Flex>
            <SearchWrapper>
              <Search
                query={query || ""}
                onSearchChange={onSearchChange}
                totalSearchResults={filteredHardwareItems.length}
                placeholder="Search all items"
              />
            </SearchWrapper>
            <DropdownMenu
              items={[
                [
                  {
                    content: <Text type="body1">All</Text>,
                    action: () => setSelectedCategory(-1),
                    align: "left" as const, // need const for typecheck
                  },
                ],
                hardwareCategories?.map((category) => ({
                  content: <Text type="body1">{category.name}</Text>,
                  action: () => setSelectedCategory(category.id),
                  align: "left",
                })) || [],
              ]}
              style={{ justifyContent: "start" }}
            >
              <Button size="lg">
                {hardwareCategories.find(
                  (category) => category.id === selectedCategory
                )?.name || "All"}
                <ChevronDownIcon />
              </Button>
            </DropdownMenu>
          </Flex>
        </div>
        {requestHardwareItem && (
          <InfoModal
            isOpen={hardwareInfoModalOpen}
            onClose={() => setHardwareInfoModalOpen(false)}
            hardwareItem={requestHardwareItem}
          />
        )}
        <ItemCardsWrapper>
          {filteredHardwareItems?.map((item, index) => (
            <>
              {index !== 0 && <Divider style={{ margin: "24px 0" }} />}
              <HardwareItemCard
                key={item.code}
                skuCode={item.code}
                image={item.image}
                label={
                  item.type === HardwareSkuType.Lottery
                    ? [{ text: "Lottery Item", color: "primary-light" }]
                    : []
                }
                showRequestCount
                showRemainingQuantity
                onClick={() => {
                  setRequestHardwareItem(item);
                  setHardwareInfoModalOpen(true);
                }}
                title={item.name}
                description={item.short_description}
                requestedQuantity={requestFrequency[item.code] || 0}
                remainingQuantity={remainingQuantityMap[item.code] || 0}
              />
            </>
          ))}
        </ItemCardsWrapper>
      </PageWrapper>
    </>
  );
};

const Flex = styled.div<{ column?: boolean; mediaQuery?: number }>`
  display: flex;
  flex-direction: ${({ column }) => (column ? "column" : "row")};
  justify-content: ${({ column }) => (column ? "flex-start" : "space-between")};
  align-items: ${({ column }) => (column ? "flex-start" : "center")};

  @media (max-width: ${({ mediaQuery }) => mediaQuery}px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${deviceBreakpoints.tablet}px) {
    gap: 16px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

const ItemCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: hidden;
`;

const SearchWrapper = styled.div`
  width: 100%;
`;

export default OrganizerInventory;
