/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../../api/types.generated";
export type SendSlackMentorshipNotificationMutationVariables = Types.Exact<{
  message: Types.Scalars["String"];
}>;

export type SendSlackMentorshipNotificationMutation = Pick<
  Types.Mutation,
  "sendSlackMentorshipNotification"
>;

export const SendSlackMentorshipNotificationDocument = gql`
  mutation SendSlackMentorshipNotification($message: String!) {
    sendSlackMentorshipNotification(message: $message)
  }
`;

/**
 * __useSendSlackMentorshipNotificationMutation__
 *
 * To run a mutation, you first call `useSendSlackMentorshipNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSlackMentorshipNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSlackMentorshipNotificationMutation, { data, loading, error }] = useSendSlackMentorshipNotificationMutation({
 *   variables: {
 *      message: // value for 'message'
 *   },
 * });
 */
export function useSendSlackMentorshipNotificationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SendSlackMentorshipNotificationMutation,
    SendSlackMentorshipNotificationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SendSlackMentorshipNotificationMutation,
    SendSlackMentorshipNotificationMutationVariables
  >(SendSlackMentorshipNotificationDocument, baseOptions);
}
export type SendSlackMentorshipNotificationMutationHookResult = ReturnType<
  typeof useSendSlackMentorshipNotificationMutation
>;
export type SendSlackMentorshipNotificationMutationResult =
  ApolloReactCommon.MutationResult<SendSlackMentorshipNotificationMutation>;
export type SendSlackMentorshipNotificationMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    SendSlackMentorshipNotificationMutation,
    SendSlackMentorshipNotificationMutationVariables
  >;
