/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../api/types.generated";
export type GetHardwareRequestCountBySkuQueryVariables = Types.Exact<{
  skuCode: Types.Scalars["String"];
}>;

export type GetHardwareRequestCountBySkuQuery = {
  hardwareRequests: Array<Pick<Types.HardwareRequest, "id">>;
};

export const GetHardwareRequestCountBySkuDocument = gql`
  query GetHardwareRequestCountBySku($skuCode: String!) {
    hardwareRequests(where: { sku_code: { equals: $skuCode } }) {
      id
    }
  }
`;

/**
 * __useGetHardwareRequestCountBySkuQuery__
 *
 * To run a query within a React component, call `useGetHardwareRequestCountBySkuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHardwareRequestCountBySkuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHardwareRequestCountBySkuQuery({
 *   variables: {
 *      skuCode: // value for 'skuCode'
 *   },
 * });
 */
export function useGetHardwareRequestCountBySkuQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetHardwareRequestCountBySkuQuery,
    GetHardwareRequestCountBySkuQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetHardwareRequestCountBySkuQuery,
    GetHardwareRequestCountBySkuQueryVariables
  >(GetHardwareRequestCountBySkuDocument, baseOptions);
}
export function useGetHardwareRequestCountBySkuLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetHardwareRequestCountBySkuQuery,
    GetHardwareRequestCountBySkuQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetHardwareRequestCountBySkuQuery,
    GetHardwareRequestCountBySkuQueryVariables
  >(GetHardwareRequestCountBySkuDocument, baseOptions);
}
export type GetHardwareRequestCountBySkuQueryHookResult = ReturnType<
  typeof useGetHardwareRequestCountBySkuQuery
>;
export type GetHardwareRequestCountBySkuLazyQueryHookResult = ReturnType<
  typeof useGetHardwareRequestCountBySkuLazyQuery
>;
export type GetHardwareRequestCountBySkuQueryResult =
  ApolloReactCommon.QueryResult<
    GetHardwareRequestCountBySkuQuery,
    GetHardwareRequestCountBySkuQueryVariables
  >;
