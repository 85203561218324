/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../api/types.generated";
export type CreateHardwareRequestMutationVariables = Types.Exact<{
  event_id: Types.Scalars["Float"];
  sku_code: Types.Scalars["String"];
  quantity: Types.Scalars["Float"];
  notes: Types.Scalars["String"];
}>;

export type CreateHardwareRequestMutation = {
  createHardwareRequestByHacker: Pick<Types.HardwareRequest, "status" | "id">;
};

export const CreateHardwareRequestDocument = gql`
  mutation CreateHardwareRequest(
    $event_id: Float!
    $sku_code: String!
    $quantity: Float!
    $notes: String!
  ) {
    createHardwareRequestByHacker(
      event_id: $event_id
      sku_code: $sku_code
      quantity: $quantity
      notes: $notes
    ) {
      status
      id
    }
  }
`;

/**
 * __useCreateHardwareRequestMutation__
 *
 * To run a mutation, you first call `useCreateHardwareRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHardwareRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHardwareRequestMutation, { data, loading, error }] = useCreateHardwareRequestMutation({
 *   variables: {
 *      event_id: // value for 'event_id'
 *      sku_code: // value for 'sku_code'
 *      quantity: // value for 'quantity'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useCreateHardwareRequestMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateHardwareRequestMutation,
    CreateHardwareRequestMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateHardwareRequestMutation,
    CreateHardwareRequestMutationVariables
  >(CreateHardwareRequestDocument, baseOptions);
}
export type CreateHardwareRequestMutationHookResult = ReturnType<
  typeof useCreateHardwareRequestMutation
>;
export type CreateHardwareRequestMutationResult =
  ApolloReactCommon.MutationResult<CreateHardwareRequestMutation>;
export type CreateHardwareRequestMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreateHardwareRequestMutation,
    CreateHardwareRequestMutationVariables
  >;
