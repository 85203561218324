import { Text } from "north.js";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Divider, Header, Icon, PageWrapper } from "src/shared/components";
import { HardwareItemCard } from "src/shared/components/Hardware";
import globalConstants from "src/theme/globalConstants";

import { useGetAllHardwareSkusQuery } from "../graphql/getAllHardwareSkus.generated";
import { useGetHardwareRequestByUserIdQuery } from "../graphql/getHardwareRequestByUserId.generated";
import { useGetRemainingQuantityQuery } from "../graphql/getRemainingQuantity.generated";
import Loader from "../shared/Loader";

import RequestInfoModal from "./requestInfoModal";

interface RequestsTabProps {
  userId: number;
  onBack: () => void;
  name: string;
}

const RequestsTab: React.FC<RequestsTabProps> = ({ userId, onBack, name }) => {
  const { data: hackerHardwareRequestsData, loading } =
    useGetHardwareRequestByUserIdQuery({
      variables: {
        user_id: userId,
      },
    });
  const [requestInfoModalOpen, setRequestInfoModalOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState<{
    id: number;
    image: string;
    name: string;
    notes: string;
    quantity: number;
    sku_code: string;
    category_id: number;
    type: string;
  } | null>(null);
  const hackerHardwareRequests = hackerHardwareRequestsData?.hardwareRequests;

  const [hardwareRequestData, setHardwareRequestData] = useState<
    {
      id: number;
      image: string;
      name: string;
      notes: string;
      quantity: number;
      sku_code: string;
      category_id: number;
      type: string;
    }[]
  >([]);

  const { data: hardwareItemsData } = useGetAllHardwareSkusQuery({
    fetchPolicy: "cache-first", // ngl this is default
  });

  const hardwareItems = hardwareItemsData?.getHardwareSkus;

  const [remainingQuantityMap, setRemainingQuantityMap] = useState<{
    [key: string]: number;
  }>({});

  const { data: remainingQuantityData } = useGetRemainingQuantityQuery();
  const remainingQuantity = remainingQuantityData?.hardwareItems;

  useEffect(() => {
    if (remainingQuantity) {
      const quantityMap: { [key: string]: number } = {};
      remainingQuantity.forEach((item) => {
        if (item.sku_code) {
          quantityMap[item.sku_code] = (quantityMap[item.sku_code] || 0) + 1;
        }
      });
      setRemainingQuantityMap(quantityMap);
    }
  }, [remainingQuantity]);

  useEffect(() => {
    if (hackerHardwareRequests && hardwareItems) {
      const newHardwareRequestData = hackerHardwareRequests.map((request) => {
        const matchingItem = hardwareItems.find(
          (item) => item.code === request.sku_code
        );
        return {
          id: request.id,
          image: matchingItem?.image ?? "",
          name: matchingItem?.name ?? "",
          notes: request.notes,
          quantity: request.quantity,
          sku_code: request.sku_code,
          category_id: matchingItem?.category_id ?? -1,
          type: matchingItem?.type ?? "",
        };
      });
      setHardwareRequestData(newHardwareRequestData);
    }
  }, [hackerHardwareRequests, hardwareItems]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Header
        title="Hardware"
        subtitle="Browse and request a variety of hardware to bring your project to life!"
      />
      <PageWrapper>
        <BackButton onClick={onBack}>
          <Icon
            name="chevron-left"
            color={globalConstants?.color?.textSecondary as string}
            style={{ width: "24px", height: "24px" }}
          />
          <BackText>Back</BackText>
        </BackButton>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "16px",
            marginBottom: "48px",
          }}
        >
          <Text type="h2">{`${name}'s requests`}</Text>
        </div>
        {selectedRequest && (
          <RequestInfoModal
            isOpen={requestInfoModalOpen}
            onClose={() => setRequestInfoModalOpen(false)}
            requestId={selectedRequest.id}
            itemName={selectedRequest.name}
            skuCode={selectedRequest.sku_code}
            categoryId={selectedRequest.category_id}
            type={selectedRequest.type}
          />
        )}
        {hardwareRequestData &&
          hardwareRequestData.map((item, index) => (
            <>
              {index !== 0 && <Divider style={{ margin: "24px 0" }} />}
              <HardwareItemCard
                key={item.name}
                skuCode={item.sku_code}
                image={item.image}
                label={[
                  {
                    text: `${item.quantity} unit${
                      item.quantity === 1 ? "" : "s"
                    }`,
                    color: "secondary-light",
                  },
                ]}
                onClick={() => {
                  setSelectedRequest(item);
                  setRequestInfoModalOpen(true);
                }}
                title={item.name}
                description={item.notes}
                showRemainingQuantity
                remainingQuantity={remainingQuantityMap[item.sku_code] || 0}
              />
            </>
          ))}
      </PageWrapper>
    </>
  );
};

const BackButton = styled.button`
  display: flex;
  padding: 6px 0px;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const BackText = styled.button`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  color: ${globalConstants?.color?.textSecondary as string};
`;

export default RequestsTab;
