import { Flex, Text } from "@hackthenorth/north";
import { Button } from "north.js";
import React, { useState, useEffect } from "react";
import styled, { useTheme } from "styled-components";

import { Header, PageWrapper } from "src/shared/components";

import QuestionCard from "./components/QuestionCard";
import { useGetSignageQuestionAndUserAnswerQuery } from "./graphql/getSignageQuestionAndUserAnswer.generated";
import Question from "./pages/Question";

const SignageHunt = () => {
  const [currentQuestionId, setCurrentQuestionId] = useState("0");
  const [isQuestionOpen, setIsQuestionOpen] = useState(false);

  // Query hook with refetch
  const { data: response, refetch } = useGetSignageQuestionAndUserAnswerQuery();

  const theme = useTheme();
  const questions = response?.getSignageQuestionsAndUserAnswer ?? [];
  const questionsLeft = questions.filter(
    (question) =>
      !(question.signage_answers && question.signage_answers.length > 0)
  ).length;

  // Refetch when the modal is closed because answered states could've changed
  useEffect(() => {
    if (!isQuestionOpen) {
      refetch();
    }
  }, [isQuestionOpen, refetch]);

  return (
    <>
      {/* TODO IVAN: Rename when ready */}
      <Header title="Charts" subtitle="Answer questions and see cool stats!" />
      <PageWrapper stretchHeight={false}>
        <ChartsContainer>
          <Flex style={{ gap: "32px" }}>
            <ChartsContainer1>
              <Text mods="heading h1 bold">
                Want to see some cool event stats?
              </Text>
              {/* TODO IVAN: Rename when ready */}
              <Text>See what other attendees had to say!</Text>

              <Button
                color="secondary"
                size="md"
                onClick={() => {
                  window.open("https://charts.hackthenorth.com/", "_blank");
                }}
                style={{ width: "100%", marginTop: "24px" }}
              >
                <Text mods="heading h4">Go to Charts!</Text>
              </Button>
            </ChartsContainer1>
            {/* TODO */}
            {/* <ChartsContainer2>AN IMAGE OF CHARTS WILL GO HERE</ChartsContainer2> */}
          </Flex>
        </ChartsContainer>
      </PageWrapper>
      <PageWrapper
        style={{
          background: theme.globalConstants.color.borderLight,
        }}
      >
        <Text mods="heading h3 bold">
          {questionsLeft > 0
            ? `${questionsLeft} more to go!`
            : "All questions answered!"}
        </Text>
        <QuestionsGrid minWidth="300px">
          {questions &&
            questions.map((question) => (
              <div key={questions.indexOf(question)}>
                <QuestionCard
                  idx={questions.indexOf(question) + 1}
                  question_id={question.question_id}
                  question_name={question.title}
                  is_answered={
                    !!(
                      question.signage_answers &&
                      question.signage_answers.length > 0
                    )
                  }
                  response_count={0}
                  responses_last_hour={0}
                  setQuestionId={setCurrentQuestionId}
                  setOpen={setIsQuestionOpen}
                />
              </div>
            ))}
        </QuestionsGrid>
      </PageWrapper>
      <Question
        id={currentQuestionId}
        isOpen={isQuestionOpen}
        setIsOpen={setIsQuestionOpen}
      />
    </>
  );
};

const QuestionsGrid = styled.div<{ minWidth: string }>`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(${(props) => props.minWidth}, 1fr)
  );
  gap: 16px;
  margin-top: 25px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ChartsContainer = styled.div`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.globalConstants.color.borderGray};
  padding: 24px 32px;
  width: fit-content;
`;

const ChartsContainer1 = styled.div`
  width: 100%;
  max-width: 350px;
`;

// TODO: Add image of charts
// const ChartsContainer2 = styled.div`
//   background: red;
//   width: 300px;
//   @media (max-width: 1060px) {
//     display: none;
//   }
// `;

export default SignageHunt;
