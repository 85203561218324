import { Text } from "@hackthenorth/north";
import { Button } from "north.js";
import React from "react";
import styled from "styled-components";

import { Modal } from "src/shared/components";

interface SuccessModalProps {
  isOpen: boolean;
  onSubmit: () => void;
}

export const SuccessModal: React.FC<SuccessModalProps> = ({
  isOpen,
  onSubmit,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      width="600px"
      noSection
      actions={
        <>
          <Button color="primary" size="lg" onClick={onSubmit}>
            Okay!
          </Button>
        </>
      }
    >
      <Heading1Text>Your request has been submitted!</Heading1Text>
      <Text mods="grey">
        We will update you on the status of your request. Once claimed, we will
        send you to the Slack chat with the mentor that claimed your request.
      </Text>
    </Modal>
  );
};

const Heading1Text = styled.h2`
  margin-top: 0;
  margin-bottom: 24px;
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.heading};
  font-weight: 900;
  font-size: 20px;
`;
