/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../../api/types.generated";
export type FindManyEventActivityQueryVariables = Types.Exact<{
  eventId: Types.Scalars["Int"];
}>;

export type FindManyEventActivityQuery = {
  findManyEventActivity: Array<
    Pick<
      Types.EventActivity,
      "name" | "id" | "start_time" | "end_time" | "category" | "restrictions"
    >
  >;
};

export const FindManyEventActivityDocument = gql`
  query FindManyEventActivity($eventId: Int!) {
    findManyEventActivity(where: { event_id: { equals: $eventId } }) {
      name
      id
      start_time
      end_time
      category
      restrictions
    }
  }
`;

/**
 * __useFindManyEventActivityQuery__
 *
 * To run a query within a React component, call `useFindManyEventActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManyEventActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManyEventActivityQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useFindManyEventActivityQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FindManyEventActivityQuery,
    FindManyEventActivityQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    FindManyEventActivityQuery,
    FindManyEventActivityQueryVariables
  >(FindManyEventActivityDocument, baseOptions);
}
export function useFindManyEventActivityLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindManyEventActivityQuery,
    FindManyEventActivityQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    FindManyEventActivityQuery,
    FindManyEventActivityQueryVariables
  >(FindManyEventActivityDocument, baseOptions);
}
export type FindManyEventActivityQueryHookResult = ReturnType<
  typeof useFindManyEventActivityQuery
>;
export type FindManyEventActivityLazyQueryHookResult = ReturnType<
  typeof useFindManyEventActivityLazyQuery
>;
export type FindManyEventActivityQueryResult = ApolloReactCommon.QueryResult<
  FindManyEventActivityQuery,
  FindManyEventActivityQueryVariables
>;
