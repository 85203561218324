export type GettingStartedSection = {
  label: string;
  sectionId: string;
  shareId: string;
};

export const GETTING_STARTED_SHARE_ID = "733b216e-7b89-41f8-b5b2-364d7ef878f9";

const FIRST_TIME_HACKER_SHARE_ID = "c0e63254-c507-4a7c-a460-a202cbbc7b79";
const PLATFORMS_SHARE_ID = "c02ad7ee-8875-4b9c-b782-2ec55370a4c1";
const WORKSHOPS_SHARE_ID = "da468598-c736-4acb-af01-ac0bf24d6cee";
const ACTIVITIES_AND_MEETUPS_SHARE_ID = "732fd66d-d7a2-4784-b428-2ed6b785d2d6";
const SPONSORS_SHARE_ID = "e3ca5a32-cc2a-499f-b7d1-d1a9cdfa7fe0";

export const GETTING_STARTED_SECTIONS: GettingStartedSection[] = [
  {
    label: "First Time Hacker",
    sectionId: "first-time-hacker",
    shareId: FIRST_TIME_HACKER_SHARE_ID,
  },
  {
    label: "Platforms",
    sectionId: "platforms",
    shareId: PLATFORMS_SHARE_ID,
  },
  {
    label: "Workshops",
    sectionId: "workshops",
    shareId: WORKSHOPS_SHARE_ID,
  },
  {
    label: "Activities and Meet-Ups",
    sectionId: "activities-and-meetups",
    shareId: ACTIVITIES_AND_MEETUPS_SHARE_ID,
  },
  {
    label: "Sponsors",
    sectionId: "sponsors",
    shareId: SPONSORS_SHARE_ID,
  },
];
