/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../api/types.generated";
export type SeedHardwareInfoMutationVariables = Types.Exact<{
  url: Types.Scalars["String"];
}>;

export type SeedHardwareInfoMutation = {
  seedHardwareInfo: Pick<Types.Success, "status">;
};

export const SeedHardwareInfoDocument = gql`
  mutation SeedHardwareInfo($url: String!) {
    seedHardwareInfo(url: $url) {
      status
    }
  }
`;

/**
 * __useSeedHardwareInfoMutation__
 *
 * To run a mutation, you first call `useSeedHardwareInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSeedHardwareInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [seedHardwareInfoMutation, { data, loading, error }] = useSeedHardwareInfoMutation({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useSeedHardwareInfoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SeedHardwareInfoMutation,
    SeedHardwareInfoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SeedHardwareInfoMutation,
    SeedHardwareInfoMutationVariables
  >(SeedHardwareInfoDocument, baseOptions);
}
export type SeedHardwareInfoMutationHookResult = ReturnType<
  typeof useSeedHardwareInfoMutation
>;
export type SeedHardwareInfoMutationResult =
  ApolloReactCommon.MutationResult<SeedHardwareInfoMutation>;
export type SeedHardwareInfoMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    SeedHardwareInfoMutation,
    SeedHardwareInfoMutationVariables
  >;
