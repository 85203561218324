import { Button, Checkbox, DropdownMenu, Label, Text } from "north.js";
import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { useQueryParam } from "use-query-params";

import { HardwareRequestStatus } from "src/api/types.generated";
import { Header, PageWrapper } from "src/shared/components";
import { Search } from "src/shared/components/Hardware";
import { useUserContext } from "src/shared/contexts";
import { ChevronDownIcon } from "src/static/icons";
import globalConstants from "src/theme/globalConstants";

import { useGetAllHardwareCategoriesQuery } from "../graphql/getAllHardwareCategories.generated";
import { useGetAllHardwareSkusQuery } from "../graphql/getAllHardwareSkus.generated";

import HackerRequestHistory from "./hacker";
import OrganizerRequestHistory from "./organizer";

const RequestHistory = () => {
  const { isOrganizer } = useUserContext();
  const [query, setQuery] = useQueryParam<string | undefined>("search");
  const [selectedCategory, setSelectedCategory] = useState<number | undefined>(
    undefined
  );
  const [selectedStatus, setSelectedStatus] = useState<
    HardwareRequestStatus | undefined
  >(undefined);
  const [checkedHideLottery, setCheckedHideLottery] = useState(false);

  const onSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.currentTarget.value === "") {
        setQuery(undefined);
      } else {
        setQuery(e.currentTarget.value);
      }
    },
    [setQuery]
  );

  const { data: hardwareItemsData } = useGetAllHardwareSkusQuery();

  const filteredHardwareItems = useMemo(() => {
    if (!hardwareItemsData?.getHardwareSkus) return [];
    console.log(
      "items data",
      hardwareItemsData.getHardwareSkus.map((item) => item.name)
    );

    if (selectedCategory === -1) {
      return hardwareItemsData.getHardwareSkus;
    }

    let filtered = hardwareItemsData.getHardwareSkus;

    if (selectedCategory !== undefined) {
      filtered = filtered.filter(
        (item) => item.category_id === selectedCategory
      );
    }

    if (query && query.trim() !== "") {
      const lowercaseQuery = query.toLowerCase();
      filtered = filtered.filter(
        (item) =>
          item.name.toLowerCase().includes(lowercaseQuery) ||
          item.short_description.toLowerCase().includes(lowercaseQuery) ||
          item.long_description.toLowerCase().includes(lowercaseQuery)
      );
    }

    return filtered;
  }, [hardwareItemsData, selectedCategory, query]);

  const { data: hardwareCategoriesData } = useGetAllHardwareCategoriesQuery({
    fetchPolicy: "cache-first",
  });

  const hardwareCategories = useMemo(() => {
    return hardwareCategoriesData?.getHardwareCategories || [];
  }, [hardwareCategoriesData]);

  return (
    <>
      <Header
        title="Hardware"
        subtitle="Browse and request a variety of hardware to bring your project to life!"
      />
      <PageWrapper>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            marginBottom: "48px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <Text type="h2">
              {isOrganizer ? "Request History" : "Your Requests"}
            </Text>
            <Text
              type="body1"
              style={{ color: globalConstants?.color?.textSecondary as string }}
            >
              {isOrganizer
                ? "View all hardware requests."
                : "View all of your hardware requests."}
            </Text>
          </div>

          {isOrganizer && (
            <FlexRow>
              <SearchWrapper>
                <Search
                  query={query || ""}
                  onSearchChange={onSearchChange}
                  hideResults
                  placeholder="Search history"
                />
              </SearchWrapper>
              <DropdownMenu
                items={[
                  [
                    {
                      content: <Text type="body1">All</Text>,
                      action: () => setSelectedCategory(-1),
                      align: "left" as const, // need const for typecheck
                    },
                  ],
                  hardwareCategories?.map((category) => ({
                    content: <Text type="body1">{category.name}</Text>,
                    action: () => setSelectedCategory(category.id),
                    align: "left",
                  })) || [],
                ]}
                style={{ justifyContent: "start" }}
              >
                <Button>
                  {hardwareCategories.find(
                    (category) => category.id === selectedCategory
                  )?.name || "All"}
                  <ChevronDownIcon />
                </Button>
              </DropdownMenu>
            </FlexRow>
          )}

          <div
            style={{
              display: "flex",
              gap: "8px",
              maxWidth: "100%",
              flexWrap: "wrap",
            }}
          >
            <LabelButton onClick={() => setSelectedStatus(undefined)}>
              <Label
                color={
                  selectedStatus === undefined ? "primary" : "secondary-light"
                }
              >
                All
              </Label>
            </LabelButton>
            {Object.values(HardwareRequestStatus).map((status) => (
              <LabelButton
                key={status}
                onClick={() => setSelectedStatus(status)}
              >
                <Label
                  color={
                    selectedStatus === status ? "primary" : "secondary-light"
                  }
                >
                  {status.charAt(0).toUpperCase() +
                    status.slice(1).toLowerCase()}
                </Label>
              </LabelButton>
            ))}
          </div>
          {isOrganizer && (
            <Checkbox
              checked={checkedHideLottery}
              onChange={(e) => setCheckedHideLottery(e.target.checked)}
              label="Hide Lottery Items"
            />
          )}
        </div>

        {isOrganizer ? (
          <OrganizerRequestHistory
            items={filteredHardwareItems}
            status={selectedStatus}
            hideLottery={checkedHideLottery}
          />
        ) : (
          <HackerRequestHistory
            items={filteredHardwareItems}
            status={selectedStatus}
          />
        )}
      </PageWrapper>
    </>
  );
};

const LabelButton = styled.button`
  border-radius: 9999px;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const SearchWrapper = styled.div`
  width: 100%;
`;

export default RequestHistory;
