import { Text, Spacer } from "@hackthenorth/north";
import { ExclamationTriangleIcon, PlusIcon } from "@heroicons/react/24/outline";
import { Alert, Button, Link } from "north.js";
import React, { useState, useCallback, useMemo } from "react";
import { styled } from "twin.macro";

import { Header, PageWrapper } from "src/shared/components";
import {
  useMentorshipRequestContext,
  useUserContext,
} from "src/shared/contexts";
import { MentorshipRequestStage } from "src/shared/contexts/MentorshipRequestContext/constants";
import { MentorshipRequest } from "src/shared/contexts/MentorshipRequestContext/types";

import { useSendSlackMentorshipNotificationMutation } from "./graphql/slackMentorshipNotification.generated";
import Instructions from "./Instructions";
import { RequestCard } from "./RequestCard";
import { RequestHostModal } from "./RequestHostModal";
import { RequestModal } from "./RequestModal";
import { SuccessModal } from "./SuccessModal";

const Home: React.FC = () => {
  const [isRequestModalOpen, setIsRequestModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isRequestHostModalOpen, setIsRequestHostModalOpen] = useState(false);
  const [isSuccessHostModalOpen, setIsSuccessHostModalOpen] = useState(false);
  const [sendSlackMentorshipNotification] =
    useSendSlackMentorshipNotificationMutation();
  const [requestToEdit, setRequestToEdit] = useState<
    MentorshipRequest | undefined
  >();

  const { info } = useUserContext();

  const handleSendNotification = async (
    title: string,
    categories: string[]
  ) => {
    try {
      // Execute the mutation
      const isHackathonHostRequest = categories.includes("Hackathon Host");
      const filteredCategories = categories.filter(
        (c) => c !== "Hackathon Host"
      );
      const msg = `New ${
        isHackathonHostRequest ? "hackathon host" : "mentorship"
      } request: *${title}* (_categories: ${filteredCategories.join(
        ", "
      )}_) submitted by ${info?.name ?? "hacker"}.`;
      await sendSlackMentorshipNotification({ variables: { message: msg } });
      console.log("Slack notification sent successfully!");
    } catch (e) {
      console.error("Error sending notification:", e);
    }
  };

  const { requests, isLoading, submitRequest, updateRequest } =
    useMentorshipRequestContext();

  const alreadyRequestedHost: boolean = useMemo(
    () =>
      requests.some((request) => request?.category?.includes("Hackathon Host")),
    [requests]
  );

  const startNewRequest = () => {
    setRequestToEdit(undefined);
    setIsRequestModalOpen(true);
  };

  const startHackathonHostRequest = () => {
    setRequestToEdit(undefined);
    setIsRequestHostModalOpen(true);
  };

  const submit = async (
    title: string,
    description: string,
    category: string[],
    priority: number
  ) => {
    await submitRequest({ title, description, category, priority });
    setIsRequestModalOpen(false);
    setIsSuccessModalOpen(true);

    handleSendNotification(title, category);
  };

  const submitHost = async (
    title: string,
    description: string,
    category: string[],
    priority: number
  ) => {
    await submitRequest({ title, description, category, priority });
    setIsRequestHostModalOpen(false);
    setIsSuccessHostModalOpen(true);

    handleSendNotification(title, category);
  };

  const acknowledgeSubmit = () => {
    setIsSuccessModalOpen(false);
    window.location.reload();
  };

  // repeat code from claimed-requests
  const resolveRequest = useCallback(
    (request: MentorshipRequest) =>
      updateRequest({
        ...request,
        stage_slug: MentorshipRequestStage.RESOLVED,
      }),
    [updateRequest]
  );

  const reopenRequest = useCallback(
    (request: MentorshipRequest) =>
      updateRequest({
        ...request,
        reopened: true,
        stage_slug: MentorshipRequestStage.OPEN,
      }),
    [updateRequest]
  );

  const sortedRequests = [
    ...requests
      .filter((r) => r.stage_slug === MentorshipRequestStage.OPEN)
      .reverse(),
    ...requests
      .filter((r) => r.stage_slug === MentorshipRequestStage.CLAIMED)
      .reverse(),
    ...requests
      .filter((r) => r.stage_slug === MentorshipRequestStage.RESOLVED)
      .reverse(),
    ...requests
      .filter((r) => r.stage_slug === MentorshipRequestStage.CLOSED)
      .reverse(),
  ];

  return (
    <>
      <Header
        title="Mentorship"
        subtitle="Submit a request or question to a mentor for help on your project"
        actionButton={
          !info?.event_platform_user_id && (
            <ButtonSeparator>
              <Button
                color="secondary"
                leadingIcon={<PlusIcon tw="w-20 h-20" />}
                onClick={startHackathonHostRequest}
                disabled={alreadyRequestedHost}
              >
                Hackathon Host Request
              </Button>
              <Button
                color="secondary"
                leadingIcon={<PlusIcon tw="w-20 h-20" />}
                onClick={startNewRequest}
              >
                New Request
              </Button>
            </ButtonSeparator>
          )
        }
      />
      <PageWrapper>
        <RequestModal
          isOpen={isRequestModalOpen}
          onCancel={() => setIsRequestModalOpen(false)}
          onSubmit={submit}
          requestToEdit={requestToEdit}
          newRequest={true}
        />
        <RequestHostModal
          isOpen={isRequestHostModalOpen}
          onCancel={() => setIsRequestHostModalOpen(false)}
          onSubmit={submitHost}
          requestToEdit={requestToEdit}
          newRequest={true}
        />
        <SuccessModal
          isOpen={isSuccessModalOpen}
          onSubmit={acknowledgeSubmit}
        />
        <SuccessModal
          isOpen={isSuccessHostModalOpen}
          onSubmit={acknowledgeSubmit}
        />
        {info?.event_platform_user_id ? (
          <Alert
            color="warning"
            icon={<ExclamationTriangleIcon tw="w-20 h-20" />}
          >
            <Link
              href="https://docs.google.com/document/d/1oh2IM-mRHjKx17uLrImOEcXKbg6SQIHI6ITLfgx9qNE"
              tw="text-text-warning!"
            >
              You&apos;re almost there! Please link your slack account to
              request help from mentors{" "}
              <span role="img" aria-label="happy emoji">
                😊
              </span>
            </Link>
          </Alert>
        ) : (
          <>
            <Heading1Text>Have a question? Ask our mentors!</Heading1Text>
            <Text>
              Our mentors will try to assist you with a variety of hacking
              questions such as coding problems, how to use hardware and much
              more.
            </Text>
            <div>
              <Spacer height={20} />
              <Instructions />
              <Spacer height={24} />

              {!isLoading && sortedRequests.length === 0 && (
                <Text mods="grey">You have no requests to display yet.</Text>
              )}
              {sortedRequests.length === 0 && <Spacer height={264} />}
            </div>
            <Grid>
              {sortedRequests.map((request) => (
                <div key={request.id}>
                  <RequestCard
                    request={request}
                    updateRequest={updateRequest}
                    onResolve={resolveRequest}
                    onReopen={reopenRequest}
                  />
                </div>
              ))}
            </Grid>
          </>
        )}
      </PageWrapper>
    </>
  );
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, calc(50% - 12px)));
  grid-gap: 24px;
`;

const Heading1Text = styled.h1`
  margin-top: 0;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.globalConstants.color.textBody};
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.heading};
  font-weight: 900;
  font-size: 28px;

  ${({ theme }) => theme.mediaQueries.largeMobile`
    font-size: 24px;
  `}
  ${({ theme }) => theme.mediaQueries.mobile`
    font-size: 20px;
  `}
`;

const ButtonSeparator = styled.div`
  display: flex;
  gap: 12px;
  align-items: flex-end;
  ${({ theme }) => theme.mediaQueries.medium`
    gap: 4px;
    flex-direction: column;
  `}
`;

export default Home;
