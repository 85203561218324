/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../api/types.generated";
export type GetHardwareRequestByUserIdQueryVariables = Types.Exact<{
  user_id: Types.Scalars["Int"];
}>;

export type GetHardwareRequestByUserIdQuery = {
  hardwareRequests: Array<
    Pick<
      Types.HardwareRequest,
      | "id"
      | "status"
      | "notes"
      | "quantity"
      | "user_id"
      | "event_id"
      | "sku_code"
      | "created_at"
      | "updated_at"
    >
  >;
};

export const GetHardwareRequestByUserIdDocument = gql`
  query getHardwareRequestByUserId($user_id: Int!) {
    hardwareRequests(where: { user_id: { equals: $user_id } }) {
      id
      status
      notes
      quantity
      user_id
      event_id
      sku_code
      created_at
      updated_at
    }
  }
`;

/**
 * __useGetHardwareRequestByUserIdQuery__
 *
 * To run a query within a React component, call `useGetHardwareRequestByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHardwareRequestByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHardwareRequestByUserIdQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useGetHardwareRequestByUserIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetHardwareRequestByUserIdQuery,
    GetHardwareRequestByUserIdQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetHardwareRequestByUserIdQuery,
    GetHardwareRequestByUserIdQueryVariables
  >(GetHardwareRequestByUserIdDocument, baseOptions);
}
export function useGetHardwareRequestByUserIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetHardwareRequestByUserIdQuery,
    GetHardwareRequestByUserIdQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetHardwareRequestByUserIdQuery,
    GetHardwareRequestByUserIdQueryVariables
  >(GetHardwareRequestByUserIdDocument, baseOptions);
}
export type GetHardwareRequestByUserIdQueryHookResult = ReturnType<
  typeof useGetHardwareRequestByUserIdQuery
>;
export type GetHardwareRequestByUserIdLazyQueryHookResult = ReturnType<
  typeof useGetHardwareRequestByUserIdLazyQuery
>;
export type GetHardwareRequestByUserIdQueryResult =
  ApolloReactCommon.QueryResult<
    GetHardwareRequestByUserIdQuery,
    GetHardwareRequestByUserIdQueryVariables
  >;
