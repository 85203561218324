import React from "react";
import { Routes } from "react-router-dom";

import { HardwareRoute } from "src/shared/constants/route";
import { Permission } from "src/shared/contexts";
import NotFound from "src/views/404";

import { Route } from "../../shared/components";

import HardwareFAQ from "./faq";
import HardwareHome from "./home";
import HardwareInventory from "./inventory";
import Lottery from "./lottery";
import RequestHistory from "./requestHistory";
import UserTab from "./userTab";

export const HardwareRoutes = () => (
  <Routes>
    <Route
      path={HardwareRoute.HOME}
      element={<HardwareHome />}
      requirePermissions={[Permission.HACKER_EVENT_ACCESS]}
    />
    <Route
      path={HardwareRoute.INVENTORY}
      element={<HardwareInventory />}
      requirePermissions={[Permission.HACKER_EVENT_ACCESS]}
    />
    <Route
      path={HardwareRoute.REQUEST_HISTORY}
      element={<RequestHistory />}
      requirePermissions={[Permission.HACKER_EVENT_ACCESS]}
    />
    <Route
      path={HardwareRoute.USERS}
      element={<UserTab />}
      requirePermissions={[Permission.ORGANIZER]}
    />
    <Route
      path={HardwareRoute.LOTTERY}
      element={<Lottery />}
      requirePermissions={[Permission.ORGANIZER]}
    />
    <Route
      path={HardwareRoute.FAQ}
      element={<HardwareFAQ />}
      requirePermissions={[Permission.HACKER_EVENT_ACCESS]}
    />
    <Route path="*" element={<NotFound />} />
  </Routes>
);
