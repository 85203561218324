import { Button, Text, TextArea, TextInput } from "north.js";
import React, { useState } from "react";
import styled from "styled-components";

import { HardwareRequestStatus } from "src/api/types.generated";
import Icon from "src/shared/components/Icon";
import Modal from "src/shared/components/Modal";
import { EVENT_ID } from "src/shared/constants/event";
import { HardwareRoute } from "src/shared/constants/route";
import globalConstants from "src/theme/globalConstants";

import { useCreateHardwareRequestMutation } from "../../graphql/createHardwareRequest.generated";
import { useGetAvailableHardwareItemsQuery } from "../../graphql/getAvailableHardwareItems.generated";
import { useUpdateHackerHardwareRequestByHackerMutation } from "../../graphql/updateHackerHardwareRequest.generated";
import { THardwareSku } from "../../shared/hardwareModels";

interface RequestModalProps {
  open: boolean;
  closeModal: () => void;
  hardwareItem: THardwareSku;
}

const RequestModal: React.FC<RequestModalProps> = ({
  open,
  closeModal,
  hardwareItem,
}) => {
  const [quantity, setQuantity] = useState(0);
  const [note, setNote] = useState("");
  const [confirmation, setConfirmation] = useState(false);
  const [requestId, setRequestId] = useState<number>(-1);

  const [createHardwareRequest] = useCreateHardwareRequestMutation();
  const [updateHackerHardwareRequestByHacker] =
    useUpdateHackerHardwareRequestByHackerMutation();

  const onRequestConfirm = async (quantity: number, note: string) => {
    try {
      const { data } = await createHardwareRequest({
        variables: {
          event_id: EVENT_ID,
          sku_code: hardwareItem.code,
          quantity,
          notes: note,
        },
      });

      if (data?.createHardwareRequestByHacker?.status === "PENDING") {
        setConfirmation(true);
        setRequestId(data?.createHardwareRequestByHacker?.id);
      } else {
        console.error("Failed to create hardware request");
      }
    } catch (error) {
      console.error("Error creating hardware request:", error);
    }
  };

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value === "" ? 0 : parseInt(e.target.value);
    setQuantity(isNaN(value) ? 0 : value);
  };

  const handleConfirm = () => {
    onRequestConfirm(quantity, note);
    setConfirmation(true);
  };

  const handleClose = () => {
    setQuantity(0);
    setNote("");
    setConfirmation(false);
    closeModal();
  };

  const handleCancel = () => {
    if (requestId !== -1) {
      updateHackerHardwareRequestByHacker({
        variables: {
          id: requestId,
          status: HardwareRequestStatus.Cancelled,
        },
      });
    }
    handleClose();
  };

  return (
    <Modal
      onClose={handleClose}
      isOpen={open}
      style={{ maxHeight: "90vh" }}
      scroll
      width={window.innerWidth <= 620 ? "100%" : "620px"}
      title={
        confirmation
          ? ""
          : `How many ${hardwareItem.name} would you like to request?`
      }
    >
      {confirmation ? (
        <ConfirmationContent onCancel={handleCancel} onClose={handleClose} />
      ) : (
        <RequestContent
          quantity={quantity}
          note={note}
          onQuantityChange={handleQuantityChange}
          onNoteChange={(e) => setNote(e.target.value)}
          hardwareItem={hardwareItem}
          onConfirm={handleConfirm}
          onClose={handleClose}
        />
      )}
    </Modal>
  );
};

const ConfirmationTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  flex-direction: row;
  width: 100%;
`;

const SubAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const StyledText = styled(Text)`
  color: ${globalConstants?.color?.textBody as string};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const CenteredText = styled(Text)`
  text-align: center;
  color: ${globalConstants?.color?.textSecondary as string};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  max-width: 548px;
`;

const CenteredFlexCol = styled(FlexCol)`
  align-items: center;
`;

const ConfirmationContent: React.FC<{
  onCancel: () => void;
  onClose: () => void;
}> = ({ onCancel, onClose }) => (
  <CenteredFlexCol>
    <ConfirmationTitle>
      <Icon
        name="check"
        stroke="#54C152"
        style={{ width: "24px", height: "24px" }}
      />
      <Text type="h3">Request Successful</Text>
    </ConfirmationTitle>
    <CenteredText type="body1">
      Once you request this item, we will send you a message on Slack when it is
      ready to be picked up!
    </CenteredText>
    <ButtonContainer>
      <Button onClick={onCancel} size="md" color="tertiary-danger">
        Cancel Request
      </Button>
      <Button
        onClick={() => {
          onClose();
          window.location.href = HardwareRoute.REQUEST_HISTORY;
        }}
        size="md"
        color="primary"
      >
        See Requests
      </Button>
    </ButtonContainer>
  </CenteredFlexCol>
);

const RequestContent: React.FC<{
  quantity: number;
  note: string;
  onQuantityChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onNoteChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onConfirm: () => void;
  onClose: () => void;
  hardwareItem: THardwareSku;
}> = ({
  quantity,
  note,
  onQuantityChange,
  onNoteChange,
  onConfirm,
  onClose,
  hardwareItem,
}) => {
  const { data: availableItemsData } = useGetAvailableHardwareItemsQuery({
    variables: { skuCode: hardwareItem.code },
  });
  const availableQuantity = availableItemsData?.hardwareItems.length;

  return (
    <FlexCol>
      <Text
        type="body1"
        color={globalConstants?.color?.textSecondary as string}
      >
        Once you request this item, we will send you a message on Slack when it
        is ready to be picked up!
      </Text>
      <SubAreaContainer>
        <StyledText>Quantity ({availableQuantity} available)</StyledText>
        <TextInput
          type="number"
          min={0}
          max={availableQuantity}
          value={quantity}
          onChange={onQuantityChange}
          size="md"
        />
      </SubAreaContainer>
      <SubAreaContainer>
        <StyledText>
          Add a note (Tell us more about what you need this for!)
        </StyledText>
        <TextArea
          fullWidth={true}
          value={note}
          onChange={onNoteChange}
          placeholder="Enter description..."
          style={{ height: "94px" }}
        />
      </SubAreaContainer>
      <ButtonContainer>
        <Button onClick={onClose} size="md">
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          size="md"
          color="primary"
          disabled={quantity > (availableQuantity ?? 0) || quantity <= 0}
        >
          Request ({quantity}) {hardwareItem.name}
        </Button>
      </ButtonContainer>
    </FlexCol>
  );
};

export default RequestModal;
