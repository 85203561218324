import { Text } from "north.js";
import React, { Fragment } from "react";
import styled from "styled-components";

import { HardwareRequestStatus } from "src/api/types.generated";
import { Divider } from "src/shared/components";
import HardwareItemCard, {
  THardwareItemCardProps,
} from "src/shared/components/Hardware/ItemCard";
import globalConstants from "src/theme/globalConstants";

type THardwareRequestsHistoryListProps = {
  hardwareRequests: {
    status: HardwareRequestStatus;
    items: THardwareItemCardProps[];
  }[];
  userType: "organizer" | "hacker";
};

const hackerStatusMap: Record<HardwareRequestStatus, string> = {
  [HardwareRequestStatus.Pending]: "Pending",
  [HardwareRequestStatus.Approved]: "Request approved",
  [HardwareRequestStatus.Fulfilled]: "Your loans",
  [HardwareRequestStatus.Denied]: "Denied",
  [HardwareRequestStatus.Returned]: "Returned",
  [HardwareRequestStatus.Ready]: "Ready for pick-up",
  [HardwareRequestStatus.Cancelled]: "Cancelled",
};

const HardwareRequestsHistoryList = ({
  hardwareRequests,
  userType,
}: THardwareRequestsHistoryListProps) => {
  return (
    <>
      <ItemGroupsWrapper>
        {hardwareRequests.length > 0 ? (
          hardwareRequests.map((itemGroup) => {
            const groupedBySubmitter = itemGroup.items.reduce((acc, item) => {
              if (!acc[item.submitted_by || ""]) {
                acc[item.submitted_by || ""] = [];
              }
              acc[item.submitted_by || ""].push(item);
              return acc;
            }, {} as Record<string, THardwareItemCardProps[]>);

            return (
              <ItemGroup key={itemGroup.status}>
                <Text type="h2">
                  {userType === "organizer"
                    ? itemGroup.status.charAt(0).toUpperCase() +
                      itemGroup.status.slice(1).toLowerCase()
                    : hackerStatusMap[
                        itemGroup.status as HardwareRequestStatus
                      ]}
                </Text>
                {Object.entries(groupedBySubmitter).map(
                  ([submitter, items], groupIndex) => (
                    <Fragment key={submitter}>
                      {groupIndex !== 0 && <Divider />}
                      {submitter && <Text type="h3">{submitter}</Text>}
                      {items.map((item, index) => (
                        <Fragment key={item.skuCode + item.timestamp}>
                          {index !== 0 && <Divider />}
                          <HardwareItemCard {...item} />
                        </Fragment>
                      ))}
                    </Fragment>
                  )
                )}
              </ItemGroup>
            );
          })
        ) : (
          <Text
            type="body1"
            style={{ color: globalConstants?.color?.textSecondary as string }}
          >
            No requests found.
          </Text>
        )}
      </ItemGroupsWrapper>
    </>
  );
};

const ItemGroupsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

const ItemGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export default HardwareRequestsHistoryList;
