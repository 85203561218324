/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../api/types.generated";
export type GetSignageQuestionAndUserAnswerQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetSignageQuestionAndUserAnswerQuery = {
  getSignageQuestionsAndUserAnswer: Array<
    Pick<Types.SignageQuestion, "question_id" | "title"> & {
      signage_answers?: Types.Maybe<
        Array<Pick<Types.SignageAnswer, "answer_id" | "value">>
      >;
    }
  >;
};

export const GetSignageQuestionAndUserAnswerDocument = gql`
  query GetSignageQuestionAndUserAnswer {
    getSignageQuestionsAndUserAnswer {
      question_id
      title
      signage_answers {
        answer_id
        value
      }
    }
  }
`;

/**
 * __useGetSignageQuestionAndUserAnswerQuery__
 *
 * To run a query within a React component, call `useGetSignageQuestionAndUserAnswerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSignageQuestionAndUserAnswerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSignageQuestionAndUserAnswerQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSignageQuestionAndUserAnswerQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetSignageQuestionAndUserAnswerQuery,
    GetSignageQuestionAndUserAnswerQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetSignageQuestionAndUserAnswerQuery,
    GetSignageQuestionAndUserAnswerQueryVariables
  >(GetSignageQuestionAndUserAnswerDocument, baseOptions);
}
export function useGetSignageQuestionAndUserAnswerLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSignageQuestionAndUserAnswerQuery,
    GetSignageQuestionAndUserAnswerQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetSignageQuestionAndUserAnswerQuery,
    GetSignageQuestionAndUserAnswerQueryVariables
  >(GetSignageQuestionAndUserAnswerDocument, baseOptions);
}
export type GetSignageQuestionAndUserAnswerQueryHookResult = ReturnType<
  typeof useGetSignageQuestionAndUserAnswerQuery
>;
export type GetSignageQuestionAndUserAnswerLazyQueryHookResult = ReturnType<
  typeof useGetSignageQuestionAndUserAnswerLazyQuery
>;
export type GetSignageQuestionAndUserAnswerQueryResult =
  ApolloReactCommon.QueryResult<
    GetSignageQuestionAndUserAnswerQuery,
    GetSignageQuestionAndUserAnswerQueryVariables
  >;
