import { Text } from "north.js";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

interface CategoryItemProps {
  category: string;
  image: string;
  categoryId: number;
}

const CategoryItem: React.FC<CategoryItemProps> = ({
  category,
  image,
  categoryId,
}) => {
  const navigate = useNavigate();

  const handleCategoryClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate(
      `/hardware/inventory?filterParam=${encodeURIComponent(
        categoryId.toString()
      )}`
    );
  };

  return (
    <CategoryItemWrapper onClick={handleCategoryClick}>
      <div
        style={{
          width: "156px",
          height: "156px",
          borderRadius: "8px",
          position: "relative",
        }}
      >
        <div
          style={{
            width: "156px",
            height: "156px",
            background: "#9CA3AF",
            borderRadius: "8px",
            opacity: "0.2",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        />
        <CategoryImage
          src={image}
          alt={category}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "8px",
          }}
        />
      </div>
      <Text type="body1" aria-setsize={16}>
        {category}
      </Text>
    </CategoryItemWrapper>
  );
};

const CategoryItemWrapper = styled.div`
  flex-direction: column;
  display: flex;
  gap: 8px;
  cursor: pointer;
`;

const CategoryImage = styled.img`
  width: 156px;
  height: 156px;
  object-fit: cover;
`;

export default CategoryItem;
