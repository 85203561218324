import { Text } from "north.js";
import React from "react";
import styled from "styled-components";

import { Header, PageWrapper } from "src/shared/components";
import globalConstants from "src/theme/globalConstants";

import { HARDWARE_FAQ } from "./ constants";

const HardwareFAQ: React.FC = () => {
  return (
    <>
      <Header
        title="Hardware"
        subtitle="Browse and request a variety of hardware to bring your project to life!"
      />
      <PageWrapper>
        <TopSection>
          <TopSectionText>
            <Text type="h2" style={{ marginBottom: "12px" }}>
              Frequently Asked Questions
            </Text>
            {HARDWARE_FAQ.map((faq) => (
              <>
                <Question>{faq.question}</Question>
                <Answer>{faq.answer}</Answer>
              </>
            ))}
          </TopSectionText>
        </TopSection>
      </PageWrapper>
    </>
  );
};

const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 48px;
`;

const TopSectionText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Question = styled(Text).attrs({ type: "body1" })`
  color: ${globalConstants?.color?.textSecondary};
  font-weight: bold;
`;

const Answer = styled(Text).attrs({ type: "body1" })`
  color: ${globalConstants?.color?.textSecondary};
  margin-bottom: 12px;
`;

export default HardwareFAQ;
