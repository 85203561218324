import { Flex, Spacer, Text } from "@hackthenorth/north";
import React from "react";
import styled from "styled-components";

import { TSignageQuestionProps } from "../types";

import { AnsweredBadge, NotAnsweredBadge, QuestionBadge } from "./Badges";

const QuestionCard: React.FC<TSignageQuestionProps> = ({
  idx,
  question_id,
  question_name,
  is_answered,
  setQuestionId,
  setOpen,
}) => {
  const handleClick = () => {
    setQuestionId(question_id.toString());
    setOpen(true);
  };

  return (
    <QuestionWrapper isAnswered={is_answered} onClick={handleClick}>
      <PanelButton>
        <Flex
          row
          style={{
            marginBottom: "10px",
            maxWidth: "100%",
            overflow: "hidden",
          }}
        >
          <QuestionBadge labelText={"Q" + idx} />
          <Spacer width={5} />
          {is_answered ? <AnsweredBadge /> : <NotAnsweredBadge />}
        </Flex>
        <QuestionText mods={"heading"} isAnswered={is_answered}>
          {question_name}
        </QuestionText>
      </PanelButton>
    </QuestionWrapper>
  );
};

const QuestionWrapper = styled.div<{ isAnswered: boolean }>`
  border-radius: 8px;
  border: 1px solid var(--north-border-gray, #d1d5db);
  background: ${({ isAnswered, theme }) =>
    isAnswered
      ? theme.globalConstants.color.borderLight
      : "var(--north-background-white, #fff)"};
  min-height: 150px;

  /* North/Shadow/base */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 3px 0px rgba(0, 0, 0, 0.1);

  padding: 20px;
  align-items: flex-start;
  gap: 16px;
  margin: 16px 0;
  cursor: pointer;
`;

const PanelButton = styled.button`
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
  text-align: left;
`;

const QuestionText = styled(Text)<{ isAnswered?: boolean }>`
  color: ${({ isAnswered, theme }) =>
    isAnswered
      ? theme.globalConstants.color.textTertiary
      : theme.globalConstants.color.primary} !important;
`;

export default QuestionCard;
