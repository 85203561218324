import { Spacer } from "@hackthenorth/north";
import { Text } from "north.js";
import React from "react";
import styled from "styled-components";

import {
  Number1Img,
  Number2Img,
  Number3Img,
  RequestImg,
  SlackNotificationImg,
  MentorSignpostImg,
} from "src/static/img";
import "twin.macro";
import { mediaQueries } from "src/theme/deviceBreakpoints";

type TInstructions = {
  numberImg: string;
  text: string;
  illustration: string;
};

const INSTRUCTIONS: TInstructions[] = [
  {
    numberImg: Number1Img,
    text: "Submit your request or question!",
    illustration: RequestImg,
  },
  {
    numberImg: Number2Img,
    text: "Get notified on Slack when your request is claimed",
    illustration: SlackNotificationImg,
  },
  {
    numberImg: Number3Img,
    text: "Follow the instructions on Slack to find your mentor in the Mentor Bay",
    illustration: MentorSignpostImg,
  },
];
const Instructions: React.FC = () => (
  <div tw="bg-background-light py-16 px-24 ">
    <Container>
      {INSTRUCTIONS.map(({ text, illustration, numberImg }, i) => (
        <div tw="flex align-items[center] py-28 px-32 rounded-8" key={i}>
          <div tw="flex w-full align-items[center]">
            <>
              <Img src={numberImg} alt="chatbox" />
              <Spacer width={24} />
            </>
            <Text tw="flex align-self[center]">{text}</Text>
            <>
              <Spacer width={16} />
              <Img src={illustration} alt="chatbox" />
            </>
          </div>
        </div>
      ))}
    </Container>
  </div>
);

const Img = styled.img`
  display: block;
  max-height: 84px;
  max-width: 100%;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  column-gap: 12px;
  row-gap: 12px;
  ${mediaQueries.large} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  ${mediaQueries.medium} {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

export default Instructions;
