import { Flex, Text, Spacer } from "@hackthenorth/north";
import { TextInput, Button, TextArea, Modal } from "north.js";
import React, { useState, useEffect } from "react";
import { styled } from "twin.macro";

import { TagInput } from "src/shared/components";
import { MENTORSHIP_TOPICS } from "src/shared/constants/mentorship";
import { MentorshipRequest } from "src/shared/contexts/MentorshipRequestContext/types";

interface RequestModalProps {
  isOpen: boolean;
  onSubmit: (
    title: string,
    description: string,
    categories: string[],
    priority: number
  ) => void;
  onCancel: () => void;
  requestToEdit?: MentorshipRequest;
  newRequest: boolean;
}

export const RequestModal: React.FC<RequestModalProps> = ({
  isOpen,
  onSubmit,
  onCancel,
  requestToEdit,
  newRequest,
}) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [categories, setCategories] = useState<string[]>([]);
  const [priority, setPriority] = useState(1); // Default to Medium priority

  useEffect(() => {
    setTitle(requestToEdit?.title ?? "");
    setDescription(requestToEdit?.description ?? "");
    setCategories(requestToEdit?.category ?? []);
    setPriority(requestToEdit?.priority ?? 1); // Default to Medium priority
  }, [isOpen, requestToEdit]);

  const isValid = !!title && !!description;

  return (
    <Modal open={isOpen} onClose={onCancel} tw="max-width[800px]! p-40!">
      <Heading1Text>Submit a question to a mentor</Heading1Text>
      <Text mods="grey">
        All questions are required unless stated otherwise.
      </Text>
      <Spacer height={12} />
      <Text mods="bold textBody">Subject</Text>
      <Spacer height={12} />
      <InputWrapper
        maxLength={200}
        placeholder="What does your team need help with?"
        value={title}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setTitle(e.target.value);
        }}
      />
      <Spacer height={24} />
      <Text mods="bold textBody">Description </Text>
      <Spacer height={12} />
      <StyledTextArea
        fullWidth
        placeholder="Describe your request. Some examples of details can include what you want to do, the issue at hand, what you have tried, and tools used."
        maxChars={500}
        value={description}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          setDescription(e.target.value);
        }}
        hint={
          "Try to be as detailed as possible so mentors can better help you!"
        }
        displayLimit
      />
      <Spacer height={24} />
      <OptionalQuestionWrapper>
        <Text mods="bold textBody">Category</Text>
        <OptionalText>Optional</OptionalText>
      </OptionalQuestionWrapper>
      <Spacer height={12} />
      <TagInput
        multi
        options={MENTORSHIP_TOPICS}
        selectedOptions={categories}
        setSelectedOptions={(selectedOptions) => {
          setCategories(selectedOptions);
        }}
      />
      <Spacer height={12} />
      <div tw="flex justify-end">
        <>
          <Button color="tertiary" size="lg" onClick={onCancel}>
            Cancel
          </Button>
          <Spacer width={16} />
          <Button
            color="primary"
            size="lg"
            disabled={!isValid}
            onClick={() => {
              onSubmit(title, description, categories, priority);
            }}
          >
            {newRequest ? "Submit" : "Save"}
          </Button>
        </>
      </div>
    </Modal>
  );
};

const Heading1Text = styled.h1`
  margin-top: 0;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.globalConstants.color.brandSecondary};
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.heading};
  font-weight: 900;
  font-size: 28px;

  ${({ theme }) => theme.mediaQueries.largeMobile`
    font-size: 24px;
  `}
  ${({ theme }) => theme.mediaQueries.mobile`
    font-size: 20px;
  `}
`;

const InputWrapper = styled(TextInput).attrs({ size: "md" })`
  width: 100%;
`;

const OptionalText = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.globalConstants.color.textTertiary};
  font-weight: 500;
  font-style: italic;
  line-height: 1.35;
  margin-top: 4px;
`;

const OptionalQuestionWrapper = styled(Flex).attrs({ align: "center" })`
  gap: 8px;
`;

const StyledTextArea = styled(TextArea)`
  height: 150px;
`;
