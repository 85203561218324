/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../api/types.generated";
export type GetRemainingQuantityQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetRemainingQuantityQuery = {
  hardwareItems: Array<Pick<Types.HardwareItem, "id" | "sku_code">>;
};

export const GetRemainingQuantityDocument = gql`
  query getRemainingQuantity {
    hardwareItems(
      where: {
        NOT: { hardware_request_item: { some: { reserved: { equals: true } } } }
      }
    ) {
      id
      sku_code
    }
  }
`;

/**
 * __useGetRemainingQuantityQuery__
 *
 * To run a query within a React component, call `useGetRemainingQuantityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRemainingQuantityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRemainingQuantityQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRemainingQuantityQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetRemainingQuantityQuery,
    GetRemainingQuantityQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetRemainingQuantityQuery,
    GetRemainingQuantityQueryVariables
  >(GetRemainingQuantityDocument, baseOptions);
}
export function useGetRemainingQuantityLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetRemainingQuantityQuery,
    GetRemainingQuantityQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetRemainingQuantityQuery,
    GetRemainingQuantityQueryVariables
  >(GetRemainingQuantityDocument, baseOptions);
}
export type GetRemainingQuantityQueryHookResult = ReturnType<
  typeof useGetRemainingQuantityQuery
>;
export type GetRemainingQuantityLazyQueryHookResult = ReturnType<
  typeof useGetRemainingQuantityLazyQuery
>;
export type GetRemainingQuantityQueryResult = ApolloReactCommon.QueryResult<
  GetRemainingQuantityQuery,
  GetRemainingQuantityQueryVariables
>;
