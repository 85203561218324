import React, { useState } from "react";
import styled from "styled-components";

import { TEvent } from "./Calendar";
import EventModal from "./EventModal";

interface EventProps {
  event: TEvent;
}

const eventColors = {
  "Main Event": "#43AFDE",
  Workshop: "#54C152",
  Activity: "#F59E0B",
  Meetup: "#D95858",
  Sponsor: "#BD63CF",
  "Tech Talk": "#6B7280",
  Panel: "#C4A484",
};

const getTime = (date: Date) => {
  const time = date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "America/Toronto",
  });
  return time.charAt(0) === "0" ? time.slice(1) : time;
};

const getWidth = (start: Date, end: Date) =>
  Math.round(((end.getTime() - start.getTime()) * 128) / 3600000) - 1;

const getTopMargin = (row: number) => row * 94 + 86;

const getLeftMargin = (start: Date) =>
  Math.round(
    ((start.getTime() - new Date("2024-09-13T21:00:00Z").getTime()) * 128) /
      3600000
  ) + 16;

const Event: React.FC<EventProps & React.HTMLAttributes<HTMLDivElement>> = (
  props
) => {
  const { title, location, start_time, end_time } = props.event;
  const [open, setOpen] = useState(false);

  return (
    <Wrapper {...props}>
      <EventModal
        isOpen={open}
        onClose={() => setOpen(false)}
        event={props.event}
      />
      <Container
        onClick={() => {
          setOpen(true);
        }}
      >
        <EventTitleText>{title}</EventTitleText>
        <EventInfoText>{location.toUpperCase()}</EventInfoText>
        <EventInfoText>{`${getTime(start_time)} - ${getTime(
          end_time
        )}`}</EventInfoText>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  flex-shrink: 0;
  width: ${(props: EventProps) =>
    getWidth(props.event.start_time, props.event.end_time)}px;
  margin-top: ${(props: EventProps) => getTopMargin(props.event.row)}px;
  margin-left: ${(props: EventProps) =>
    getLeftMargin(props.event.start_time)}px;
  padding: 8px 12px;
  border-radius: 4px;
  background-color: ${(props: EventProps) => eventColors[props.event.type]};
  color: ${({ theme }) => theme.globalConstants.color.white};
`;

const Container = styled.div`
  cursor: pointer;
`;

const EventTitleText = styled.p`
  margin: 0;
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
  font-weight: 700;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const EventInfoText = styled.p`
  margin-top: 4px;
  margin-bottom: 0;
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
  font-weight: 400;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default Event;
