export type JudgingSection = {
  label: string;
  sectionId: string;
  shareId: string;
};

export const JUDGING_SHARE_ID = "00d3b6b3-0780-4061-b406-f8f137696f5b";

const HACK_THE_NORTH_JUDGING_SHARE_ID = "955a0d43-a440-4f39-b2b5-8abacbf8c8c7";
const SPONSOR_JUDGING_SHARE_ID = "10c9228f-e3ac-4658-8b07-594ab32bd533";
const APPENDIX_SHARE_ID = "ef1bd2d8-ddd1-4c90-bdd5-7bd27c3ec960";

export const JUDGING_SECTIONS: JudgingSection[] = [
  {
    label: "Hack the North Judging",
    sectionId: "hack-the-North-judging",
    shareId: HACK_THE_NORTH_JUDGING_SHARE_ID,
  },
  {
    label: "Sponsor Judging",
    sectionId: "sponsor-judging",
    shareId: SPONSOR_JUDGING_SHARE_ID,
  },
  {
    label: "Appendix",
    sectionId: "appendix",
    shareId: APPENDIX_SHARE_ID,
  },
];
