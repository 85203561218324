import { Text, Spacer } from "@hackthenorth/north";
import React from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import styled from "styled-components";

import { Modal } from "src/shared/components";
import { useDeviceSize } from "src/shared/hooks";
import { TWText } from "src/shared/styles";
import AppleWallet from "src/static/img/applewallet.svg";
import GoogleWallet from "src/static/img/googlewallet.svg";

import "react-spring-bottom-sheet/dist/style.css";

// we're using 2 diff modals: regular, and drawer for mobile (this is why there are 2+ exports)
// props are the same ofc
interface ModalProps {
  appleWallet: string | null;
  googleWallet: string | null;
  onCancel: () => void;
  isOpen: boolean;
  ticket: any;
}

const TicketModal: React.FC<ModalProps> = ({
  appleWallet,
  googleWallet,
  onCancel,
  isOpen,
  ticket,
}) => {
  const isMobileOrSmaller = useDeviceSize("largeMobile");

  return (
    <StyledModal isOpen={isOpen} onClose={onCancel}>
      <ModalContainer>
        <HeaderText>My Ticket</HeaderText>
        <Subtitle>
          This is your ticket for the event and will be needed to register
          (a.k.a. check in) at Hack the North 2024. Please use the buttons below
          to add this ticket to your mobile wallet or take a screenshot of this
          page. Registration begins at 5:30 PM local time at the RBC Oasis Tent
          in the parking lot of the University of Waterloo&apos;s E7 building.
        </Subtitle>
        <TicketHolder isMobileOrSmaller={isMobileOrSmaller}>
          {ticket}
        </TicketHolder>
        <WalletHeader>DIGITAL WALLET</WalletHeader>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <WalletButton href={appleWallet} icon={AppleWallet} />
          <Spacer width="16px" />
          <WalletButton href={googleWallet} icon={GoogleWallet} />
        </div>
      </ModalContainer>
    </StyledModal>
  );
};

export default TicketModal;

interface WalletButtonProps {
  href: string | null;
  icon: any;
}

export const WalletButton: React.FC<WalletButtonProps> = ({ href, icon }) => {
  return (
    <StyledWalletButton>
      <WalletLink href={href ?? ""} target="_blank">
        <Wallet src={icon} alt="Apple Wallet" />
      </WalletLink>
    </StyledWalletButton>
  );
};

export const TicketDrawer: React.FC<ModalProps> = ({
  appleWallet,
  googleWallet,
  onCancel,
  isOpen,
  ticket,
}) => {
  const isMobileOrSmaller = useDeviceSize("largeMobile");

  return (
    <BottomSheet
      open={isOpen}
      onDismiss={onCancel}
      snapPoints={({ maxHeight }) => [
        maxHeight - maxHeight / 10,
        maxHeight / 4,
        maxHeight * 0.6,
      ]}
      defaultSnap={({ maxHeight }) => maxHeight / 2}
    >
      <DrawerContainer>
        <HeaderText>My Ticket</HeaderText>
        <Subtitle>
          This is your ticket for the event and will be needed to register
          (a.k.a. check in) at Hack the North 2024. Please use the buttons below
          to add this ticket to your mobile wallet or take a screenshot of this
          page. Registration begins at 5:30 PM local time at the RBC Oasis Tent
          in the parking lot of the University of Waterloo&apos;s E7 building.
          building.
        </Subtitle>
        <FlexCenter>
          <TicketHolder isMobileOrSmaller={isMobileOrSmaller}>
            {ticket}
          </TicketHolder>
        </FlexCenter>
        <WalletHeader>DIGITAL WALLET</WalletHeader>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <WalletButton href={appleWallet} icon={AppleWallet} />
          <Spacer width="16px" />
          <WalletButton href={googleWallet} icon={GoogleWallet} />
        </div>
      </DrawerContainer>
    </BottomSheet>
  );
};

const WalletLink = styled.a`
  text-decoration: none;
  color: inherit;
`;

const DrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  margin-bottom: 20px;
`;

const ModalContainer = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  max-width: 90vw;
`;

const StyledWalletButton = styled.div`
  cursor: pointer;
`;

const FlexCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Wallet = styled.img`
  width: auto;
  height: 48px;
  ${({ theme }) => theme.mediaQueries.largeMobile`
    height: 41px;
  `}
`;

const HeaderText = styled(Text).attrs<{ hasCloseIcon: boolean }>({})`
  ${TWText.h2}
  font-size: 20px !important;
  padding-right: 32px;
  color: ${({ theme }) =>
    theme.globalConstants.color.borderGrayDark} !important;
`;

const TicketHolder = styled.div<{ isMobileOrSmaller: boolean }>`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: ${({ isMobileOrSmaller }) =>
    isMobileOrSmaller ? "32px 0px" : "49px 72px"};
`;

const WalletHeader = styled.p`
  color: #6b7280;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 18px */
  letter-spacing: 0.6px;
  margin-bottom: 8px;
`;

const Subtitle = styled.p`
  color: #6b7280;
  margin-top: 4px;
`;

const StyledModal = styled(Modal)`
  max-height: 80vh;
  overflow: scroll;
  margin: 12px;
`;
