import { Spacer } from "@hackthenorth/north";
import { Button, Text } from "north.js";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useQueryParam } from "use-query-params";

import { Divider, Header, PageWrapper } from "src/shared/components";
import Search from "src/shared/components/Hardware/Search";
import { globalConstants } from "src/theme/globalConstants";

import { useGetUsersByNameLazyQuery } from "../graphql/getUserByName.generated";

import RequestsTab from "./requestsTab";

const UserTab = () => {
  function useDebounce(cb: string, delay: number) {
    const [debounceValue, setDebounceValue] = useState(cb);
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebounceValue(cb);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [cb, delay]);
    return debounceValue;
  }

  const [query, setQuery] = useQueryParam<string>("search");
  const [debouncedQuery, setDebouncedQuery] = useState<string>("");
  const debounceQuery = useDebounce(query, 300);
  const [users, setUsers] = useState<
    { name: string; email: string; id: number }[]
  >([]);
  const [getUsersByName, { data, loading }] = useGetUsersByNameLazyQuery();
  const [selectedUser, setSelectedUser] = useState<{
    name: string;
    email: string;
    id: number;
  } | null>(null);
  const [tab, setTab] = useState<"users" | "requests">("users");

  const onSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.currentTarget.value === "") {
        setQuery("");
      } else {
        setQuery(e.currentTarget.value);
      }
    },
    [setQuery]
  );
  useEffect(() => {
    setDebouncedQuery(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceQuery]);

  useEffect(() => {
    if (debouncedQuery === "") {
      setUsers([]);
      return;
    }

    const fetchUsers = async () => {
      try {
        await getUsersByName({
          variables: { name: debouncedQuery },
        });
      } catch (error) {
        console.error("Error fetching users:", error);
        setUsers([]);
      }
    };

    fetchUsers();
  }, [debouncedQuery, getUsersByName]);

  useEffect(() => {
    if (data && data.users) {
      const formattedUsers = data.users.map((user) => ({
        name: user.name,
        email: user.email,
        id: user.id,
      }));
      setUsers(formattedUsers);
    } else {
      setUsers([]);
    }
  }, [data]);

  if (tab === "requests" && selectedUser) {
    return (
      <RequestsTab
        userId={selectedUser.id}
        onBack={() => setTab("users")}
        name={selectedUser.name}
      />
    );
  }

  return (
    <>
      <Header
        title="Hardware"
        subtitle="Browse and request a variety of hardware to bring your project to life!"
      />
      <PageWrapper>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            marginBottom: "48px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <Text type="h2">Users</Text>
            <Text
              type="body1"
              style={{ color: globalConstants?.color?.textSecondary as string }}
            >
              Search for users to view their hardware requests and manage their
              inventory.
            </Text>
          </div>
          <FlexRow>
            <SearchWrapper>
              <Search
                query={query || ""}
                onSearchChange={onSearchChange}
                totalSearchResults={users.length}
                placeholder="Search hackers"
              />
            </SearchWrapper>
          </FlexRow>
        </div>
        {!loading &&
          users.map((user, index) => (
            <div
              style={{ display: "flex", flexDirection: "column" }}
              key={user.email}
            >
              {index !== 0 && <Divider style={{ margin: "24px 0" }} />}
              <Bold type="body1">{user.name}</Bold>
              <Spacer height={8} />
              <EmailText type="body1">{user.email}</EmailText>
              <Spacer height={19} />
              <Button
                onClick={() => {
                  setSelectedUser(user);
                  setTab("requests");
                }}
              >
                See requests
              </Button>
            </div>
          ))}
      </PageWrapper>
    </>
  );
};

const EmailText = styled(Text)`
  color: ${globalConstants?.color?.textSecondary};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
`;

const Bold = styled(Text)`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 25.6px */
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const SearchWrapper = styled.div`
  width: 100%;
`;

export default UserTab;
