import { Spacer } from "@hackthenorth/north";
import { Button, ButtonGroup, Label, Table, Text } from "north.js";
import React, { useState } from "react";
import styled from "styled-components";

import { HardwareRequestHistoryEntry, User } from "src/api/types.generated";
import Modal from "src/shared/components/Modal";
import { mapStatusToLabelColor } from "src/shared/utils/hardware/mapStatusToLabelColor";
import { ChevronLeftIcon, ChevronRightIcon } from "src/static/icons";
import globalConstants from "src/theme/globalConstants";

import { useGetHardwareItemsQuery } from "../graphql/getHardwareItems.generated";
import { useGetHardwareRequestQuery } from "../graphql/getHardwareRequest.generated";

interface RequestInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
  requestId: number;
  itemName: string;
  skuCode: string;
  categoryId: number;
  type: string;
}

const RequestInfoModal: React.FC<RequestInfoModalProps> = ({
  isOpen,
  onClose,
  requestId,
  itemName,
  skuCode,
  categoryId,
  type,
}) => {
  const [page, setPage] = useState(0);
  const itemsPerPage = 5;

  const { data: hardwareRequestData } = useGetHardwareRequestQuery({
    variables: {
      id: requestId,
    },
  });

  const { data: hardwareItemsData } = useGetHardwareItemsQuery({
    variables: {
      where: {
        sku_code: {
          equals: skuCode,
        },
      },
    },
  });

  const hardwareRequest = hardwareRequestData?.hardwareRequest;
  const hardwareItems = hardwareItemsData?.hardwareItems;

  if (!hardwareRequest || !hardwareItems) {
    return null;
  }

  const NUM_PAGES = Math.ceil(hardwareItems.length / itemsPerPage);

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      width={window.innerWidth <= 766 ? "100%" : "766px"}
      title={itemName}
    >
      <Column
        style={{
          marginTop: 16,
          maxHeight: "80vh",
          overflowY: "auto",
        }}
      >
        <InfoRow>
          <InfoCol>
            <LargeBodyBold>Keywords</LargeBodyBold>
            <StyledBodyBold>{itemName}</StyledBodyBold>
          </InfoCol>
          <InfoCol>
            <LargeBodyBold>Item name</LargeBodyBold>
            <StyledBodyBold>{itemName}</StyledBodyBold>
          </InfoCol>
          <InfoCol>
            <LargeBodyBold>Category</LargeBodyBold>
            <StyledBodyBold>{categoryId}</StyledBodyBold>
          </InfoCol>
          <InfoCol>
            <LargeBodyBold>Type</LargeBodyBold>
            <StyledBodyBold>{type}</StyledBodyBold>
          </InfoCol>
        </InfoRow>
        <Spacer height={40} />
        <Column style={{ gap: 16, marginBottom: 24 }}>
          <SectionHeading type="body1">Units</SectionHeading>
          <Table
            displayStyle={"plain"}
            columns={[
              {
                id: "number",
                name: "Number",
              },
              {
                id: "notes",
                name: "Notes",
              },
            ]}
            data={hardwareItems.map((item) => ({
              number: item.id,
              notes: item.notes,
            }))}
            page={page}
            itemsPerPage={itemsPerPage}
            showFooter
          />
          <PaginationWrapper>
            <ButtonGroup>
              <Button
                size="sm"
                disabled={page === 0}
                onClick={() => setPage(Math.max(0, page - 1))}
              >
                <ChevronLeftIcon className="w-4 h-4" />
              </Button>
              {Array.from({ length: NUM_PAGES }, (_, i) => (
                <Button
                  size="sm"
                  color={page === i ? "primary" : "secondary"}
                  onClick={() => setPage(i)}
                >
                  {i + 1}
                </Button>
              ))}
              <Button
                size="sm"
                disabled={page === NUM_PAGES - 1}
                onClick={() => setPage(Math.min(NUM_PAGES, page + 1))}
              >
                <ChevronRightIcon className="w-4 h-4" />
              </Button>
            </ButtonGroup>
          </PaginationWrapper>
        </Column>

        <HistoryEntryUpdates
          historyEntries={hardwareRequest.hardware_request_history_entry}
        />
      </Column>
    </Modal>
  );
};

const HistoryEntryUpdates: React.FC<{
  historyEntries: (Pick<
    HardwareRequestHistoryEntry,
    "status" | "time" | "notes" | "id"
  > & {
    modifying_user: Pick<User, "name">;
  })[];
}> = ({ historyEntries }) => {
  return (
    <Column style={{ gap: 16 }}>
      <SectionHeading type="body1">History</SectionHeading>
      <Column style={{ gap: 16, flexDirection: "column-reverse" }}>
        {historyEntries.map((entry, index) => {
          if (!entry.status) return null;

          const previousEntry = index > 0 ? historyEntries[index - 1] : null;
          const statusChanged =
            !previousEntry || entry.status !== previousEntry.status;

          return (
            <Row key={entry.id} style={{ gap: 8 }}>
              <Text
                type="body1"
                style={{ color: globalConstants?.color?.textDark as string }}
              >
                <span style={{ fontWeight: 700 }}>
                  {entry.modifying_user.name}
                </span>{" "}
                changed {statusChanged ? "status" : "notes"} to{" "}
                {!statusChanged ? (
                  entry.notes ? (
                    <span style={{ fontWeight: 700 }}>{entry.notes}</span>
                  ) : (
                    "empty"
                  )
                ) : null}
              </Text>
              {statusChanged && (
                <Label size="sm" color={mapStatusToLabelColor(entry.status)}>
                  {entry.status.charAt(0).toUpperCase() +
                    entry.status.slice(1).toLowerCase()}
                </Label>
              )}
              <Text
                type="body1"
                style={{
                  color: globalConstants?.color?.textSecondary as string,
                }}
              >
                {" ∙ "}
                {new Date(entry.time).toLocaleString()}
              </Text>
            </Row>
          );
        })}
      </Column>
    </Column>
  );
};

const SectionHeading = styled(Text)`
  font-size: 20px !important;
  font-weight: 700;
  color: ${({ theme }) => theme.globalConstants.color.textDark};
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  max-width: 100%;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: end;
`;

const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 85px;
`;

const StyledBodyBold = styled(Text)`
  color: ${globalConstants?.color?.brandSecondary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 28.8px */
  word-wrap: break-word;
`;

const LargeBodyBold = styled(Text)`
  color: ${globalConstants?.color?.brandSecondary};

  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 32px */
`;

const InfoCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export default RequestInfoModal;
