import { HardwareRequestStatus } from "src/api/types.generated";

export const mapStatusToLabelColor = (status: HardwareRequestStatus) => {
  switch (status) {
    case HardwareRequestStatus.Approved:
      return "success-light";
    case HardwareRequestStatus.Denied:
      return "danger-light";
    case HardwareRequestStatus.Ready:
      return "success-light";
    case HardwareRequestStatus.Fulfilled:
      return "secondary-light";
    case HardwareRequestStatus.Returned:
      return "secondary-light";
    case HardwareRequestStatus.Cancelled:
      return "danger-light";
    case HardwareRequestStatus.Pending:
      return "warning-light";
    default:
      return "secondary";
  }
};
