/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../api/types.generated";
export type UpdateHardwareRequestStatusMutationVariables = Types.Exact<{
  id: Types.Scalars["Float"];
  status: Types.HardwareRequestStatus;
}>;

export type UpdateHardwareRequestStatusMutation = {
  changeHardwareStatus: Pick<Types.HardwareRequest, "id" | "status">;
};

export const UpdateHardwareRequestStatusDocument = gql`
  mutation updateHardwareRequestStatus(
    $id: Float!
    $status: HardwareRequestStatus!
  ) {
    changeHardwareStatus(id: $id, status: $status) {
      id
      status
    }
  }
`;

/**
 * __useUpdateHardwareRequestStatusMutation__
 *
 * To run a mutation, you first call `useUpdateHardwareRequestStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHardwareRequestStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHardwareRequestStatusMutation, { data, loading, error }] = useUpdateHardwareRequestStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateHardwareRequestStatusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateHardwareRequestStatusMutation,
    UpdateHardwareRequestStatusMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateHardwareRequestStatusMutation,
    UpdateHardwareRequestStatusMutationVariables
  >(UpdateHardwareRequestStatusDocument, baseOptions);
}
export type UpdateHardwareRequestStatusMutationHookResult = ReturnType<
  typeof useUpdateHardwareRequestStatusMutation
>;
export type UpdateHardwareRequestStatusMutationResult =
  ApolloReactCommon.MutationResult<UpdateHardwareRequestStatusMutation>;
export type UpdateHardwareRequestStatusMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateHardwareRequestStatusMutation,
    UpdateHardwareRequestStatusMutationVariables
  >;
