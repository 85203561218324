import { Spacer } from "@hackthenorth/north";
import { Button, Card, Text } from "north.js";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import {
  Dropzone,
  DropzoneError,
  FileLink,
  Header,
  Icon,
  PageWrapper,
} from "src/shared/components";
import { useUploadFile } from "src/shared/hooks/useUploadFile";
import { HackerAPIFile } from "src/shared/utils/hackerapi";
import globalConstants from "src/theme/globalConstants";

import { useSeedHardwareInfoMutation } from "../../graphql/seedHardwareItems.generated";

const UploadSpreadsheet: React.FC<{
  tab: string;
  setTab: React.Dispatch<
    React.SetStateAction<"inventory" | "uploadSpreadsheet">
  >;
}> = ({ tab, setTab }) => {
  const [file, setFile] = useState<HackerAPIFile | null>(null);
  const [error, setError] = useState("");

  const onBack = () => {
    setTab("inventory");
  };

  const { uploadFile } = useUploadFile();

  const addFile = async (files: File[]) => {
    if (files.length === 1) {
      const file = files[0];
      const hapiFile = await uploadFile(file);

      if (hapiFile) setFile(hapiFile);
    }
  };

  const [seedHardwareInfo, { loading }] = useSeedHardwareInfoMutation();

  useEffect(() => {
    console.log(file);
  }, [file]);

  return (
    <>
      <Header
        title="Upload Spreadsheet"
        subtitle="Upload a spreadsheet to update hardware inventory"
      />
      <PageWrapper>
        <BackButton onClick={onBack}>
          <Icon
            name="chevron-left"
            color={globalConstants?.color?.textSecondary as string}
            style={{ width: "24px", height: "24px" }}
          />
          <BackText>Back to Inventory</BackText>
        </BackButton>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "16px",
            marginBottom: "48px",
            gap: "16px",
          }}
        >
          <Text type="h2">Upload Spreadsheet</Text>
          <Text type="body1">
            Add, edit, or delete items by uploading a spreadsheet or updating
            each item individually.
          </Text>
          <Dropzone
            max={1}
            onError={(error: DropzoneError) => {
              setError(error);
            }}
            acceptedFileTypes={["csv"]}
            label={
              <span>
                <b>Upload a file</b> or drag and drop
              </span>
            }
            onFilesUpload={addFile}
            onBlur={() => setError("")}
          />
          {error && <Card>{error}</Card>}
          {file && (
            <>
              <Spacer height={8} />
              <FileLink file={file} />
            </>
          )}
          <Button
            color="primary"
            disabled={!file}
            onClick={() => {
              seedHardwareInfo({
                variables: { url: file?.uri as string },
              });
              setFile(null);
            }}
          >
            {loading ? "Submitting..." : "Submit"}
          </Button>
        </div>
      </PageWrapper>
    </>
  );
};

const BackButton = styled.button`
  display: flex;
  padding: 6px 0px;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const BackText = styled.button`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  color: ${globalConstants?.color?.textSecondary as string};
`;

export default UploadSpreadsheet;
