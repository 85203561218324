import { Spacer } from "@hackthenorth/north";
import { Link, Text } from "north.js";
import React from "react";
import styled from "styled-components";

import { Header, PageWrapper } from "src/shared/components";
import { Route } from "src/shared/constants/route";
import { mediaQueries } from "src/theme/deviceBreakpoints";
import globalConstants from "src/theme/globalConstants";

import Loader from "../shared/Loader";
import { useGetHardwareInfo } from "../shared/useGetHardwareInfo";

import CategoryItem from "./CategoryItem";

const HardwareHome: React.FC = () => {
  const { hardwareCategories, hardwareCategoriesDataLoading } =
    useGetHardwareInfo();

  if (hardwareCategoriesDataLoading) {
    return <Loader />;
  }

  return (
    <>
      <Header
        title="Hardware"
        subtitle="Browse and request a variety of hardware to bring your project to life!"
      />
      <PageWrapper>
        <TopSection>
          <TopSectionText>
            <Text type="h2" style={{ marginBottom: "12px" }}>
              Welcome to Hardware Tool!
            </Text>
            <Text
              type="body1"
              style={{ color: globalConstants?.color?.textSecondary as string }}
            >
              We’re so excited to bring back our hardware loaning system to Hack
              the North 2024! Throughout the event, anyone can request and pick
              up hardware to use for their hack.
            </Text>
            <Text
              type="body1"
              style={{ color: globalConstants?.color?.textSecondary as string }}
            >
              If you’re new to hardware, no worries! We have a beginner friendly
              workshop called Printed Circuit Boards 101: Building a Binary
              Clock on Saturday at 11:00 AM - 12:00 PM in Workshop Room 3A (E7
              3343). Plus, you can request mentors all weekend to help you.
            </Text>
            <Text
              type="body1"
              style={{ color: globalConstants?.color?.textSecondary as string }}
            >
              Please make all of your hardware requests using this tool. After
              that, you can pick up your items at the{" "}
              <b>Shopify Hardware Hub</b> located in rooms 1427/1437 on Floor 1
              of E7 starting <b>10:00 PM on Friday.</b> The checkout desk will
              be open all weekend except from 2:00-9:00 AM on Saturday and
              2:00-7:00 AM on Sunday.
            </Text>
            <Text
              type="body1"
              style={{ color: globalConstants?.color?.textSecondary as string }}
            >
              Please take some time to read through the{" "}
              <Link href={Route.HARDWARE_FAQ}>FAQ</Link> for important
              information on our sign-out process. For any hardware-related
              questions, reach out to{" "}
              <Link
                href="mailto:hardware@hackthenorth.com"
                target="_blank"
                rel="noopener"
              >
                hardware@hackthenorth.com
              </Link>
              .
            </Text>
            <Text
              type="body1"
              style={{ color: globalConstants?.color?.textSecondary as string }}
            >
              If the hardware you receive is defective or you think you have
              damaged it, please visit the hardware checkout desk. Replacements
              may be available, but please always inform us if your hardware is
              broken so that we don’t give it out to someone else – you won’t be
              required to pay for it unless it was clearly damaged from misuse.
            </Text>
          </TopSectionText>
        </TopSection>

        <TopSection>
          <TopSectionText>
            <Text type="h3" aria-setsize={20}>
              All categories
            </Text>
            <Text
              type="body1"
              style={{ color: globalConstants?.color?.textSecondary as string }}
            >
              Check out all of our items below!
            </Text>
          </TopSectionText>
        </TopSection>
        <GridLayout>
          {hardwareCategories.map((category) => (
            <CategoryItem
              key={category.id}
              category={category.name}
              image={category.image}
              categoryId={category.id}
            />
          ))}
        </GridLayout>
        <Spacer height={48} />
      </PageWrapper>
    </>
  );
};

const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 48px;
`;

const TopSectionText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 48px 24px;

  ${mediaQueries.extraLarge} {
    grid-template-columns: repeat(4, 1fr);
  }

  ${mediaQueries.medium} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${mediaQueries.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${mediaQueries.mobile} {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
  }
`;

export default HardwareHome;
