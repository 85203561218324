import { Button, ButtonGroup, Table, Text } from "north.js";
import React, { useState } from "react";
import styled from "styled-components";

import Modal from "src/shared/components/Modal";
import { ChevronLeftIcon, ChevronRightIcon } from "src/static/icons";
import { deviceBreakpoints } from "src/theme/deviceBreakpoints";
import globalConstants from "src/theme/globalConstants";

import { useGetHardwareItemsQuery } from "../../graphql/getHardwareItems.generated";
import { THardwareSku } from "../../shared/hardwareModels";

interface InfoModalProps {
  isOpen: boolean;
  onClose: () => void;
  hardwareItem: THardwareSku;
}

const InfoModal: React.FC<InfoModalProps> = ({
  isOpen,
  onClose,
  hardwareItem,
}) => {
  const { data: hardwareItemsData } = useGetHardwareItemsQuery({
    variables: {
      where: {
        sku_code: {
          equals: hardwareItem.code,
        },
      },
    },
  });
  const hardwareItems = hardwareItemsData?.hardwareItems;

  const [page, setPage] = useState(0);
  const itemsPerPage = 5;

  if (!hardwareItems) {
    return null;
  }

  const NUM_PAGES = Math.ceil(hardwareItems.length / itemsPerPage);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={hardwareItem.name}
      width={window.innerWidth <= 766 ? "100%" : "766px"}
    >
      <ContentWrapper>
        <TopWrapper>
          <FlexRow>
            <Image src={hardwareItem.image} />
            <FlexCol>
              <LargeBodyBold>Description</LargeBodyBold>
              <Text
                type="body1"
                style={{
                  color: globalConstants?.color?.textSecondary as string,
                }}
              >
                {hardwareItem.short_description}
              </Text>
            </FlexCol>
          </FlexRow>
          <InfoRow>
            <InfoCol>
              <LargeBodyBold>Item Name</LargeBodyBold>
              <StyledBodyBold>{hardwareItem.name}</StyledBodyBold>
            </InfoCol>
            <InfoCol>
              <LargeBodyBold>Category</LargeBodyBold>
              <StyledBodyBold>{hardwareItem.category_id}</StyledBodyBold>
            </InfoCol>
            <InfoCol>
              <LargeBodyBold>Location</LargeBodyBold>
              <StyledBodyBold>{hardwareItem.location}</StyledBodyBold>
            </InfoCol>
            <InfoCol>
              <LargeBodyBold>Type</LargeBodyBold>
              <StyledBodyBold>{hardwareItem.type}</StyledBodyBold>
            </InfoCol>
          </InfoRow>
        </TopWrapper>
        <Column
          style={{
            marginTop: 16,
            overflowY: "auto",
          }}
        >
          <Column style={{ gap: 16, marginBottom: 24 }}>
            <SectionHeading type="body1">Units</SectionHeading>
            <Table
              displayStyle={"plain"}
              columns={[
                {
                  id: "number",
                  name: "Number",
                },
                {
                  id: "notes",
                  name: "Notes",
                },
              ]}
              data={hardwareItems?.map((item) => ({
                number: item.id,
                notes: item.notes,
              }))}
              page={page}
              itemsPerPage={itemsPerPage}
              showFooter
            />
            <PaginationWrapper>
              <ButtonGroup>
                <Button
                  size="sm"
                  disabled={page === 0}
                  onClick={() => setPage(Math.max(0, page - 1))}
                >
                  <ChevronLeftIcon className="w-4 h-4" />
                </Button>
                {Array.from({ length: NUM_PAGES }, (_, i) => (
                  <Button
                    size="sm"
                    color={page === i ? "primary" : "secondary"}
                    onClick={() => setPage(i)}
                  >
                    {i + 1}
                  </Button>
                ))}
                <Button
                  size="sm"
                  disabled={page === NUM_PAGES - 1}
                  onClick={() => setPage(Math.min(NUM_PAGES, page + 1))}
                >
                  <ChevronRightIcon className="w-4 h-4" />
                </Button>
              </ButtonGroup>
            </PaginationWrapper>
          </Column>
        </Column>
      </ContentWrapper>
    </Modal>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-height: 80vh;
  overflow-y: auto;
  margin-top: 16px;
`;

const SectionHeading = styled(Text)`
  font-size: 20px !important;
  font-weight: 700;
  color: ${({ theme }) => theme.globalConstants.color.textDark};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: end;
`;

const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 85px;

  @media (max-width: ${deviceBreakpoints.tablet}px) {
    flex-direction: column;
    gap: 16px;
  }
`;

const StyledBodyBold = styled(Text)`
  color: ${globalConstants?.color?.brandSecondary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 28.8px */
  word-wrap: break-word;
`;

const LargeBodyBold = styled(Text)`
  color: ${globalConstants?.color?.brandSecondary};

  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 32px */
`;

const InfoCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const Image = styled.img`
  width: 124px;
  height: 124px;
  border-radius: 8px;
  background: linear-gradient(
      0deg,
      rgba(156, 163, 175, 0.2) 0%,
      rgba(156, 163, 175, 0.2) 100%
    ),
    url(<path-to-image>) lightgray 50% / cover no-repeat;

  @media (max-width: ${deviceBreakpoints.tablet}px) {
    width: 96px;
    height: 96px;
  }

  @media (max-width: ${deviceBreakpoints.largeMobile}px) {
    width: 80px;
    height: 80px;
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
`;

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export default InfoModal;
