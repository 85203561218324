/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../api/types.generated";
export type GetAvailableHardwareItemsQueryVariables = Types.Exact<{
  skuCode: Types.Scalars["String"];
}>;

export type GetAvailableHardwareItemsQuery = {
  hardwareItems: Array<Pick<Types.HardwareItem, "id" | "sku_code" | "notes">>;
};

export const GetAvailableHardwareItemsDocument = gql`
  query GetAvailableHardwareItems($skuCode: String!) {
    hardwareItems(
      where: {
        sku_code: { equals: $skuCode }
        hardware_request_item: { none: { reserved: { equals: true } } }
      }
    ) {
      id
      sku_code
      notes
    }
  }
`;

/**
 * __useGetAvailableHardwareItemsQuery__
 *
 * To run a query within a React component, call `useGetAvailableHardwareItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableHardwareItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableHardwareItemsQuery({
 *   variables: {
 *      skuCode: // value for 'skuCode'
 *   },
 * });
 */
export function useGetAvailableHardwareItemsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetAvailableHardwareItemsQuery,
    GetAvailableHardwareItemsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetAvailableHardwareItemsQuery,
    GetAvailableHardwareItemsQueryVariables
  >(GetAvailableHardwareItemsDocument, baseOptions);
}
export function useGetAvailableHardwareItemsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAvailableHardwareItemsQuery,
    GetAvailableHardwareItemsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetAvailableHardwareItemsQuery,
    GetAvailableHardwareItemsQueryVariables
  >(GetAvailableHardwareItemsDocument, baseOptions);
}
export type GetAvailableHardwareItemsQueryHookResult = ReturnType<
  typeof useGetAvailableHardwareItemsQuery
>;
export type GetAvailableHardwareItemsLazyQueryHookResult = ReturnType<
  typeof useGetAvailableHardwareItemsLazyQuery
>;
export type GetAvailableHardwareItemsQueryResult =
  ApolloReactCommon.QueryResult<
    GetAvailableHardwareItemsQuery,
    GetAvailableHardwareItemsQueryVariables
  >;
