/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../api/types.generated";
export type GetRequestsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetRequestsQuery = {
  hardwareRequests: Array<Pick<Types.HardwareRequest, "sku_code">>;
};

export const GetRequestsDocument = gql`
  query getRequests {
    hardwareRequests(where: {}) {
      sku_code
    }
  }
`;

/**
 * __useGetRequestsQuery__
 *
 * To run a query within a React component, call `useGetRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRequestsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetRequestsQuery,
    GetRequestsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetRequestsQuery, GetRequestsQueryVariables>(
    GetRequestsDocument,
    baseOptions
  );
}
export function useGetRequestsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetRequestsQuery,
    GetRequestsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetRequestsQuery,
    GetRequestsQueryVariables
  >(GetRequestsDocument, baseOptions);
}
export type GetRequestsQueryHookResult = ReturnType<typeof useGetRequestsQuery>;
export type GetRequestsLazyQueryHookResult = ReturnType<
  typeof useGetRequestsLazyQuery
>;
export type GetRequestsQueryResult = ApolloReactCommon.QueryResult<
  GetRequestsQuery,
  GetRequestsQueryVariables
>;
