/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../api/types.generated";
export type GetHardwareRequestQueryVariables = Types.Exact<{
  id: Types.Scalars["Float"];
}>;

export type GetHardwareRequestQuery = {
  hardwareRequest: Pick<
    Types.HardwareRequest,
    | "id"
    | "status"
    | "notes"
    | "quantity"
    | "user_id"
    | "event_id"
    | "sku_code"
    | "created_at"
    | "updated_at"
  > & {
    user: Pick<Types.User, "email">;
    hardware_request_history_entry: Array<
      Pick<
        Types.HardwareRequestHistoryEntry,
        "id" | "status" | "notes" | "time"
      > & { modifying_user: Pick<Types.User, "name"> }
    >;
  };
};

export const GetHardwareRequestDocument = gql`
  query GetHardwareRequest($id: Float!) {
    hardwareRequest(id: $id) {
      id
      status
      notes
      quantity
      user_id
      event_id
      sku_code
      created_at
      updated_at
      user {
        email
      }
      hardware_request_history_entry {
        id
        status
        notes
        time
        modifying_user {
          name
        }
      }
    }
  }
`;

/**
 * __useGetHardwareRequestQuery__
 *
 * To run a query within a React component, call `useGetHardwareRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHardwareRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHardwareRequestQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetHardwareRequestQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetHardwareRequestQuery,
    GetHardwareRequestQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetHardwareRequestQuery,
    GetHardwareRequestQueryVariables
  >(GetHardwareRequestDocument, baseOptions);
}
export function useGetHardwareRequestLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetHardwareRequestQuery,
    GetHardwareRequestQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetHardwareRequestQuery,
    GetHardwareRequestQueryVariables
  >(GetHardwareRequestDocument, baseOptions);
}
export type GetHardwareRequestQueryHookResult = ReturnType<
  typeof useGetHardwareRequestQuery
>;
export type GetHardwareRequestLazyQueryHookResult = ReturnType<
  typeof useGetHardwareRequestLazyQuery
>;
export type GetHardwareRequestQueryResult = ApolloReactCommon.QueryResult<
  GetHardwareRequestQuery,
  GetHardwareRequestQueryVariables
>;
